<template>
  <b-container class="no-gutters">
    <b-row align-h="center">
      <b-col v-if="interactive" align="center">
        <template v-if="schedule.length > 0">
          <p>(Tap to see our hours)</p>
          <b-collapse id="collapse" class="mt-2">
            <b-card>
              <table
                cellpadding="6"
                class="hours"
                v-html="scheduleHtml"
              ></table>
            </b-card>
          </b-collapse>
        </template>
      </b-col>
      <div v-else>
        <table cellpadding="6" class="hours" v-html="scheduleHtml"></table>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment';
import { configuration } from '~/configuration';

export default {
  props: {
    schedule: { type: Array, required: true },
    interactive: { type: Boolean, default: false },
  },
  data: () => ({ configuration }),
  computed: {
    scheduleHtml() {
      if (this.schedule.length === 0) return;

      let str = '';
      let mapped = [];
      this.schedule.forEach((block) => {
        if (!mapped[`${block.day}`]) mapped[`${block.day}`] = [];

        mapped[`${block.day}`].push(block);
      });

      mapped.push(mapped.shift()); // move sunday to end

      mapped.forEach((day) => {
        if (!day) return;

        const d = moment().day(day[0].day).format('dddd');
        str += `<tr><td class="align-top" style="padding: 0;"><b>${d}&nbsp;&nbsp;&nbsp;</b></td><td style="padding:0;padding-bottom: 0.15rem;font-size:0.95em;">`;
        let lastBlock = null;
        day.forEach((block, i) => {
          const s = moment(block.start, 'HH:mm').format('h:mma');
          const e = moment(block.end, 'HH:mm').format('h:mma');

          if (lastBlock === null) {
            str += `${s} &ndash; `;
          } else if (lastBlock.end !== block.start) {
            str += moment(lastBlock.end, 'HH:mm').format('h:mma');
            str += `<br>${s}-`;
          }

          if (i === day.length - 1) str += e;

          lastBlock = block;
        });
        str += '</td></tr>';
      });
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

.dont-worry {
  transition: opacity 0.5s;
  font-weight: bold;
  font-size: 105%;
}

.btn {
  font-family: $staatliches-font;
  color: white;

  &.success {
    background-color: green;
    border-color: green;
  }

  &.error {
    background-color: red;
    border-color: red;
  }

  &:hover {
    color: var(--primary);
    background-color: $hover-bg-color;
    border-color: var(--primary);
  }
}

.card {
  border: none;
}

.card-body {
  padding-left: 0;
  padding-right: 0;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.7s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
</style>
