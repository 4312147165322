
  <path
    d="M23.69,39.39c-5.33-1.8-9.51-4.88-12.47-9.59c-2.17-3.45-3.13-7.24-3.26-11.28c-0.07-2.21-0.14-4.43-0.27-6.64
		c-0.02-0.37-0.27-0.79-0.54-1.07c-2.08-2.13-4.21-4.2-6.29-6.33c-1.47-1.5-1-3.59,0.92-4.32c1.11-0.42,1.99,0,2.77,0.78
		C6.59,2.98,8.63,5,10.64,7.06c0.47,0.48,0.95,0.66,1.63,0.7c3.75,0.25,7.6,0.13,11.23,0.96c7.68,1.76,12.78,6.69,15.42,14.12
		c0.1,0.27,0.21,0.53,0.41,1.05c0.62-2.36,1.15-4.48,1.72-6.58c0.44-1.62,0.96-3.22,1.39-4.84c0.07-0.26-0.06-0.69-0.25-0.89
		c-1.63-1.69-3.31-3.33-4.96-5C36.03,5.36,35.94,3.93,37,2.82c1.11-1.16,2.56-1.13,3.83,0.13c3.97,3.94,7.94,7.86,11.88,11.83
		c3.09,3.11,5.94,6.39,7.17,10.82c2.15-0.87,3.73-0.01,5.15,1.5c1.76,1.88,3.64,3.66,5.36,5.57c1.03,1.15,1.96,2.42,2.75,3.75
		c0.79,1.32,1.34,2.77,2.04,4.23c1.99-0.63,3.62,0.1,5.04,1.64c2.16,2.33,4.6,4.43,6.51,6.93c4.09,5.33,5.33,11.43,3.76,17.98
		c-1.14,4.77-2.46,9.51-3.82,14.23c-0.28,0.98-0.15,1.5,0.54,2.18c3.96,3.89,7.88,7.83,11.8,11.77c1.37,1.38,1.3,3.01-0.14,4.09
		c-1.14,0.85-2.33,0.68-3.53-0.51c-4-4-8.01-7.98-11.98-12.01c-0.53-0.54-0.92-0.54-1.61-0.35c-5.47,1.47-10.9,3.22-16.47,4.2
		c-7.12,1.26-13.36-1.1-18.54-6.15c-1.51-1.48-2.96-3.02-4.51-4.47c-1.5-1.41-2.21-3.01-1.62-4.87c-2.07-1.12-4.28-1.93-6-3.34
		c-2.95-2.42-5.65-5.17-8.36-7.87c-1.11-1.11-1.17-2.6-0.77-3.96c-1.71-0.88-3.49-1.56-4.99-2.63c-2.06-1.47-4.01-3.13-5.83-4.9
		c-3.94-3.82-7.77-7.75-11.64-11.65c-1.63-1.64-1.13-3.98,0.95-4.53c1.04-0.28,1.87,0.13,2.59,0.85c1.64,1.64,3.29,3.26,4.91,4.91
		c0.38,0.38,0.66,0.42,1.18,0.27c3.29-0.95,6.6-1.84,9.91-2.75C22.95,39.59,23.32,39.49,23.69,39.39z M13.09,12.86
		c0,2-0.05,3.87,0.01,5.74c0.13,3.8,1.18,7.31,3.7,10.23c4.49,5.18,10.24,6.81,16.89,5.86c0.84-0.12,1-0.66,1.01-1.29
		c0.04-1.97,0.28-3.99-0.01-5.92c-0.98-6.31-4.37-10.88-10.39-13.29C20.65,12.74,16.76,13.18,13.09,12.86z M48.55,51.89
		c-2.13-1.93-3.97-3.78-5.99-5.4c-3.06-2.46-6.59-3.71-10.57-3.45c-5.47,0.36-10.56,2.38-15.82,3.6c1.64,1.69,3.12,3.44,4.82,4.94
		c3.57,3.13,7.79,4.6,12.53,3.98c2.5-0.33,4.96-1.06,7.42-1.66C43.36,53.3,45.77,52.63,48.55,51.89z M31.36,61.83
		c1.64,1.69,3.08,3.38,4.74,4.84c3.79,3.35,8.27,4.8,13.29,3.97c2.91-0.48,5.76-1.37,8.64-2.09c1.77-0.45,3.54-0.93,5.63-1.48
		c-1.77-1.68-3.28-3.25-4.92-4.65c-3.67-3.14-7.86-4.84-12.77-4.1c-2.05,0.31-4.09,0.72-6.1,1.21
		C37.02,60.23,34.21,61.05,31.36,61.83z M61.85,31.18c-1.16,4.48-2.36,8.7-3.33,12.96c-1.2,5.26,0.05,10.04,3.5,14.16
		c1.46,1.74,3.18,3.27,4.79,4.88c0.07,0.07,0.22,0.08,0.37,0.13c0.96-3.59,1.95-7.11,2.82-10.66c0.71-2.89,1.16-5.83,0.55-8.82
		C69.45,38.45,65.84,34.82,61.85,31.18z M78.49,82.36c0.05-0.12,0.11-0.23,0.16-0.35c-1.86-1.7-3.65-3.5-5.61-5.09
		c-3.3-2.69-7.11-4.05-11.4-3.51c-2.41,0.31-4.8,0.83-7.17,1.4c-2.68,0.64-5.33,1.44-7.98,2.16c1.6,1.65,3.08,3.36,4.74,4.85
		c3.7,3.3,8.15,4.82,13,4C69.04,85,73.74,83.54,78.49,82.36z M77.22,46.41c-0.11,0.03-0.21,0.06-0.32,0.08
		c-1.03,4.02-2.14,8.02-3.08,12.06c-0.82,3.54-0.85,7.09,0.67,10.49c1.68,3.77,4.47,6.67,7.53,9.34c0.06,0.05,0.17,0.03,0.27,0.04
		c1.06-4.01,2.16-7.98,3.15-11.98c1.24-5.02,0.52-9.76-2.62-13.91C81.17,50.34,79.1,48.45,77.22,46.41z M52.1,48.04
		c1.01-4.05,2.13-8.22,3.09-12.42c1.27-5.57-0.05-10.54-3.81-14.83c-1.44-1.65-3.02-3.18-4.67-4.9c-1.16,4.45-2.31,8.57-3.29,12.74
		c-1.25,5.28-0.18,10.13,3.3,14.29C48.28,44.78,50.25,46.29,52.1,48.04z"
  />
