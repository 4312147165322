
<b-form-group class="datePicker">
  <Label v-if="inputLabel">
    <template #title>
      {{ inputLabel }}
    </template>
  </Label>

  <b-form-datepicker 
    v-model="dateValue"
    :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
    :min="minDate"
    :max="maxDate"
    locale="en"
    hideHeader
    showDecadeNav
    width="100%"
  />
</b-form-group>
