<template>
  <div
    class="tag"
    :class="{ 'tag-selected': selected, [`tag-${size}`]: true }"
    :style="{
      backgroundColor: selected ? `color-mix(in srgb, ${value.color} 90%, white)` : `color-mix(in srgb, ${value.color} 50%, #fff)`,
      color: selected ? `color-mix(in srgb, ${value.color} 10%, black)` : `color-mix(in srgb, ${value.color} 50%, black)`,
      borderColor: selected ? `color-mix(in srgb, ${value.color} 10%, black)` : `color-mix(in srgb, ${value.color} 75%, black)`
    }">
    <span v-if="selected" class="selected"></span>
    <span v-else>{{ value.symbol }}</span>
  </div>
</template>

<script>
const requiredKeys = ['name', 'color', 'symbol']
const sizes = ['sm', 'md', 'lg']

export default {
  name: 'Tag',
  props: {
    value: {
      type: Object,
      required: true,
      validator: value => requiredKeys.every(key => key in value)
    },
    size: {
      type: String,
      default: sizes[1],
      validator: size => sizes.includes(size)
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectTag() {
      this.$emit('tag-selected', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 4px;
  display: flex;
  flex-grow: 0 !important;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 12px;
  font-family: Arial, sans-serif !important;
  border: 1px solid #000;
}

.tag-sm {
  font-size: 12px;
  width: 30px;
  height: 24px;
}

.tag-md {
  font-size: 16px;
  width: 40px;
  height: 32px;
}

.tag-lg {
  font-size: 20px;
  width: 50px;
  height: 40px;
}

.selected {
  background-color: #3597d3;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: block;
}
</style>
