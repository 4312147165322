<template>
  <canvas ref="weightChart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export default {
  props: {
    weights: {
      type: Array,
      required: true,
    },
    currentDate: {
      type: Date,
      required: true,
    },
    min: {
      type: Date,
      required: true,
    },
    max: {
      type: Date,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.renderChart();
  },

  watch: {
    weights: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
  },

  methods: {
    renderChart() {
      const weightData = {
        datasets: [
          {
            label: 'Weight Over Time',
            data: this.weights.map((w) => ({
              x: w.timestamp,
              y: w.weight,
            })),
            borderColor: JSON.parse(localStorage.getItem('personalization'))
              .theme_color_1,
            borderWidth: 5,
            tension: 5,
            cubicInterpolationMode: 'monotone',
            clip: false,
          },
        ],
      };

      const weightOptions = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          },
        },
        scales: {
          x: {
            weight: 10,
            type: 'time',
            time: {
              unit: this.unit,
            },
            min: this.min,
            max: this.max,
            grid: {
              display: true,
              drawBorder: false,
            },
            ticks: {
              display: true,
              maxTicksLimit: 12,
              font: {
                family: 'Red Hat Text',
                size: 14, // set the font size here
                weight: 'bold', // set the font weight here
              },
            },
          },

          y: {
            drawOnChartArea: false,
            drawTicks: false,
            grace: '20%',
            grid: {
              display: true,
            },
            ticks: {
              display: true,
              font: {
                family: 'Red Hat Text',
                size: 14, // set the font size here
                weight: 'bold', // set the font weight here
              },
            },
          },
        },
      };

      if (this.chartInstance) {
        this.chartInstance.data = weightData;

        this.chartInstance.options = weightOptions;
        this.chartInstance.update();
      } else {
        this.chartInstance = new Chart(
          this.$refs.weightChart.getContext('2d'),
          {
            type: 'line',
            data: weightData,
            options: weightOptions,
          }
        );
      }
    },
  },
  beforeDestroy() {
    if (this.chart) {
      // this.chartInstance.destroy();
    }
  },
};
</script>

<style lang="scss" scoped>
.chart {
  height: 100%;
}
</style>
