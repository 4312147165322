
<b-row align-h="center" class="pt-2 pb-1">
  <b-col :cols="cols" class="pr-1">
    <b-form-input
      v-model.number="value.quantityOrdered"
      :max="value.weight"
      min="1"
      step="1"
      type="number"
    />
  </b-col>
  <b-col :class="removable ? 'px-0' : 'pl-0'" :cols="cols">
    <b-form-select
      v-model="value.orderedWeight"
      :disabled="disableWeightSelect"
      :options="selectableSmartWeightOptions"
    />
  </b-col>
  <b-col v-if="removable" class="pl-0" cols="2">
    <b-button class="ml-1" variant="info" @click="removeFromCart(value)"
      >Remove</b-button
    >
  </b-col>
</b-row>
