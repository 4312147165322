<template #default="{ inputValue, inputEvents }">
  <div class="block">
    <Label>
      <template #title>
        <span>
          <span :class="{ animation: true, disabled: !!disabled }">{{
            label
          }}</span>
        </span>
      </template>
    </Label>
    <div class="input-block" :class="{ animation: true, disabled: !!disabled }">
      <div class="icon">
        <icon-base
          size="16"
          :icon-color="active ? '#22a9ff' : 'black'"
          :icon-name="label"
          :error="!disabled && error"
        >
          <component :is="icon" />
        </icon-base>
      </div>
      <input
        class="input"
        v-bind="$attrs"
        :value="value"
        :class="{
          input_withIcon: !!icon,
          error_input: !disabled && error,
        }"
        :placeholder="placeholder"
        :type="type"
        :readonly="readonly"
        :disabled="disabled"
        :min="min"
        :max="max"
        @blur="active = false"
        @focus="active = true"
        @input="$emit('input', $event.target.value)"
      />
      <span v-if="!disabled && error" class="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import Label from '@/common/Label/Label.vue';
import IconBase from '@/common/Icons/IconBase.vue';
import CalendarIcon from '@/common/Icons/CalendarIcon.vue';
import Button from '@/common/Button/Button.vue';

export default {
  components: { Label, IconBase, CalendarIcon, MyButton: Button },
  props: [
    'placeholder',
    'value',
    'label',
    'readonly',
    'icon',
    'type',
    'min',
    'max',
    'model',
    'button',
    'disabled',
    'error',
  ],
  data() {
    return {
      active: false,
    };
  },
  methods: {
    updateValue(event) {
      // emit the 'input' event with the new value as the payload
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.block {
  margin-top: 24px;
}
.input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $dark-gray;
  font-family: $main-font;
  font-weight: bold;
  font-size: 12px;
  padding: 8px 14px;
  &:focus,
  &:active {
    border: 1px solid var(--primary);
    outline: none;
  }
  &_withIcon {
    padding-left: 30px;
  }
}

.input-block {
  position: relative;
  margin-bottom: 16px;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.animation {
  transition: all 0.3s ease;
}
.input-block {
  position: relative;
}

.error_input {
  border: 1px solid $red;
  &:focus,
  &:active {
    border: 1px solid $red;
    outline: none;
    color: $red;
  }
}

.icon {
  position: absolute;
  top: 7px;
  left: 8px;
}

.error {
  position: absolute;
  bottom: -16px;
  font-size: 10px;
  font-family: $main-font;
  font-weight: bold;
  color: $red;
  left: 0;
}
</style>
