
<b-container class="login-container">
  <b-tabs
    v-model="registering"
    align-h="center"
    :class="alert.show ? 'mt-5' : 'mt-3'"
    fill
    active-nav-item-class="tabs"
  >
    <b-tab title="Login"></b-tab>
    <b-tab title="Register"></b-tab>
  </b-tabs>
  <b-row class="justify-center pt-5">
    <div class="col-12">
      <template v-if="cartProducts.length > 0">
        <h1 :class="(registering ? 'register' : 'login') +' display-5 align-center text-center'">{{ !registering ? 'Login' : 'Step 1: You' }}</h1>
        <p class="pt-2 px-2 text-center" v-if="!registering"><b>Login</b> to complete your order, or <b>register</b> in under 30 seconds <a class="primary" to="/register" @click="registering = 1"><u><b>here</b></u></a>.</p>
        <p class="pt-2 px-2 text-center" v-else><b>Register</b> in under 30 seconds to complete your order, or <b>login</b> <a class="primary" to="/login" @click="registering = 0"><u><b>here</b></u></a>.</p>
      </template>
      <template v-else>
        <h1 :class="(registering ? 'register' : 'login') +' display-5 align-center text-center'">{{ !registering ? 'Login' : 'Register' }}</h1>
        <p class="pt-2 px-2 text-center" v-if="!registering">Or <b>register</b> in under 30 seconds <a class="primary" to="/register" @click="registering = 1"><u><b>here</b></u></a>.</p>
        <p class="pt-2 px-2 text-center" v-else>Or <b>login</b> <a class="primary" to="/login" @click="registering = 0"><u><b>here</b></u></a>.</p>       
      </template>
    </div>
  </b-row>
  <b-row v-if="loading" key="loading" align-h="center">
    <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
  </b-row>
  <b-row v-else class="justify-center mx-auto">
    <b-container>
      <b-form class="contact-form" @submit.prevent="loginOrRegister">
        <b-form-row align-h="center">
          <b-col md="6">
            <b-form-group label="Email">
              <b-form-input
                v-model.trim="email"
                autocomplete="email"
                autofocus
                required
                type="email"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Password">
              <b-input-group>
                <b-form-input v-model="password" autocomplete="current-password" required :type="passwordType" :state="password.length == 0 ? null : password.length >= 8" :formatter="passwordFormatter" />
                <b-input-group-append>
                  <b-button variant="outline" @click="togglePasswordVisibility" class="show-password-button">
                    <b-icon :icon="passwordType === 'password' ? 'eye' : 'eye-slash'"  />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback id="input-live-feedback">
                Must be at least 8 characters.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <transition
          mode="out-in"
          name="fade"
          :duration="{ enter: 400, leave: 0 }"
        >
          <b-form-row v-if="registering" align-h="center">
            <b-col md="4">
              <b-form-group label="Confirm Password">
                <b-form-input v-model="confirmPassword" required :type="passwordType" :state="confirmPassword.length ? password === confirmPassword : null" :formatter="passwordFormatter">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="First Name">
                <b-form-input v-model.trim="first_name" required type="text">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Last Name">
                <b-form-input v-model.trim="last_name" required type="text">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Phone Number">
                <b-form-input
                  v-model.trim="phone"
                  v-mask="'+1(###)###-####'"
                  required
                  type="text"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </transition>

        <b-form-row
          v-if="
            registering && region.governance.EnableFeatureControlledSubstances
          "
          align-h-center
        >
          <b-col md="4">
            <b-form-group label="Photo ID (Front)">
              <b-form-file
                v-model="photoIDFront"
                accept=".jpg, .png, .gif"
                required
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Photo ID (Back)">
              <b-form-file
                v-model="photoIDBack"
                accept=".jpg, .png, .gif"
                required
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              description="Please hold up the front of your ID next to your face."
              label="Photo ID (Comparison)"
            >
              <b-form-file
                v-model="photoIDConf"
                accept=".jpg, .png, .gif"
                required
              ></b-form-file>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="font-italic text-muted"
              >Photo ID is required to verify your legal age and ensure the
              secure delivery of your orders. Photo IDs are stored securely on
              our servers and are not shared with any third-parties.</span
            >
          </b-col>
        </b-form-row>
        <b-row v-if="registering" align-h="center" class="mt-4 mb-4">
          <b-checkbox v-model="agreeTos" class="mr-1"></b-checkbox>
          By checking this box you agree with our&nbsp;<a
            :href="configuration.tosUrl"
            target="_blank"
            required
            >Terms of Service</a
          >.
        </b-row>
        <b-row align-h="center" :class="registering ? '' : 'mt-5'">
          <b-button
            :class="(registering ? 'register' : 'login') + ' btn-cta'"
            size="lg"
            type="submit"
            variant="info"
            :disabled="
              registering == true &&
              (!agreeTos ||
                password.length < 8 ||
                password != confirmPassword)
            "
            >{{ !registering ? 'Login' : 'Register' }}</b-button
          >
        </b-row>

        <b-row align-h="center" class="mt-5">
          <router-link class="reset-link" :to="`/vendor/${vendor.uuid}/reset-notify`"
            >Forgot your password?</router-link
          >
        </b-row>
        
        <b-row
          v-if="showCredit && !registering"
          class="powered-by">      
          <b-img :src="preppedIconBlack" />&nbsp;Powered by&nbsp;<b>Prepped</b>&nbsp;|&nbsp;<a target="_blank" href="https://orderprepped.com">Meal Prep Software</a>.  
        </b-row>
      </b-form>

    </b-container>
  </b-row>
</b-container>
