
  <g>
    <path
      d="M32.88,100.2c-1.81-0.33-3.66-0.52-5.44-1C13.69,95.51,4.77,86.77,1.15,73c-2.51-9.57-0.83-18.69,4.22-27.2
		c4.86-8.19,9.69-16.4,14.51-24.61c1.29-2.19,2.9-4.07,4.89-5.64c0.35-0.28,0.6-0.84,0.66-1.3c0.62-4.57,1.2-9.15,1.79-13.73
		c0.01-0.09,0.05-0.18,0.09-0.3c5.59,0,11.17,0,16.83,0c0.4,3.11,0.8,6.2,1.2,9.29c0.21,1.68,0.39,3.36,0.65,5.03
		c0.06,0.37,0.33,0.79,0.62,1.02c1.98,1.54,3.58,3.39,4.85,5.55c4.93,8.38,9.87,16.75,14.81,25.12c3.71,6.28,5.52,13.08,4.97,20.34
		c-1,13.24-7.42,23.11-19.02,29.55c-4.05,2.25-8.44,3.5-13.05,3.92c-0.22,0.02-0.43,0.11-0.65,0.17
		C36.65,100.2,34.76,100.2,32.88,100.2z M65.46,64.51c-0.08-5.51-1.5-10.64-4.31-15.39c-4.95-8.36-9.86-16.74-14.82-25.1
		c-4.95-8.34-16.32-8.34-21.25,0c-4.97,8.39-9.92,16.79-14.86,25.2c-3.81,6.48-5.06,13.41-3.73,20.83
		C9.31,85.81,24.86,96.7,40.66,93.93C55.01,91.41,65.44,79.05,65.46,64.51z M31.68,12.09c2.73,0,5.33,0,8.06,0
		c-0.25-1.91-0.51-3.97-0.78-6.01c-2.25,0-4.35,0-6.52,0C32.18,8.15,31.92,10.19,31.68,12.09z"
    />
    <path
      d="M100,33.58c-0.13,0.56-0.28,1.12-0.4,1.68c-1.63,7.22-8.46,13.46-17.45,12.77c-7.64-0.59-13.89-6.59-14.94-14.01
		c-0.55-3.92,0.13-7.59,2.18-10.95c4.59-7.51,9.24-14.99,13.86-22.48c0.07-0.11,0.16-0.2,0.29-0.37c0.49,0.77,0.95,1.5,1.41,2.24
		c4.11,6.66,8.22,13.33,12.34,19.99c1.3,2.1,2.23,4.32,2.55,6.78c0.02,0.16,0.11,0.3,0.17,0.45C100,30.98,100,32.28,100,33.58z
		M83.53,11.49c-0.08,0.06-0.14,0.1-0.17,0.15c-3.09,5-6.27,9.95-9.22,15.03c-2.13,3.65-1.57,8.38,1.16,11.59
		c2.94,3.46,6.71,4.72,11.1,3.52c6.76-1.84,9.81-9.64,6.17-15.73c-2.83-4.74-5.79-9.4-8.69-14.1
		C83.77,11.78,83.65,11.64,83.53,11.49z"
    />
    <path
      d="M35.67,46.48c10-0.01,18.08,8.04,18.09,18c0.01,10.06-8.03,18.14-18.05,18.13c-9.97,0-18.03-8.07-18.04-18.05
		C17.66,54.57,25.71,46.49,35.67,46.48z M35.66,76.76c6.63,0.03,12.19-5.46,12.25-12.12c0.06-6.68-5.46-12.28-12.15-12.31
		c-6.64-0.03-12.19,5.46-12.25,12.11C23.45,71.13,28.98,76.73,35.66,76.76z"
    />
  </g>
