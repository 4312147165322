
<div class="d-inline">
  <span
    v-if="prepend && currency !== 0"
    class="currency-symbol"
    :style="`font-size:${secondaryFontSize}`"
    >{{ prepend }}</span
  >
  <span
    v-if="currency === 0"
    class="currency ma-0 pa-0"
    :style="`font-size:${primaryFontSize}`"
    >FREE</span
  >
  <span v-else>
    {{ currency }}
  </span>
  <span
    v-if="append && currency !== 0"
    class="currency-symbol"
    :style="`font-size:${secondaryFontSize}`"
    >{{ append }}</span
  >
</div>
