<template>
  <b-row class="py-2 text-left" align-v="center">
    <b-col cols="8">
      <b-row>
        <b-col cols="12"
          ><span class="pr-1">{{ value.name }}</span></b-col
        >
        <b-col cols="12">
          <currency
            v-if="Number(value.price_adjustment) > 0"
            primary-font-size="1rem"
            secondary-font-size="0.9rem"
            prepend="$"
            :currency="value.price_adjustment"
          />
          <span
            v-if="value.min_quantity > 0"
            class="font-italic footnote-text highlight pl-1"
            >*This item is required</span
          >
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="4">
      <div class="float-right d-flex align-items-center">
        <template v-if="isBinary">
          <b-checkbox
            :checked="value.item_quantity > 0"
            size="lg"
            :disabled="value.item_quantity < 1 && disabled"
            @change="value.item_quantity === 0 ? addQty() : subQty()"
          />
        </template>
        <div v-else class="d-flex align-center">
          <b-btn class="mr-1 btn-outline" :disabled="!canSubQty" @click="subQty"
            ><b-icon-dash size="xl"></b-icon-dash
          ></b-btn>
          <b-btn
            class="mr-1 btn-outline"
            :disabled="!canAddQty || disabled"
            @click="addQty"
            ><b-icon-plus size="xl"></b-icon-plus
          ></b-btn>
          <span class="qty pt-1 px-2 font-weight-bold">{{
            value.item_quantity !== 0 ? value.item_quantity : '&ndash;'
          }}</span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Currency from '@/Currency.vue';

export default {
  name: 'ModifierItem',
  components: { Currency },
  props: ['value', 'disabled'],
  computed: {
    isBinary() {
      return this.value.max_quantity === 1 && this.value.min_quantity === 0;
    },
    canSubQty() {
      return this.value.item_quantity > this.value.min_quantity;
    },
    canAddQty() {
      if (this.value.max_quantity === null) return true;

      return this.value.item_quantity < this.value.max_quantity;
    },
  },
  created() {
    if (this.value.item_quantity == null || isNaN(this.value.item_quantity))
      this.$set(this.value, 'item_quantity', this.value.default_quantity);
  },
  methods: {
    subQty() {
      if (this.canSubQty) this.value.item_quantity--;
    },
    addQty() {
      if (this.canAddQty) this.value.item_quantity++;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/variables.module.scss';

@include buttons;
@include inputs;

.highlight {
  color: var(--primary);
}

.footnote-text {
  font-size: 80%;
}

.modifier-header {
  border: 2px solid $primary-color;
  border-left: 0;
  border-right: 0;
}

.quantity-input {
  width: 40px;
  text-align: center;
}

.btn-outline {
  font-size: 1.25em !important;
}

.qty {
  text-align: right;
  width: 35px !important;
  max-width: 35px !important;
}

.item-row {
}
</style>
