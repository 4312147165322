<template>
  <div class="card">
    <div class="icon">
      <icon-base size="36" :icon-name="title">
        <component :is="icon" />
      </icon-base>
    </div>
    <div class="title">{{ title }}</div>
    <p v-if="!loading" class="basicInfo">{{ basicInfo }}</p>
    <div v-else class="skeleton">
      <skeleton />
    </div>
  </div>
</template>

<script>
import IconBase from '@/common/Icons/IconBase.vue';
import Skeleton from '@/common/Skeleton/Skeleton.vue';

export default {
  components: { IconBase, Skeleton },
  props: {
    title: { type: String, required: true },
    basicInfo: { type: String, required: true },
    icon: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';
.card {
  width: 100%;
  border-radius: 10px;
  border: 0;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  box-shadow: $basic-shadow;
  position: relative;
  text-align: center;
  font-family: $main-font;
  font-weight: bold;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;

  animation-duration: 0.5s;
  animation-name: animate-glow;
  animation-timing-function: ease;

  @keyframes animate-glow {
    0% {
      opacity: 0;
      filter: brightness(3) saturate(3);
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      filter: brightness(1) saturate(1);
      transform: scale(1, 1);
    }
  }
}
.icon {
  position: absolute;
  left: 50%;
  top: -16px;
  transform: translateX(-50%);
}

.title {
  margin-top: 30px;
  font-size: 12px;
  font-weight: 600;
  color: $dark-gray;
}

.skeleton {
  width: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  padding-bottom: 1rem;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
