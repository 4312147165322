<template>
  <b-table-simple v-if="macros.fibers || macros.fats || macros.proteins || macros.carbs || macros.calories" small responsive fixed class="text-center mb-1 product-macros-table">
    <b-thead class="product-macros-table__header">
      <b-tr>
        <b-th colspan="5">
          Nutrition Facts
          <span id="popover-target-1" class="ml-1 mdi mdi-information-outline" title="Sources" />
          <b-popover target="popover-target-1" triggers="hover" boundary-padding="90" title="Sources">
            <a href="https://fdc.nal.usda.gov/" target="_blank">🔎 https://api.nal.usda.gov/fdc</a>
          </b-popover>
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody class="product-macros-table__body">
      <b-tr>
        <b-th></b-th>
        <b-th>Fats</b-th>
        <b-th>Protein</b-th>
        <b-th>Carbs</b-th>
        <b-th>Calories</b-th>
      </b-tr>
      <b-tr>
        <b-th class="text-left">Nutrition</b-th>
        <b-td>
          <number :to="macros.fats" :duration="0.12" :format="formatGrams" />
        </b-td>
        <b-td>
          <number :to="macros.proteins" :duration="0.12" :format="formatGrams" />
        </b-td>
        <b-td>
          <number :to="macros.carbs" :duration="0.12" :format="formatGrams" />
        </b-td>
        <b-td>
          <number :to="macros.calories" :duration="0.12" :format="formatToMacro" />
        </b-td>
      </b-tr>
      <b-tr v-if="nutritionRecommendation && isGoalCenter ">
        <b-th class="text-left">Your Goal</b-th>
        <b-td>
          <span v-if="macros.fats">
            <span v-if="nutritionRecommendation && nutritionRecommendation.fat"> {{ Math.round((macros.fats / nutritionRecommendation.fat) * 100) }}% </span>
          </span>
          <span v-else>-</span>
        </b-td>
        <b-td>
          <span v-if="macros.proteins">
            <span v-if="nutritionRecommendation && nutritionRecommendation.protein"> {{ Math.round((macros.proteins / nutritionRecommendation.protein) * 100) }}% </span>
          </span>
          <span v-else>-</span>
        </b-td>
        <b-td>
          <span v-if="macros.carbs">
            <span v-if="nutritionRecommendation && nutritionRecommendation.carbs"> {{ Math.round((macros.carbs / nutritionRecommendation.carbs) * 100) }}% </span>
          </span>
          <span v-else>-</span>
        </b-td>
        <b-td>
          <span v-if="macros.calories">
            <span v-if="nutritionRecommendation && nutritionRecommendation.calories">{{ Math.round((macros.calories / nutritionRecommendation.calories) * 100) }}% </span>
          </span>
          <span v-else>-</span>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import { axios } from '^/axios';
import { mapGetters } from 'vuex';

export default {
  props: {
    macros: {
      type: Object,
      default: () => ({})
    },
    nutritions: {
      type: Object,
      default: () => ({})
    },
    isGoalCenter: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      nutritionRecommendation: null,
    }
  },

  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    async getNutritionRecommendation() {
      try {
        const response = await axios.get(`/user/${this.user.uuid}/nutrition-recommendations`)
        if (!response.calories) return
        this.nutritionRecommendation = response
      } catch (e) {
        await this.$store.dispatch('alertError', e.message)
      }
    },

    formatGrams(number) {
      return this.formatToMacro(number) + 'g'
    },

    formatToMacro(value) {
      return Math.max(value).toFixed(0)
    }
  },

  async mounted() {    
    if (Object.keys(this.nutritions).length) {
      this.nutritionRecommendation = this.nutritions
    } else if (this.user) {
      this.getNutritionRecommendation()
    }
  }
}
</script>

<style lang="scss" scoped>
.product-macros-table {
  &__header {
    color: white;
    background-color: var(--primary);
  }

  & td {
    vertical-align: middle;
  }

  &__body {
    font-size: 11px;
  }
}
</style>