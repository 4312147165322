<template>
  <b-row v-if="value" class="align-items-center align-items-sm-start">
    <b-col cols="2" class="d-flex align-items-center justify-content-center">
      <b-button class="btn" @click="collapse = !collapse"
        ><b-icon-pencil-fill
      /></b-button>
    </b-col>
    <b-col cols="6">
      <h3>{{ value.subscription_type }} Subscription</h3>
      <div class="shrink-text">
        <span
          >{{ frequency }} time<template v-if="frequency > 1">s</template> a
          week</span
        >
        &bull;
        <span>{{ value.order.type }}</span>
        <div>
          <span v-if="value.paused_until" class="paused"
            >Paused Until: {{ moment(value.paused_until).format('LL') }}
          </span>
        </div>

        <p v-if="value.problem" class="problem">
          {{ getProblem(value.problem) }}
        </p>
      </div>
    </b-col>
    <b-col cols="4">
      <b-button block class="btn" @click="$emit('update:viewSub', value)"
        >View</b-button
      >

      <b-button
        v-if="value.paused_until"
        block
        class="btn"
        @click="$emit('update:unpauseSub', value)"
        >Unpause</b-button
      >
      <b-button
        v-else
        block
        class="btn"
        @click="$emit('update:pauseSub', value)"
        >Pause</b-button
      >
      <b-button
        block
        class="btn cancel-btn"
        @click="$emit('update:unsub', value.uuid)"
        >Cancel</b-button
      >
    </b-col>
    <b-col v-if="message" cols="12">
      <b-alert dismissible show variant="primary">
        <span class="mdi mdi-alert-remove-outline"></span> {{ message }}
      </b-alert>
    </b-col>
    <b-col cols="12">
      <b-collapse v-model="collapse">
        <b-row v-if="!loading">
          <b-col cols="12" sm="10" md="8">
            <subscription-editor
              v-if="collapse"
              v-model="editedSubscription"
              :order="value.order"
              :subscription="value"
              :delivery-schedule-intervals="deliveryScheduleIntervals"
              :pickup-schedule-intervals="pickupScheduleIntervals"
              @message="setMessage"
            />
          </b-col>
          <b-col cols="10" offset="1">
            <hr />
          </b-col>
          <b-col cols="12">
            <h3>Subscription Payment Info:</h3>
            <payment-methods
              v-if="collapse"
              v-model="paymentMethodUuid"
              class="mb-2 px-0 px-sm-2"
            />
          </b-col>
          <b-col cols="12">
            <b-button
              class="btn float-right"
              :disabled="!canSave && !loading"
              @click="updateSubscription"
              >Save</b-button
            >
          </b-col>
        </b-row>
        <div v-else class="text-center">
          <b-spinner label="loading" />
        </div>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import PaymentMethods from '@/cart/PaymentMethods.vue';
import SubscriptionEditor from '@/subscriptions/SubscriptionEditor.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { axios } from '^/axios';

export default {
  name: 'SubscriptionListItem',
  components: { PaymentMethods, SubscriptionEditor },
  props: ['value', 'unsub', 'pauseSub', 'unpauseSub'],
  data: () => {
    return {
      message: null,
      paymentMethodUuid: null,
      loading: false,
      deliveryScheduleIntervals: [],
      pickupScheduleIntervals: [],
      collapse: true,
      editedSubscription: {
        days_of_week_times: Array(7),
      },
    };
  },
  async created() {
    this.loading = true;
    try {
      await Promise.all([
        this.getVendorDeliveryScheduleIntervals(),
        this.getVendorPickupScheduleIntervals(),
      ]);
    } catch (e) {
      await this.$store.dispatch('alertError', ['danger', e.message]);
    } finally {
      this.loading = false;
    }
    if (this.value.order.payment_method) {
      this.paymentMethodUuid = this.value.order.payment_method.uuid;
    }
  },
  methods: {
    getProblem(problem) {
      const problems = {
        InventoryIssue: () =>
          `This was no longer in stock, the last time we processed your subscription.`,
        RegionDisabled: () =>
          `The ${this.value.order.vendor.region_name} region is no longer operating. (I may need to add to API response)`,
        RegionSchedule: () =>
          `The ${this.value.order.vendor.region_name} region has changed operating hours. Please update your subscription`,
        VendorDisabled: () =>
          `${this.value.order.vendor.name} is no longer on Prepped. (I may need to add to API response)`,
        VendorSchedule: () =>
          `${this.value.order.vendor.name} has changed operating hours. Please update your subscription.`,
        PaymentMethod: () =>
          `The last time we processed this subscription, your payment was declined. Please update your card below.`,
        DeliveryZone: () =>
          `The ${this.value.order.vendor.region_name} region has changed it's delivery zone. Please update your subscription.`,
      };
      return problems[problem]();
    },
    setMessage(str) {
      this.message = str;
    },
    moment: function (val) {
      return moment(val);
    },
    async updateSubscription() {
      this.loading = true;
      try {
        await axios.put(`/subscription/${this.value.uuid}`, {
          ...this.editedSubscription,
          payment_method_uuid: this.paymentMethodUuid,
        });
        this.$emit('update');
        this.collapse = false;
      } catch (e) {
        await this.$store.dispatch('alertError', ['error', e.message]);
      } finally {
        this.loading = false;
      }
    },
    async getVendorDeliveryScheduleIntervals() {
      this.deliveryScheduleIntervals = await axios.get(
        `region/${this.region.uuid}/vendor/${this.value.order.vendor.uuid}/schedule/delivery-intervals`,
        {
          params: {
            prep_time_minutes: this.prepTimeInMinutes,
          },
        }
      );
    },
    async getVendorPickupScheduleIntervals() {
      this.pickupScheduleIntervals = await axios.get(
        `region/${this.region.uuid}/vendor/${this.value.order.vendor.uuid}/schedule/pickup-intervals`,
        {
          params: {
            prep_time_minutes: this.prepTimeInMinutes,
          },
        }
      );
    },
  },
  // watch: {
  //   async collapse(val) {
  //     if (val) {
  //       this.loading = true
  //       try {
  //         await Promise.all([this.getVendorDeliveryScheduleIntervals(), this.getVendorPickupScheduleIntervals()])
  //       } catch (e) {
  //         await this.$store.dispatch('alertError', ['danger', e.message])
  //       } finally {
  //         this.loading = false
  //       }
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(['region', 'vendorRegion']),
    frequency() {
      if (this.value) {
        return [
          this.value.sunday,
          this.value.monday,
          this.value.tuesday,
          this.value.wednesday,
          this.value.thursday,
          this.value.friday,
          this.value.saturday,
        ].filter((x) => !!x).length;
      } else {
        return 0;
      }
    },
    prepTimeInMinutes() {
      return this.vendorRegion.prep_time_minutes;
    },
    canSave() {
      return !!this.paymentMethodUuid;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

@include buttons;

.cancel-btn {
  background-color: $error-color;
}

.paused {
  background-color: var(--primary);
  color: white;
  padding: 4px 8px;
  border-radius: 15px;
  width: 100%;
  font-size: 10px;
  white-space: nowrap;
}
.problem {
  color: var(--red);
  font-weight: bold;
  padding: 4px 8px;
  padding-left: 0;
  border-radius: 15px;
  width: 100%;
  font-size: 10px;
}
</style>
