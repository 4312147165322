<template>
  <div :class="className">
    <p v-if="label" class="title">{{ label }}</p>
    <div class="options">
      <div
        v-for="option in options"
        :key="option.value"
        class="option"
        :class="{
          'option-active': activeButton === option.value,
          'option-small': small,
        }"
        @click="updateActiveButton(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'activeButton',
    event: 'onUpdateActiveButton',
  },

  tabs: {
    type: Array,
    required: true,
  },
  activeColor: {
    type: String,
    required: true,
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
      validator: (items) => {
        return items.every((item) => {
          return (
            typeof item.value === 'string' && typeof item.label === 'string'
          );
        });
      },
    },
    activeButton: { type: String, required: true },
    label: { type: String, default: '' },
    className: { type: String, default: '' },
    small: { type: Boolean, default: false },
  },
  methods: {
    updateActiveButton(option) {
      this.$emit('onUpdateActiveButton', option.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.title {
  font-family: $main-font;
  font-weight: 700;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 0;
  color: $dark-gray;
}

.options {
  display: flex;
  background-color: $light-gray;
  border-radius: 5px;
}

.option {
  width: 100%;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  font-family: $main-font;
  font-weight: 700;
  font-size: 12px;
  padding: 8px 14px;
  cursor: pointer;
}

.option-small {
  padding: 2px 0;
}

.option-active {
  color: var(--white);
  background: var(--primary);

  // box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  box-shadow: $basic-shadow;
  cursor: default;
}
</style>
