<template>
  <b-container>
    <b-row class="justify-center py-5">
      <div class="col-12">
        <h1 class="display-4 align-center text-center">Order History</h1>
      </div>
    </b-row>
    <div v-if="!loading">
      <b-row v-if="orders.length > 0" class="justify-content-center pb-4">
        <b-col cols="12" sm="10">
          <b-list-group>
            <order
              v-for="(order, index) in orders"
              :key="index"
              :index="index"
              :order="order"
              @reorder="checkReorder"
            />
          </b-list-group>
        </b-col>
      </b-row>
      <b-container v-else>
        <b-row align-h="center" class="text-center pt-3">
          <p><b>Hey!</b> You haven't placed any orders yet.</p>
        </b-row>
        <b-row align-h="center" class="mt-3">
          <b-button class="btn-cta" to="/">Shop!</b-button>
        </b-row>
      </b-container>
      <b-row v-if="orders.length > 0" class="justify-content-center">
        <b-pagination
          v-model="currentPage"
          :per-page="itemsPerPage"
          :total-rows="totalOrders"
          pills
        ></b-pagination>
      </b-row>
    </div>
    <b-row v-else class="justify-content-center">
      <template align-h="center">
        <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
      </template>
    </b-row>
    <b-modal
      v-model="reorderAdjustmentModal"
      cancel-title="Nevermind"
      no-close-on-backdrop
      no-close-on-esc
      ok-title="That's ok!"
      title="Some changes to your order!"
      @ok="applyReorderItems"
    >
      <h4>We don't have enough of the following items in stock:</h4>
      <br />
      <ul>
        <li v-for="(o, index) in orderAdjustments" :key="index">
          You ordered {{ o.from }} <span v-if="!o.ordered_weight">units</span
          ><span v-else>{{
            smartWeightOptions[o.ordered_weight].text.toLowerCase()
          }}</span>
          of {{ o.product.title }}, but we
          <span v-if="o.to > 0">
            only have {{ o.to }} in stock that we can include in your
            reorder.</span
          >
          <span v-else>
            don't have any in stock. It won't be included in this reorder.</span
          >
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { configuration } from '~/configuration';
import { axios } from '^/axios';
import { debounce } from 'lodash';
import Order from '@/order-history/Order.vue';
import { smartWeightOptions } from '~/enums/smartWeightOptions';
import loadingGif from '#/loading-small.gif';

export default {
  name: 'OrderHistory',
  components: { Order },
  data() {
    return {
      orders: [],
      currentPage: 1,
      totalOrders: 0,
      itemsPerPage: 5,
      loading: true,
      order: {},
      reorderAdjustmentModal: false,
      orderAdjustments: [],
      activeOrder: false,
      smartWeightOptions,
      configuration,
      loadingGif,
    };
  },
  computed: {
    ...mapGetters(['region', 'regionId', 'vendor']),
  },
  created() {
    this.getOrderHistory();
  },
  methods: {
    getOrderHistory: debounce(async function () {
      let url = 'user/orders';
      this.loading = true;
      let response = await axios.get(url, {
        params: {
          page: this.currentPage,
          per_page: this.itemsPerPage,
          region_id: this.regionId,
          vendor_uuid: this.vendor.uuid,
        },
      });
      this.orders = response.data;
      this.totalOrders = response.meta.total;
      this.loading = false;
    }, 500),
    checkReorder: async function (order) {
      this.order = order;
      let url = 'order/check-reorder';
      try {
        this.orderAdjustments = await axios.post(url, {
          order_uuid: order.uuid,
          region_id: this.regionId,
        });
        if (this.orderAdjustments.length > 0) {
          // Some items were not in stock
          this.reorderAdjustmentModal = true;
        } else {
          this.applyReorderItems();
        }
      } catch (error) {
        await this.$store.dispatch('alertError', { text: error.message });
      }
    },
    applyReorderItems() {
      this.$store.commit('clearCart');
      this.order.products.forEach((productWrapper) => {
        let product = productWrapper.product;
        product.quantityOrdered = productWrapper.smart_weight_purchase
          ? productWrapper.weight
          : productWrapper.quantity;
        product.orderedWeight = productWrapper.order_weight;
        product.weight = productWrapper.weight;

        if (this.region.governance.EnableFeatureModifiers) {
          product.modifiers.forEach((modifier) => {
            modifier.items.forEach((item) => {
              let found = productWrapper.modifier_items.find(
                (x) => x.uuid === item.uuid
              );
              if (found) this.$set(item, 'item_quantity', found.item_quantity);
            });
          });
        }

        this.$store.commit('addProductToCart', product);
        /* --- TODO: add this back in & reconfigure when product inventory is reimplemented
        let product = productWrapper.product
        let adjustment = this.orderAdjustments.find(p => p.product_uuid === product.uuid)
        if (adjustment) {
          if (adjustment.to === 0)
            product = null
          else
            product.quantityOrdered = adjustment.to
        } else {
          product.quantityOrdered = productWrapper.quantity
        }

        if (product && product.product_inventories) {
          let product_inventory = product.product_inventories[0]
          delete product.product_inventories
          product.quantity = product_inventory.quantity
          product.price = product_inventory.retail_price
          this.$store.commit('addProductToCart', product)
        }
         */
      });
      this.$router.push('/cart');
    },
  },
  watch: {
    currentPage() {
      this.getOrderHistory();
    },
  },
};
</script>

<style scoped>
>>> .page-item.active .page-link {
  background-color: #3e3e3e !important;
}

>>> .page-link {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}
</style>
<style lang="scss" scoped>
@import '../../styles/variables.module.scss';
@include buttons;
</style>
