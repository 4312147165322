
<b-row v-if="value" class="align-items-center align-items-sm-start">
  <b-col cols="2" class="d-flex align-items-center justify-content-center">
    <b-button class="btn" @click="collapse = !collapse"
      ><b-icon-pencil-fill
    /></b-button>
  </b-col>
  <b-col cols="6">
    <h3>{{ value.subscription_type }} Subscription</h3>
    <div class="shrink-text">
      <span
        >{{ frequency }} time<template v-if="frequency > 1">s</template> a
        week</span
      >
      &bull;
      <span>{{ value.order.type }}</span>
      <div>
        <span v-if="value.paused_until" class="paused"
          >Paused Until: {{ moment(value.paused_until).format('LL') }}
        </span>
      </div>

      <p v-if="value.problem" class="problem">
        {{ getProblem(value.problem) }}
      </p>
    </div>
  </b-col>
  <b-col cols="4">
    <b-button block class="btn" @click="$emit('update:viewSub', value)"
      >View</b-button
    >

    <b-button
      v-if="value.paused_until"
      block
      class="btn"
      @click="$emit('update:unpauseSub', value)"
      >Unpause</b-button
    >
    <b-button
      v-else
      block
      class="btn"
      @click="$emit('update:pauseSub', value)"
      >Pause</b-button
    >
    <b-button
      block
      class="btn cancel-btn"
      @click="$emit('update:unsub', value.uuid)"
      >Cancel</b-button
    >
  </b-col>
  <b-col v-if="message" cols="12">
    <b-alert dismissible show variant="primary">
      <span class="mdi mdi-alert-remove-outline"></span> {{ message }}
    </b-alert>
  </b-col>
  <b-col cols="12">
    <b-collapse v-model="collapse">
      <b-row v-if="!loading">
        <b-col cols="12" sm="10" md="8">
          <subscription-editor
            v-if="collapse"
            v-model="editedSubscription"
            :order="value.order"
            :subscription="value"
            :delivery-schedule-intervals="deliveryScheduleIntervals"
            :pickup-schedule-intervals="pickupScheduleIntervals"
            @message="setMessage"
          />
        </b-col>
        <b-col cols="10" offset="1">
          <hr />
        </b-col>
        <b-col cols="12">
          <h3>Subscription Payment Info:</h3>
          <payment-methods
            v-if="collapse"
            v-model="paymentMethodUuid"
            class="mb-2 px-0 px-sm-2"
          />
        </b-col>
        <b-col cols="12">
          <b-button
            class="btn float-right"
            :disabled="!canSave && !loading"
            @click="updateSubscription"
            >Save</b-button
          >
        </b-col>
      </b-row>
      <div v-else class="text-center">
        <b-spinner label="loading" />
      </div>
    </b-collapse>
  </b-col>
</b-row>
