export const smartWeightOptionsEnum = {
  MILLIGRAMS: 0,
  GRAMS: 1,
  OZ: 2,
};

export const smartWeightOptions = [
  { text: 'Miligrams', value: smartWeightOptionsEnum.MILLIGRAMS },
  { text: 'Grams', value: smartWeightOptionsEnum.GRAMS },
  { text: 'Ounces', value: smartWeightOptionsEnum.OZ },
];

export const smartWeightAbrOptions = [
  { text: 'mg', value: smartWeightOptionsEnum.MILLIGRAMS },
  { text: 'g', value: smartWeightOptionsEnum.GRAMS },
  { text: 'oz', value: smartWeightOptionsEnum.OZ },
];
