
<div>
  <div v-if="loading" class="text-center">
    <b-spinner label="loading" />
  </div>
  <div v-else>
    <b-tabs v-model="tabs" align="center" justified>
      <b-tab title="Add a Credit Card">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form
            class="credit-card-form"
            @submit.stop.prevent="handleSubmit(createPaymentMethod)"
          >
            <b-row class="mt-4">
              <b-col md="3">
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ required: true }"
                  name="Credit Card Number"
                >
                  <b-form-group label="Credit Card Number">
                    <b-form-input
                      v-model.trim="paymentMethod.ccnumber"
                      :state="validateState(validationContext)"
                      :aria-describedby="`cardNumber-feedback-${_uid}`"
                      name="cardNumber"
                      placeholder="4111 1111 1111 1111"
                      required
                      type="text"
                      maxlength=18
                    />

                    <b-form-invalid-feedback
                      :id="`cardNumber-feedback-${_uid}`"
                      >Please enter a valid credit card
                      number.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ required: true, min: 3, max: 64 }"
                  name="Cardholder Name"
                  :custom-messages="{
                     required: 'Please enter the cardholder\'s name.',
                      min: 'Cardholder name must be at least 3 characters long.',
                      max: 'Cardholder name cannot exceed 64 characters.'
                  }"
                >
                  <b-form-group label="Cardholder Name">
                    <b-form-input
                      v-model.trim="paymentMethod.cardholder_name"
                      :state="validateState(validationContext)"
                      :aria-describedby="`cardName-feedback-${_uid}`"
                      name="cardName"
                      type="text"
                      maxlength="64"
                    />

                    <b-form-invalid-feedback :id="`cardName-feedback-${_uid}`">
                      <!-- Please enter the cardholder's name. -->
                      {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="6" md="2" sm="6">
                <b-form-group label="Exp. Month">
                  <b-form-select
                    v-model="paymentMethod.exp_month"
                    :options="months"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="2" sm="6">
                <b-form-group label="Exp. Year">
                  <b-form-select
                    v-model="paymentMethod.exp_year"
                    :options="years"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <validation-provider
                  v-slot="validationContext"
                  :rules="cvvRules"
                  name="CVV"
                >
                  <b-form-group label="CVV/CVC">
                    <b-form-input
                      v-model.trim="paymentMethod.cvv"
                      v-mask="cvvMask"
                      :state="validateState(validationContext)"
                      :aria-describedby="`cardCvv-feedback-${_uid}`"
                      name="cardCvv"
                      required
                      type="text"
                      :maxlength="maxCvv"
                    />

                    <b-form-invalid-feedback :id="`cardCvv-feedback-${_uid}`"
                      >Please enter a valid CVV/CVC</b-form-invalid-feedback
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col class="col-sm-12 col-md-8 col-12 billing-address">
                <div>
                  <Address
                    v-model="selectedAddressAutocomplete"
                    label="Billing address"
                    @clear="clearAddress"
                    required
                  />
                </div>
              </b-col>

              <b-col class="mt-4 col-12 col-sm-12 col-md-4 billing-unit">
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ max: 32 }"
                  name="unit"
                >
                  <b-form-group label="Address unit" label-class="p-0">
                    <b-form-input
                      v-model.trim="unit"
                      :state="validateState(validationContext)"
                      :aria-describedby="`unit-feedback-${_uid}`"
                      name="unit"
                      type="number"
                    />

                    <b-form-invalid-feedback :id="`unit-feedback-${_uid}`">
                      Please enter a valid unit
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col class="text-center" md="12">
                <b-button type="submit" class="btn card-btn"
                  >Add Card</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab title="My Cards">
        <div v-if="paymentMethods && paymentMethods.length > 0" class="mt-2">
          <div class="mb-2">Please select a credit card:</div>
          <!-- First Payment Method -->
          <payment-method
            v-if="firstPaymentMethod"
            v-model="firstPaymentMethod"
          >
            <template #delete>
              <b-btn
                pill
                size="sm"
                variant="danger"
                @click="
                  lastActiveId = firstPaymentMethod.uuid;
                  deleteModal = true;
                "
              >
                <b-icon-trash-fill scale="0.8" />
              </b-btn>
            </template>
            <template #action>
              <b-form-radio-group v-model="selectedPaymentMethod">
                <b-radio :value="firstPaymentMethod.uuid" />
              </b-form-radio-group>
            </template>
          </payment-method>
          <div
            v-if="paymentMethods && morePaymentMethods.length > 0"
            class="py-2"
          >
            <a
              href="#"
              @click.prevent="
                showMorePaymentMethods = !showMorePaymentMethods
              "
            >
              <span v-if="!showMorePaymentMethods">See all credit cards</span>
              <span v-else>Hide all credit cards</span>
            </a>
          </div>
          <!-- More Payment Methods -->
          <b-collapse v-model="showMorePaymentMethods">
            <div v-for="(p, index) in morePaymentMethods" :key="index">
              <payment-method :value="p">
                <template #delete>
                  <b-btn
                    pill
                    size="sm"
                    variant="danger"
                    @click="
                      lastActiveId = p.uuid;
                      deleteModal = true;
                    "
                  >
                    <b-icon-trash-fill scale="0.8" />
                  </b-btn>
                </template>
                <template #action>
                  <b-form-radio-group v-model="selectedPaymentMethod">
                    <b-radio :value="p.uuid" />
                  </b-form-radio-group>
                </template>
              </payment-method>
            </div>
          </b-collapse>
        </div>
        <div v-else class="py-5 text-center">
          No credit cards on record. Please add one.
        </div>
      </b-tab>
    </b-tabs>
    <b-alert v-model="showError" class="mt-2" dismissible variant="danger"
      ><span>{{ errorMessage }}</span></b-alert
    >
    <b-alert
      :show="alertCountDown"
      class="mt-2"
      dismissible
      variant="success"
      @dismissed="alertCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <span>{{ feedbackMessage }}</span>
      <br />
      <b-progress
        v-show="false"
        :max="defaultSecs"
        :value="alertCountDown"
        height="4px"
        precision="2"
        variant="success"
      ></b-progress>
    </b-alert>
  </div>
  <b-modal
    v-model="deleteModal"
    button-size="sm"
    ok-title="Delete"
    ok-variant="danger"
    size="md"
    title="Are you sure you want to delete this credit card?"
    @ok="deletePaymentMethod(lastActiveId)"
  />
</div>
