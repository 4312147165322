<template>
  <div class="wrapper">
    <div class="header-container">
      <h3 class="header">Track Your Progress.</h3>
      <div class="metric-switch">
        <RadioSelect
          v-model="weightMeasurement"
          :active-button="weightMeasurement"
          :small="true"
          :options="[
            { value: 'kg', label: 'kg' },
            { value: 'lbs', label: 'lbs' },
          ]"
        />
      </div>
    </div>

    <InputWithButton
      v-model="weight"
      placeholder="Put your weight"
      type="number"
      :min="weightMeasurement === 'kg' ? 40 : 89"
      :max="weightMeasurement === 'kg' ? 1000 : 2204"
      :on-button-click="saveWeight"
      :formatter="weightFormatter"
    >
      <template #icon>
        <ScaleIcon />
      </template>

      <template #title>
        <Label>
          <template #title>
            <span>
              <span>{{
                weightMeasurement === 'kg'
                  ? 'Current weight (kg)'
                  : 'Current weight (lbs):'
              }}</span>
            </span>
          </template>
        </Label>
      </template>
    </InputWithButton>

    <!-- //TODO Move switcher to separate component -->
    <div class="filter">
      <RadioSelect
        v-model="dateRange"
        :options="[
          { value: 'w', label: 'W' },
          { value: 'm', label: 'M' },
          { value: '6m', label: '6M' },
          { value: 'y', label: 'Y' },
        ]"
        :active-button="dateRange"
      />
    </div>
    <div class="chart">
      <div v-if="loading">Loading</div>
      <weight-chart
        v-else
        :weights="weights"
        :current-date="currentDate"
        :min="new Date(startDate)"
        :max="new Date(endDate)"
        :unit="unit"
        :loading="loading"
      ></weight-chart>
    </div>
    <div v-if="!loading" class="weight-history">
      <div
        v-for="(currentWeight, index) in weights"
        :key="currentWeight.timestamp.toLocaleString()"
        class="weight-record"
      >
        <div>
          {{ convertTime(currentWeight.timestamp) }}
        </div>
        <div>
          {{ currentWeight.weight }}
        </div>
        <div :class="changeType(index)">
          {{
            index < weights.length - 1
              ? percentageChange(
                  currentWeight.weight,
                  weights[index + 1].weight
                )
              : ''
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputWithButton from '@/common/Input/InputWithButton.vue';
import ScaleIcon from '@/common/Icons/ScaleIcon.vue';
import RadioSelect from '@/common/RadioSelect/RadioSelect.vue';
import WeightChart from '@/Macronutrients/Progress/WeightChart.vue';
import moment from 'moment';
import { axios } from '^/axios';
import { mapGetters } from 'vuex';
import Label from '@/common/Label/Label.vue';
import { convertKgToLbs, convertLbsToKg } from '%/convertMeasurement';
import IconBase from '@/common/Icons/IconBase.vue';

export default {
  components: { InputWithButton, RadioSelect, WeightChart, Label, IconBase, ScaleIcon },

  props: {
    onSaveFinished: { type: Function, default: () => {} },
  },

  data() {
    return {
      dateRange: 'y',
      startDate: '',
      endDate: '',
      unit: 'day',
      loading: false,
      weight: null,
      currentDate: new Date(),
      weights: [],
      convertKgToLbs
    };
  },

  computed: {
    ...mapGetters(['user', 'weightMeasurement']),
    weightMeasurement: {
      get() {
        return this.$store.getters.weightMeasurement;
      },
      set(value) {
        if (value === this.weightMeasurement) return;
        this.weights = this.weights.map(({ timestamp, weight }) => ({
          timestamp,
          weight:
            value === 'kg'
              ? convertLbsToKg(weight).toFixed(2)
              : convertKgToLbs(weight).toFixed(2),
        }));
        if (this.weight) {
          this.weight =
            value === 'kg'
              ? convertLbsToKg(this.weight).toFixed(2) || null
              : convertKgToLbs(this.weight).toFixed(2) || null;
        }
        this.$store.dispatch('setWeightMeasurement', value);
      },
    },
    isKg() {
      return this.weightMeasurement === 'kg';
    },
  },

  methods: {
    convertTime(timestamp) {
      return moment(timestamp).format('MMM DD/YYYY HH:MM:SS');
    },

    percentageChange(currentValue, nextValue) {
      const change = (currentValue - nextValue) / nextValue;
      const percentage = (change * 100).toFixed(2);
      return `${percentage}%`;
    },

    changeType(index) {
      const current = this.weights[index].weight;
      const prev = this.weights[index + 1]
        ? this.weights[index + 1].weight
        : null;
      const difference = current - prev;
      if (difference > 0) {
        return 'increase';
      } else if (difference < 0) {
        return 'decrease';
      } else {
        return 'same';
      }
    },

    getRangeDiapason(dataRange) {
      let startDate;
      let unit;
      switch (dataRange) {
        case 'w':
          startDate = moment().subtract(1, 'week');
          unit = 'day';
          break;
        case 'm':
          startDate = moment().subtract(1, 'month');
          unit = 'week';
          break;
        case '6m':
          startDate = moment().subtract(6, 'month');
          unit = 'month';
          break;
        case 'y':
          startDate = moment().subtract(1, 'year');
          unit = 'month';
          break;
        default:
          break;
      }
      return {
        startDate: startDate.toDate(),
        endDate: moment().toDate(),
        unit,
      };
    },

    setActualDateRange() {
      const { startDate, endDate, unit } = this.getRangeDiapason(
        this.dateRange
      );
      this.startDate = startDate;
      this.endDate = endDate;
      this.unit = unit;
    },

    async getWeight() {
      this.loading = true;

      try {
        this.loading = true;
        const response = await axios.get(
          `/user/${this.user.uuid}/physicality/weight`,
          {
            cache: false,
            params: {
              start_date: this.startDate,
              end_date: moment().toDate(),
              _: new Date().getTime(),
            },
          }
        );
        this.weights = response.data.map((item) => ({
          timestamp: new Date(item.created_at),
          weight: this.isKg
            ? Number(item.weight_in_kg).toFixed(2)
            : convertKgToLbs(item.weight_in_kg).toFixed(2),
        }));
        this.loading = false;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.loading = false;
      }
    },

    async saveWeight() {
      this.setActualDateRange();

      try {
        this.loading = true;
        await axios.post(`/user/${this.user.uuid}/physicality/weight`, {
          weight_in_kg: this.isKg
            ? this.weight
            : convertLbsToKg(this.weight).toFixed(2),
        });
        this.weight = null;
        this.onSaveFinished();

        await this.getWeight();
        this.loading = false;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      }
    },

    weightFormatter(weight) {
      const decimals = `${weight}`.split('.')[1]
      if (decimals && decimals.length > 2) return (Math.floor(weight * 100) / 100).toFixed(2) 
      return weight
    }
  },

  async created() {
    this.setActualDateRange();
    await this.getWeight();
  },

  watch: {
    async dateRange(newVal) {
      this.dateRange = newVal;
      this.setActualDateRange();
      await this.getWeight();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 20px;
  font-weight: bold;
  font-family: $main-font;
}

.description {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
}

.chart {
  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  margin-top: 24px;
}

.metric-switch {
  width: 100px;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.weight-history {
  font-family: $main-font;
  font-weight: bold;
  padding: 0 20px;
}

.weight-record {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  &:not(:last-child) {
    border-bottom: 1px solid #d0d0d0;
  }

  > div {
    flex-basis: 25%; // Set the width of each div to one third of the container
    text-align: right; // Center the text within each div
  }

  > div:first-child {
    flex-basis: 50%; // Set the width of each div to one third of the container
    text-align: left; // Center the text within each div
  }

  > div:last-child {
    &.increase {
      color: #00a911;
    }

    &.same {
      color: #8e8e8e;
    }

    &.decrease {
      color: #f21400;
    }
  }
}
</style>
