
<div class="wrapper">
  <div class="header-container">
    <h3 class="header">Tell Us About You.</h3>
    <!-- //TODO Move switcher to separate component -->
  </div>
  <p class="description">
    We need this information to calculate your macronutrient intake
    recommendation!
  </p>
  <div class="form">
    <DatePicker v-model="birthday" :inputLabel="'Birthday:'" />

    <div class="height-block">
      <div class="metric-switch">
        <RadioSelect
          v-model="lengthMeasurement"
          :active-button="lengthMeasurement"
          :small="true"
          :options="[
            { value: 'cm', label: 'cm' },
            { value: 'inch', label: 'inch' },
          ]"
        />
      </div>
      <Slider v-model="height" :value="height" :min="70" :max="250">
        <template slot="label">
          <Label>
            <template #title>
              <span>
                <span>Height: </span>
                <span v-if="lengthMeasurement === 'cm'" class="value"
                  >{{ height }} cm</span
                >
                <span v-else class="value" e>{{
                  getHeightInInches(height)
                }}</span>
              </span>
            </template>
          </Label>
        </template>
      </Slider>
    </div>

    <RadioSelect
      v-model="gender"
      :options="[
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ]"
      :active-button="gender"
      :label="'Gender'"
    />

    <my-select
      v-model="goal"
      :label="'Weight Goal'"
      :options="[
        { value: 'lose', label: 'Cut Fat' },
        { value: 'gain', label: 'Gain Muscle' },
        { value: 'maintain', label: 'Maintain' },
      ]"
      :can-clear="false"
      :can-deselect="false"
    />

    <my-select
      v-model="activityLevel"
      :label="'Activity Level'"
      :options="[
        { value: 'sedentary', label: 'Sedentary' },
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Moderate' },
        { value: 'hight', label: 'Very Active' },
        { value: 'athlete', label: 'Athlete' },
      ]"
      :can-clear="false"
      :can-deselect="false"
    />
    <div
      class="advanced-settings-toggle"
      @click="showAdvanced = !showAdvanced"
    >
      <span>
        {{
          showAdvanced ? 'Hide Advanced Settings ' : 'Show Advanced Settings '
        }}
      </span>
      <div :class="{ icon: true, iconOpen: showAdvanced }">
        <icon-base size="12" :icon-color="personalization.theme_color_1">
          <arrow-icon></arrow-icon>
        </icon-base>
      </div>
    </div>

    <div :class="{ advancedSettings: true, hidden: !showAdvanced }">
      <my-select
        v-model="formula"
        :can-clear="false"
        :can-deselect="false"
        :options="[
          {
            value: FormulaEnum.MifflinStJeor,
            label: FormulaEnum.MifflinStJeor,
          },
          {
            value: FormulaEnum.Cunningham,
            label: FormulaEnum.Cunningham,
          },
          {
            value: FormulaEnum.HarrisBenedict,
            label: FormulaEnum.HarrisBenedict,
          },
          {
            value: FormulaEnum.KatchMcArdle,
            label: FormulaEnum.KatchMcArdle,
          },
        ]"
        :label="'Formula'"
      />

      <my-input
        v-model="bodyFatPercentage"
        :disabled="formula !== FormulaEnum.KatchMcArdle"
        placeholder="Put your body fat percentage"
        :icon="FatIcon"
        type="number"
        :min="1"
        :max="80"
        :label="'Body Fat percentage'"
        :error="validateFatPercentage"
      >
      </my-input>
    </div>

    <my-button
      :on-click="postPhysicality"
      center
      :disabled="
        !(gender && goal && activityLevel && height && birthday) ||
        (formula === FormulaEnum.KatchMcArdle &&
          bodyFatPercentage === null) ||
        validateFatPercentage
      "
      >Save</my-button
    >
  </div>
</div>
