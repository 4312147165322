<!-- // TODO: [Vue3_Migration] Rewrite it To use with native date picker for native apps (use Ionic7) -->
<!-- // TODO: [Vue3_Migration] Reuse input from UI lib  -->
<template>
  <b-form-group class="datePicker">
    <Label v-if="inputLabel">
      <template #title>
        {{ inputLabel }}
      </template>
    </Label>

    <b-form-datepicker 
      v-model="dateValue"
      :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
      :min="minDate"
      :max="maxDate"
      locale="en"
      hideHeader
      showDecadeNav
      width="100%"
    />
  </b-form-group>
</template>

<script>
import moment from 'moment';
import Label from '@/common/Label/Label.vue';

export default {
  components: {
    Label,
  },
  
  data() {
    return {
      active: false,
      minDate: moment(new Date()).subtract(100, 'years').toDate(),
      maxDate: moment(new Date()).subtract(14, 'years').toDate()
    };
  },
  props: {
    value: {
      type: [String, Date],
      default: undefined,
    },
    
    inputLabel: {
      type: String,
      default: ''
    }
  },

  computed: {
    dateValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    personalization() {
      return JSON.parse(localStorage.getItem('personalization'));
    }
  },

  methods: {
    formatDate(dateString) {
      if (dateString) {
        return moment(dateString, 'MM/DD/YYYY').format('MMMM D, YYYY');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/variables.module.scss';

.datePicker {
  margin-top: 30px;
  width: 100%;
  font-weight: bold !important;

  & * {
    font-weight: bold !important;
  }
}
</style>

<style lang="scss">
.datePicker {
  & .form-control {
    font-weight: bold;
    font-size: 12px;
  }
}
</style>
