
<div class="fees">
  <div v-show="title" class="title-block">
    <span class="title"
      >{{ title }}<span class="sizes"> ({{ sizes }})</span></span
    >
  </div>
  <div v-for="fee in fees" :key="fee.destination" class="fee">
    <span>
      {{ fee.type === 'flat' ? `Flat Rate: ` : `${fee.destination} miles` }}
    </span>
    <span v-if="!fee.isPricePerMile">
      ${{ fee.price.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}
    </span>
    <span v-else>
      ${{
        fee.price.toLocaleString('en-US', { minimumFractionDigits: 2 })
      }}
      per mile over {{ fee.overMilage }} miles
    </span>
  </div>
</div>
