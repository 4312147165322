
<div class="w-100">
  <modifier
    v-for="(modifier, i) in value"
    :key="modifier.uuid"
    v-model="value[i]"
    :valid="valid[i]"
    :is-mods-disabled = "isModsDisabled"
    :expanded="expanded"
  />
</div>
