<template>
  <b-row align-h="start" class="pt-2 pb-1 flex-grow-1">
    <b-col :class="removable ? 'pr-0' : ''" :cols="cols">
      <v-select
        v-model="selectedFixedOption"
        :append-to-body="true"
        :calculate-position="withPopper"
        :clearable="false"
        :options="completeFixedWeightOptions"
        :searchable="false"
        class="w-100"
        transition=""
      >
        <template slot="selected-option" slot-scope="option">
          <div class="d-flex justify-content-between">
            <span
              >{{ option.amount
              }}<span v-if="value.smart_weight_enabled">{{
                getAbrWeightLabel(option.weight)
              }}</span>
              for ${{ option.price }}</span
            >
          </div>
        </template>
        <template slot="option" slot-scope="option">
          <div class="d-flex justify-content-between position-relative">
            <span
              >{{ option.amount
              }}<span v-if="value.smart_weight_enabled">{{
                getAbrWeightLabel(option.weight)
              }}</span>
              for ${{ option.price }}</span
            >
            <span v-if="option.savings && option.savings > 0"
              ><b-badge variant="success"
                >-{{ option.savingsPercent }}%</b-badge
              ></span
            >
          </div>
        </template>
      </v-select>
    </b-col>
    <b-col v-if="removable" class="pl-0" cols="2">
      <b-button class="ml-1" variant="info" @click="removeFromCart(value)"
        >Remove</b-button
      >
    </b-col>
    <b-form-valid-feedback
      v-if="
        selectedFixedOption &&
        selectedFixedOption.savings &&
        selectedFixedOption.savings > 0
      "
      class="mx-3"
      force-show
    >
      You save ${{ bankersRound(selectedFixedOption.savings, 2) }}!
    </b-form-valid-feedback>
  </b-row>
</template>

<script>
import {
  bankersRound,
  getAbrWeightLabel,
  handleSmartWeightConversion,
  removeFromCart,
} from '^/utilities';
import { createPopper } from '@popperjs/core';

export default {
  name: 'FixedWeightControls',
  props: ['value', 'removable'],
  data() {
    return {
      selectedFixedOption: null,
      placement: 'bottom',
    };
  },
  computed: {
    cols() {
      return this.removable ? 8 : 12;
    },
    completeFixedWeightOptions() {
      let options = [];
      options.push({
        amount: 1,
        weight: this.value.sold_by_weight,
        price: this.value.price,
        savings: null,
        label: '',
      });
      return options.concat(
        this.value.fixed_weight_prices.map((option) => {
          if (!this.value.smart_weight_enabled) {
            option.amount = parseInt(option.amount);
          }

          let actualAmount;
          if (
            this.value.smart_weight_enabled &&
            option.weight !== this.value.sold_by_weight
          ) {
            actualAmount = handleSmartWeightConversion(
              option.amount,
              option.weight,
              this.value.sold_by_weight
            );
          } else {
            actualAmount = option.amount;
          }

          option.savings = this.value.price * actualAmount - option.price;

          option.savingsPercent = bankersRound(
            (option.savings / (this.value.price * actualAmount)) * 100,
            0
          );
          option.label = ''; // So v-select doesn't give us warnings
          return option;
        })
      );
    },
  },
  watch: {
    selectedFixedOption(val) {
      this.value.quantityOrdered = val.amount;
      this.value.orderedWeight = val.weight;
    },
  },
  created() {
    this.selectedFixedOption = this.completeFixedWeightOptions[0];
    if (this.value.quantityOrdered > 0) {
      let matchingOption = this.completeFixedWeightOptions.find((x) => {
        return (
          x.amount === this.value.quantityOrdered &&
          x.weight === this.value.orderedWeight
        );
      });
      if (matchingOption) this.selectedFixedOption = matchingOption;
    }
  },
  methods: {
    getAbrWeightLabel,
    bankersRound,
    removeFromCart,
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifier: [
          {
            name: 'offset',
            option: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='bottom'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>

<style scoped>
>>> .vs__dropdown-toggle {
  height: 38px;
}
</style>
