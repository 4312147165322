
<div>
  <div class="tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab"
      :class="{ active: activeTab === index, disabled: tab.disabled }"
      @click="activeTab = index"
    >
      <icon-base
        size="16"
        :icon-color="activeTab === index ? activeColor : '#7B7B7B'"
        :icon-name="tab.title"
      >
        <component :is="tab.icon" />
      </icon-base>
      <span
        :class="{ active: activeTab === index }"
        :style="{ color: activeTab === index ? activeColor : '#7B7B7B' }"
        >{{ tab.title }}</span
      >
    </div>
  </div>
  <div class="tab-content">
    <slot :name="tabs[activeTab].id"></slot>
  </div>
</div>
