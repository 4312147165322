<template>
  <b-list-group-item>
    <b-row cols="12">
      <b-col sm="3">
        Order <b>{{ order.code }}</b
        >: <i>{{ order.status }}</i>
      </b-col>
      <b-col sm="3">
        <span class="font-weight-light" style="font-size: 0.8em">TOTAL</span>
        ${{ order.breakdown.total }}
      </b-col>
      <b-col sm="4">
        <router-link :to="`/vendor/${order.vendor.uuid}`">{{
          order.vendor.name
        }}</router-link>
        <span class="float-right"
          ><a v-b-toggle="`products-${index}`" href="#" @click.prevent
            >[Show Details]</a
          ></span
        >
      </b-col>
      <b-col class="reorder-col" sm="2">
        <b-button class="btn float-right" @click="$emit('reorder', order)">
          <span v-if="!loading">Reorder</span>
          <b-spinner v-else small variant="light" />
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <b-collapse :id="`products-${index}`">
          <b-list-group class="my-2">
            <order-product
              v-for="(product, i) in order.products"
              :key="i"
              :product-wrapper="product"
              :reviewable="true"
            />
            <order-summary :order="order" />
          </b-list-group>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" style="font-size: 0.8em">
        <span class="font-weight-bold font-italic"
          >{{ order.type }}: &nbsp;</span
        >
        <span class="font-italic">{{
          order.type === 'Shipping' ? shippingOrderDate : orderDate
        }}</span>
        <span v-if="order.type == 'Delivery'" class="font-italic"
          >&nbsp;departure time.</span
        >
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import moment from 'moment';
import OrderProduct from '@/order-history/OrderProduct.vue';
import OrderSummary from '@/order-history/OrderSummary.vue';

export default {
  name: 'Order',
  components: { OrderProduct, OrderSummary },
  props: ['order', 'index'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    orderDate() {
      return moment(this.order.scheduled).format('MMMM D, YYYY - h:mma');
    },
    shippingOrderDate() {
      return moment(this.order.scheduled).format('MMMM D, YYYY');
    },
  },
  methods: {
    async handleCheckReorder() {
      this.loading = true;
      await this.reorderCallback(this.order);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

@include buttons;

@media #{$belowMd} {
  .reorder-col {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .order-details {
    float: left !important;
  }
}
</style>
