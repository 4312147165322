
<div class="wrapper">
  <div class="header-container">
    <h3 class="header">Track Your Progress.</h3>
    <div class="metric-switch">
      <RadioSelect
        v-model="weightMeasurement"
        :active-button="weightMeasurement"
        :small="true"
        :options="[
          { value: 'kg', label: 'kg' },
          { value: 'lbs', label: 'lbs' },
        ]"
      />
    </div>
  </div>

  <InputWithButton
    v-model="weight"
    placeholder="Put your weight"
    type="number"
    :min="weightMeasurement === 'kg' ? 40 : 89"
    :max="weightMeasurement === 'kg' ? 1000 : 2204"
    :on-button-click="saveWeight"
    :formatter="weightFormatter"
  >
    <template #icon>
      <ScaleIcon />
    </template>

    <template #title>
      <Label>
        <template #title>
          <span>
            <span>{{
              weightMeasurement === 'kg'
                ? 'Current weight (kg)'
                : 'Current weight (lbs):'
            }}</span>
          </span>
        </template>
      </Label>
    </template>
  </InputWithButton>

  <!-- //TODO Move switcher to separate component -->
  <div class="filter">
    <RadioSelect
      v-model="dateRange"
      :options="[
        { value: 'w', label: 'W' },
        { value: 'm', label: 'M' },
        { value: '6m', label: '6M' },
        { value: 'y', label: 'Y' },
      ]"
      :active-button="dateRange"
    />
  </div>
  <div class="chart">
    <div v-if="loading">Loading</div>
    <weight-chart
      v-else
      :weights="weights"
      :current-date="currentDate"
      :min="new Date(startDate)"
      :max="new Date(endDate)"
      :unit="unit"
      :loading="loading"
    ></weight-chart>
  </div>
  <div v-if="!loading" class="weight-history">
    <div
      v-for="(currentWeight, index) in weights"
      :key="currentWeight.timestamp.toLocaleString()"
      class="weight-record"
    >
      <div>
        {{ convertTime(currentWeight.timestamp) }}
      </div>
      <div>
        {{ currentWeight.weight }}
      </div>
      <div :class="changeType(index)">
        {{
          index < weights.length - 1
            ? percentageChange(
                currentWeight.weight,
                weights[index + 1].weight
              )
            : ''
        }}
      </div>
    </div>
  </div>
</div>
