<template>
  <div>
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        :class="{ active: activeTab === index, disabled: tab.disabled }"
        @click="activeTab = index"
      >
        <icon-base
          size="16"
          :icon-color="activeTab === index ? activeColor : '#7B7B7B'"
          :icon-name="tab.title"
        >
          <component :is="tab.icon" />
        </icon-base>
        <span
          :class="{ active: activeTab === index }"
          :style="{ color: activeTab === index ? activeColor : '#7B7B7B' }"
          >{{ tab.title }}</span
        >
      </div>
    </div>
    <div class="tab-content">
      <slot :name="tabs[activeTab].id"></slot>
    </div>
  </div>
</template>

<script>
import IconBase from '@/common/Icons/IconBase.vue';
import InfoCard from '@/common/InfoCard/InfoCard.vue';

export default {
  components: { IconBase, InfoCard },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
  },
  watch: {
    tabs: {
      handler(newTabs) {
        return newTabs;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.tabs {
  display: flex;
  margin-top: 32px;
}

.tab {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.3s ease;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:hover {
    box-shadow: $basic-shadow;
  }
}

.tab.active {
  box-shadow: $basic-shadow;
}

.tab span {
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
}

.tab-content {
  margin-top: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}
</style>
