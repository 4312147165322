import { axios } from '^/axios';
import { darken, lighten } from 'polished';
import { configuration } from '~/configuration';
import { Capacitor } from '@capacitor/core';

const fetchAndSettingsColors = async () => {
  let url = '';
  let params = {};
  if (Capacitor.isNativePlatform()) {
    url = `vendor/${configuration.vendorUuid}`;
  } else {
    url = 'vendor';
    params = { domain: window.origin };
  }
  return axios.get(url, { params }).then((res) => {
    res.theme_color_1 = res.settings.theme_color_1 || '#1c2833';
    res.theme_color_2 = res.settings.theme_color_2 || '#000000';

    localStorage.setItem('personalization', JSON.stringify(res));

    document
      .getElementById('favicon')
      .setAttribute('href', res.settings.theme_mobile_logo_url);
    document.documentElement.style.setProperty(
      '--primary',
      res.settings.theme_color_1
    );
    document.documentElement.style.setProperty(
      '--secondary',
      res.settings.theme_color_2
    );
    document.documentElement.style.setProperty(
      '--info',
      res.settings.theme_color_2
    );
    document.documentElement.style.setProperty(
      '--circle-conic-gradient',
      `conic-gradient(
        from 180deg at 50% 50%,
        ${res.settings.theme_color_1} 0deg,
        ${lighten(0.2, res.settings.theme_color_1)} 100deg,
        ${darken(0.2, res.settings.theme_color_1)} 200deg,
        ${lighten(0.2, res.settings.theme_color_1)} 300deg,
        ${res.settings.theme_color_1} 360deg
      )`
    );

    const style = document.createElement('style');

    style.textContent = res.settings.theme_background_url
      ? `
      body {background-color: transparent;}
      .backdrop { background-image: url(${res.settings.theme_background_url}) !important;}`
      : ``;

    document.head.appendChild(style);

    return res
  });
};

export default fetchAndSettingsColors;
