
<div class="block">
  <slot name="label" />
  <vue-slider
    v-model="currentValue"
    class="slider"
    :tooltip="false"
    process-class="process-bar"
    :bg-style="{
      backgroundColor: styles.lightGray,
      cursor: 'pointer',
    }"
    :process-style="{
      backgroundColor: personalization.theme_color_1,
      cursor: 'pointer',
    }"
    :marks="true"
    :data="data"
    :min="min"
    :max="max"
  >
    <template #dot><div class="dot"></div></template>
    <template #piecewise><div class="piecewise"></div></template>
  </vue-slider>
</div>
