
<b-modal
  v-model="active"
  :title="value.title"
  hide-footer
  @hidden="handleClose"
>
  <b-row>
    <b-col sm="6">
      <div class="product-img-wrapper mx-auto mx-sm-0">
        <b-badge v-if="value.brand" variant="info">{{
          value.brand.name
        }}</b-badge>
        <b-img
          :alt="value.title"
          :src="value.image"
          :title="value.title"
          fluid
          thumbnail
        />
        <div class="tags-wrapper">
          <tag
            v-for="tag in value.tags"
            :key="tag"
            v-b-popover.hover.top="tag.name"
            size="sm"
            :value="tag"
            class="mr-1"
          />
        </div>
      </div>
    </b-col>
    <b-col class="text-center text-md-right" sm="6">
      <b-form-rating
        v-model="value.rating"
        class="my-0 mx-0"
        color="gold"
        inline
        no-border
        readonly
        show-value
      />
      <div class="w-100 pr-3 reviews-wrapper">
        <a
          @click.prevent="
            () => {
              viewReviews($event, value);
              handleClose();
            }
          "
          >{{ value.ratings_count }} Reviews</a
        >
      </div>
      <div class="w-100 pr-3 pt-1">
        <b-badge
          v-for="(category, index) in value.category_list"
          :key="index"
          pill
          variant="info"
          >{{ category.category }}</b-badge
        >
      </div>
    </b-col>
  </b-row>
  <template v-if="value.description">
    <h5 class="pt-3">Description:</h5>
    <div v-html="value.description" />
  </template>
</b-modal>
