
<div class="gift-cards">
  <h1 class="pt-4 text-center">Gift Cards</h1>

  <div class="gift-cards__new__preview">
    <img v-if="vendor.logo_image" class="gift-cards__new__preview__logo" :src="vendor.logo_image">
    <div class="gift-cards__new__preview__title">{{ user.name }}'s {{ vendor.name }} Gift Card</div>
    <div class="gift-cards__new__preview__sum">{{ formatDefaultPrice(giftCards.balance) }}</div>
  </div>

  <div class="gift-cards__header">
    <div v-for="tab in OPTIONS" :key="tab.value" class="gift-cards__header__item"
      :class="{ 'gift-cards__header__item--active': activeTab === tab.value }" @click="changeTab(tab.value)">
      <button>{{ tab.label }}</button>
    </div>
  </div>

  <div class="gift-cards__list" v-if="activeTab === 'gift-cards-history'">
    <span class="gift-cards__list__title">Balance: {{ formatDefaultPrice(giftCards.balance) }}</span>

    <div v-for="card in giftCardsHistory" :key="card.id" class="gift-cards__list__item">
      <div>{{ moment(card.created_at).format('MMMM D, YYYY') }} - {{ card.label }}</div>
      <div>{{ card.type === 'credit' ? '+' : '-' }}{{ formatDefaultPrice(card.change) }}</div>
    </div>

    <div v-if="!giftCardsHistory.length" class="gift-cards__list__empty">You haven't used a Gift Card, yet!</div>
  </div>

  <div v-else-if="!vendor.is_active_payment">
    <b-alert show variant="info" fade>{{ vendor.name }} needs to activate a payment processor to enable this
      feature.</b-alert>
  </div>

  <div v-else-if="!vendor.settings.enable_gift_cards">
    <b-alert show variant="info" fade>{{ vendor.name }} needs to activate a gift cards to enable this
      feature.</b-alert>
  </div>

  <div v-else-if="activeTab === 'buy-new-gift-card'" class="gift-cards__new">
    <PaymentMethods v-model="form.payment_method_uuid" class="gift-cards__new__payment-methods" />

    <b-form class="gift-cards__new__form" @submit="onSubmit">
      <b-form-group id="receiver-group" label="For Who?" label-for="receiver">
        <b-form-select id="receiver" v-model="form.reciever" :options="newCardReceiverOptions" required />
      </b-form-group>

      <b-form-group v-show="form.reciever !== 'own'" id="email-group" label="Recipient Email Address"
        label-for="email">
        <b-form-input if="email" placeholder="mail@example.com" type="email" v-model="form.email"
          :required="form.reciever === 'present'" />
      </b-form-group>
      <b-form-group id="price-group" label="Credit Amount" label-for="price">
        <b-form-input id="price" placeholder="$100.00" type="number" step="1" v-model="form.price" required :min="15"
          :max="1000" :formatter="priceFormatter" />
      </b-form-group>

      <b-button type="submit" class="btn-cta" size="lg" variant="primary" :disabled="isPurchasing">
        <div v-show="isPurchasing" class="spinner-border text-light loading-icon" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Purchase
      </b-button>
    </b-form>

    <b-alert :show="showSuccessAlert" variant="success" class="gift-cards__new__alert" dismissible fade>
      A Gift Card credit was successfully purchased.
    </b-alert>
  </div>
</div>
