<template>
  <div>
    <div v-if="loading" class="text-center">
      <b-spinner label="loading" />
    </div>
    <div v-else>
      <b-tabs v-model="tabs" align="center" justified>
        <b-tab title="Add a Credit Card">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form
              class="credit-card-form"
              @submit.stop.prevent="handleSubmit(createPaymentMethod)"
            >
              <b-row class="mt-4">
                <b-col md="3">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="{ required: true }"
                    name="Credit Card Number"
                  >
                    <b-form-group label="Credit Card Number">
                      <b-form-input
                        v-model.trim="paymentMethod.ccnumber"
                        :state="validateState(validationContext)"
                        :aria-describedby="`cardNumber-feedback-${_uid}`"
                        name="cardNumber"
                        placeholder="4111 1111 1111 1111"
                        required
                        type="text"
                        maxlength=18
                      />

                      <b-form-invalid-feedback
                        :id="`cardNumber-feedback-${_uid}`"
                        >Please enter a valid credit card
                        number.</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="{ required: true, min: 3, max: 64 }"
                    name="Cardholder Name"
                    :custom-messages="{
                       required: 'Please enter the cardholder\'s name.',
                        min: 'Cardholder name must be at least 3 characters long.',
                        max: 'Cardholder name cannot exceed 64 characters.'
                    }"
                  >
                    <b-form-group label="Cardholder Name">
                      <b-form-input
                        v-model.trim="paymentMethod.cardholder_name"
                        :state="validateState(validationContext)"
                        :aria-describedby="`cardName-feedback-${_uid}`"
                        name="cardName"
                        type="text"
                        maxlength="64"
                      />

                      <b-form-invalid-feedback :id="`cardName-feedback-${_uid}`">
                        <!-- Please enter the cardholder's name. -->
                        {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback
                      >
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6" md="2" sm="6">
                  <b-form-group label="Exp. Month">
                    <b-form-select
                      v-model="paymentMethod.exp_month"
                      :options="months"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="2" sm="6">
                  <b-form-group label="Exp. Year">
                    <b-form-select
                      v-model="paymentMethod.exp_year"
                      :options="years"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="cvvRules"
                    name="CVV"
                  >
                    <b-form-group label="CVV/CVC">
                      <b-form-input
                        v-model.trim="paymentMethod.cvv"
                        v-mask="cvvMask"
                        :state="validateState(validationContext)"
                        :aria-describedby="`cardCvv-feedback-${_uid}`"
                        name="cardCvv"
                        required
                        type="text"
                        :maxlength="maxCvv"
                      />

                      <b-form-invalid-feedback :id="`cardCvv-feedback-${_uid}`"
                        >Please enter a valid CVV/CVC</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col class="col-sm-12 col-md-8 col-12 billing-address">
                  <div>
                    <Address
                      v-model="selectedAddressAutocomplete"
                      label="Billing address"
                      @clear="clearAddress"
                      required
                    />
                  </div>
                </b-col>

                <b-col class="mt-4 col-12 col-sm-12 col-md-4 billing-unit">
                  <validation-provider
                    v-slot="validationContext"
                    :rules="{ max: 32 }"
                    name="unit"
                  >
                    <b-form-group label="Address unit" label-class="p-0">
                      <b-form-input
                        v-model.trim="unit"
                        :state="validateState(validationContext)"
                        :aria-describedby="`unit-feedback-${_uid}`"
                        name="unit"
                        type="number"
                      />

                      <b-form-invalid-feedback :id="`unit-feedback-${_uid}`">
                        Please enter a valid unit
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col class="text-center" md="12">
                  <b-button type="submit" class="btn card-btn"
                    >Add Card</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-tab>
        <b-tab title="My Cards">
          <div v-if="paymentMethods && paymentMethods.length > 0" class="mt-2">
            <div class="mb-2">Please select a credit card:</div>
            <!-- First Payment Method -->
            <payment-method
              v-if="firstPaymentMethod"
              v-model="firstPaymentMethod"
            >
              <template #delete>
                <b-btn
                  pill
                  size="sm"
                  variant="danger"
                  @click="
                    lastActiveId = firstPaymentMethod.uuid;
                    deleteModal = true;
                  "
                >
                  <b-icon-trash-fill scale="0.8" />
                </b-btn>
              </template>
              <template #action>
                <b-form-radio-group v-model="selectedPaymentMethod">
                  <b-radio :value="firstPaymentMethod.uuid" />
                </b-form-radio-group>
              </template>
            </payment-method>
            <div
              v-if="paymentMethods && morePaymentMethods.length > 0"
              class="py-2"
            >
              <a
                href="#"
                @click.prevent="
                  showMorePaymentMethods = !showMorePaymentMethods
                "
              >
                <span v-if="!showMorePaymentMethods">See all credit cards</span>
                <span v-else>Hide all credit cards</span>
              </a>
            </div>
            <!-- More Payment Methods -->
            <b-collapse v-model="showMorePaymentMethods">
              <div v-for="(p, index) in morePaymentMethods" :key="index">
                <payment-method :value="p">
                  <template #delete>
                    <b-btn
                      pill
                      size="sm"
                      variant="danger"
                      @click="
                        lastActiveId = p.uuid;
                        deleteModal = true;
                      "
                    >
                      <b-icon-trash-fill scale="0.8" />
                    </b-btn>
                  </template>
                  <template #action>
                    <b-form-radio-group v-model="selectedPaymentMethod">
                      <b-radio :value="p.uuid" />
                    </b-form-radio-group>
                  </template>
                </payment-method>
              </div>
            </b-collapse>
          </div>
          <div v-else class="py-5 text-center">
            No credit cards on record. Please add one.
          </div>
        </b-tab>
      </b-tabs>
      <b-alert v-model="showError" class="mt-2" dismissible variant="danger"
        ><span>{{ errorMessage }}</span></b-alert
      >
      <b-alert
        :show="alertCountDown"
        class="mt-2"
        dismissible
        variant="success"
        @dismissed="alertCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <span>{{ feedbackMessage }}</span>
        <br />
        <b-progress
          v-show="false"
          :max="defaultSecs"
          :value="alertCountDown"
          height="4px"
          precision="2"
          variant="success"
        ></b-progress>
      </b-alert>
    </div>
    <b-modal
      v-model="deleteModal"
      button-size="sm"
      ok-title="Delete"
      ok-variant="danger"
      size="md"
      title="Are you sure you want to delete this credit card?"
      @ok="deletePaymentMethod(lastActiveId)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { axios } from '^/axios';
import { configuration } from '~/configuration';
import PaymentMethod from '@/cart/PaymentMethod.vue';

import { auth } from '^/auth';
import Address from '@/auth/Address.vue';

const AMERICAN_EXPRESS_LENGTH = 15;

export default {
  directives: { mask },
  components: { PaymentMethod, Address },
  props: ['value'],
  data() {
    return {
      paymentMethod: {
        ccnumber: String(),
        cvv: String(),
        exp_month: '',
        exp_year: new Date().getFullYear(),
        cardholder_name: String(),
      },
      loading: false,
      lastActiveId: null,
      paymentMethods: [],
      tabs: 0,
      deleteModal: false,
      defaultSecs: 5,
      showError: false,
      errorMessage: String(),
      feedbackMessage: String(),
      alertCountDown: 0,
      selectedPaymentMethod: null,
      showMorePaymentMethods: false,
      configuration,
      
      auth,
      selectedAddressAutocomplete: {},
      unit: null,
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1
    };
  },
  created: async function () {
    if (this.user) {
      this.paymentMethod.cardholder_name = this.user.name;
    }
    this.paymentMethod.exp_month =  this.currentMonth < 10 ? `0${this.currentMonth}` : this.currentMonth;
    await this.getPaymentMethods();
    this.tabs = this.paymentMethods.length > 0 ? 1 : 0;
    if (this.firstPaymentMethod && this.firstPaymentMethod.uuid && !this.value)
      this.selectedPaymentMethod = this.firstPaymentMethod.uuid;
    else if (this.value) this.selectedPaymentMethod = this.value;
  },
  methods: {    
    ...mapActions(['updateQuote']),
    clearAddress() {
      this.$store.commit('setOrderAddress', null);
      this.$store.commit('setShippingRateId', null);
      this.updateQuote();
    },
    async getPlace(selected) {
      try {
        this.loadingAddress = true;
        return await axios.get(`address/place`, {
          params: {
            place_id: selected.place_id,
            sessiontoken: auth.getGoogleAutocompleteToken(),
          },
        });
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.loadingAddress = false;
      }
    },
    async saveAddress() {
      this.loadingAddress = true;

      const place = await this.getPlace(this.selectedAddressAutocomplete);
      const component = (type) =>
        place.result.address_components
          .filter((x) => x.types.includes(type))
          .map((x) => x.short_name)[0];

      let addressPayload = {
        street_number: component('street_number'),
        street_name: component('route'),
        city: component('administrative_area_level_2'),
        province: component('administrative_area_level_1'),
        country: component('country'),
        postal: component('postal_code'),
        latitude: place.result.geometry.location.lat.toFixed(7),
        longitude: place.result.geometry.location.lng.toFixed(7),
        google_place_id: this.selectedAddressAutocomplete.place_id,
        google_autocomplete_json: JSON.stringify(
          this.selectedAddressAutocomplete // TODO: not the single address, the whole list (json) returned by google
        ),
        google_place_json: JSON.stringify(place),
        unit: this.unit || null,
      };

      // TODO: for some reason locally, the call below won't fire for me.
      try {
        const address = await axios[
          this.address && this.address.id ? 'put' : 'post'
        ]('/address', addressPayload);

        this.address = address.id;
        
        return this.address.id;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
        this.errorMessage = e.message;
        return e.message;
      } finally {
        this.tabs = 1;
        this.loadingAddress = false;

      }
    },

    async getUser() {
      try {
        const user = await axios.get('user');

        this.$store.commit('user', user);
        this.$store.dispatch('getGiftCardsInfo');
      } catch {
        if (!this.auth.isAuthenticated()) {
          this.$router.push({ path: 'login' });
        }
      }
    },

    validateState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getPaymentMethods() {
      this.loading = true;
      try {
        let data = await axios.get('payment-methods');
        if (this.value) {
          const matching = data.findIndex((x) => x.uuid === this.value);
          if (matching !== -1) {
            data.unshift(data.splice(matching, 1)[0]);
          }
        }
        this.paymentMethods = data;
      } catch (e) {
        this.showError = true;
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    async createPaymentMethod() {
      
      this.loading = true;

      await this.saveAddress();

      if (typeof this.address !== 'undefined') {
        this.paymentMethod.address_id = this.address; //Connect address with the payment method
        try {
          const response = await axios.post('payment-method', {
            ...this.paymentMethod,
            ccnumber: this.paymentMethod.ccnumber.replace(/ /g, ''),
            exp_month: parseInt(this.paymentMethod.exp_month),
          });
          this.alertCountDown = this.defaultSecs;
          this.feedbackMessage = 'Payment method added successfully.';
          this.paymentMethod = this.getTemplate();
          this.selectedPaymentMethod = response.uuid;
          this.tabs = 1;
          this.scrollToTitle();
          this.selectedAddressAutocomplete = {};
        } catch (e) {
          this.tabs = 0;
          this.showError = true;
          this.errorMessage = e.message;
        } finally {
          this.getPaymentMethods();
          this.loading = false;
        }
      } else {
        this.showError = true;        
        this.loading = false; // Ensure to set loading to false if there's an error
      }
    },
    async deletePaymentMethod(id) {
      this.loading = true;
      try {
        await axios.delete(`payment-method/${id}`);
        this.alertCountDown = this.defaultSecs;
        this.feedbackMessage = 'Payment method successfully removed.';
        this.scrollToTitle();
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);

        if(this.selectedPaymentMethod === id){
          this.selectedPaymentMethod = null;
        }
      } finally {
        this.getPaymentMethods();
      }
    },
    countDownChanged(alertCountDown) {
      this.alertCountDown = alertCountDown;
    },
    getTemplate() {
      return {
        ccnumber: String(),
        cvv: String(),
        exp_month: '01',
        exp_year: new Date().getFullYear(),
        cardholder_name: String(),
      };
    },
    scrollToTitle() {
      let header = document.getElementById('credit-card-anchor');
      if (header)
        header.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
    },
  },
  computed: {
    ...mapGetters(['user']),
    months() {
      let months = [];
      let month = this.paymentMethod.exp_year === this.currentYear ? this.currentMonth : 1;
      for (month; month < 13; month++) {
        months.push(month < 10 ? `0${month}` : month);
      }
      return months;
    },
    years() {
      let currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i < 12; i++) {
        years.push(i + currentYear);
      }
      return years;
    },
    firstPaymentMethod() {
      return this.paymentMethods && this.paymentMethods.length > 0
        ? this.paymentMethods[0]
        : null;
    },
    morePaymentMethods() {
      return this.paymentMethods && this.paymentMethods.length > 1
        ? this.paymentMethods.slice(1, this.paymentMethods.length)
        : [];
    },
    cvvMask() {
      return this.paymentMethod.ccnumber.length === AMERICAN_EXPRESS_LENGTH ? '####' : '###'
    },
    cvvRules() {
      //If american express card - min=4, max=4
      //Else - min:3, max:3
      const cardLength = this.paymentMethod.ccnumber.length;
      const min = (cardLength === AMERICAN_EXPRESS_LENGTH) ? 4 : 3;
      const max = min;
      
      return { required: true, min, max };
    },
    maxCvv() {
      return this.paymentMethod.ccnumber.length === AMERICAN_EXPRESS_LENGTH ? 4 : 3
    }
  },
  watch: {
    selectedPaymentMethod(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/variables.module.scss';

.credit-card-form {
  @include inputs;
  @include buttons;

  .btn {
    margin-top: 0.5em;
    font-size: 120% !important;
  }
}

.credit-card-identifier {
  font-size: 1.5em;
}

@media #{$belowMd} {
  .credit-card-identifier {
    font-size: 1em;
  }
  .cardholder-label {
    font-size: 0.7em;
  }
  .exp-label {
    font-size: 0.7em;
  }
}

.billing-address input{
  border: var(--primary) 1px solid;
  border-radius: 25px !important;
}
.billing-address .multiselect{
  border: none;
}
.multiselect-fake-input{
  border: none !important;
}

.billing-unit {
  font-weight: bold;
  color: #888;
  font-size: 16px;
  font-family: 'Red Hat Text', sans-serif;

  & input {
    height: 34.5px;
  }
}
</style>
