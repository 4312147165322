<template>
  <div>
    <multiselect
      v-model="selectedOption"
      :options="options"
      :multiple="false"
      :close-on-select="true"
      placeholder="Begin typing your address."
      :searchable="true"
      :loading="isLoading"
      :filter-results="false"
      v-bind="$attrs"
      @search-change="handleSearch"
      v-on="$listeners"
    >
      <div class="address-no-options">
        <span class="error-message-custom" v-if="errorMessage">{{ errorMessage }}</span>
        <span v-else>The list is empty</span>
      </div>
    </multiselect>
  </div>
</template>

<script>
import Select from '@/common/Select/Select.vue';

import { axios } from '^/axios';
import { debounce } from 'lodash';
import { auth } from '^/auth';

export default {
  components: {
    Multiselect: Select,
  },
  props: {
    value: {
      type: Object, // Update the prop type to Object
      required: true,
    },
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
      errorMessage: '',
      searchText: '',
      session: this.$uuid.v4(),
      isLoading: false,
      selectedOption: null,
    };
  },
  computed: {
    autocompleteOptions() {
      return this.options;
    },
  },
  created() {
    if (this.value.description) {
      this.selectedOption = this.value;
      this.options[0] = {
        label: this.selectedOption.description,
        value: this.selectedOption,
        valueProp: this.selectedOption.place_id       
      } 
      this.searchText = this.value.description;
    }
  },
  watch: {
    value(newVal) {
      this.selectedOption = newVal;
      this.$emit('input', newVal);
    },
  },
  methods: {
    handleSearch: debounce(
      async function (searchText) {
        if (searchText.length < 2) return
        
        if (!/^\d+/.test(searchText)) {
          this.options = [];
          this.errorMessage = "Enter a valid address, Example: 'Street Number, Street Name, City, State, Zip'";
          return;
        }
        this.errorMessage = '';
        this.isLoading = true;
        if (!searchText) {
          this.isLoading = false;
          return;
        }
        const resp = await axios.get('address/autocomplete', {
          params: {
            input: searchText,
            sessiontoken: auth.getGoogleAutocompleteToken(),
          },
        });

        let items = [];
        if (resp?.predictions) {
          items = items.concat(resp.predictions);
        } else {
          items = items.concat(resp);
        }

        if (items){
          const response = items.map((prediction) => ({
            value: prediction,
            valueProp: prediction.place_id,
            label: prediction.description,
          }));

          this.options = response;
          this.isLoading = false;
          return response;
        }

        this.isLoading = false;
      },
      550,
      { leading: false, trailing: true }
    ),

  },
};
</script>

<style scoped>
.address-no-options {
  height: 1.5rem;
  padding: 8px;
  display: flex;
  align-items: center;
}

.error-message-custom {
  color: red;
  display: inline-block;
  margin-top: 4px;
  font-style: bold;
}
</style>