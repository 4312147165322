
<b-row align-h="start" class="pt-2 pb-1 flex-grow-1">
  <b-col :class="removable ? 'pr-0' : ''" :cols="cols">
    <v-select
      v-model="selectedFixedOption"
      :append-to-body="true"
      :calculate-position="withPopper"
      :clearable="false"
      :options="completeFixedWeightOptions"
      :searchable="false"
      class="w-100"
      transition=""
    >
      <template slot="selected-option" slot-scope="option">
        <div class="d-flex justify-content-between">
          <span
            >{{ option.amount
            }}<span v-if="value.smart_weight_enabled">{{
              getAbrWeightLabel(option.weight)
            }}</span>
            for ${{ option.price }}</span
          >
        </div>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-flex justify-content-between position-relative">
          <span
            >{{ option.amount
            }}<span v-if="value.smart_weight_enabled">{{
              getAbrWeightLabel(option.weight)
            }}</span>
            for ${{ option.price }}</span
          >
          <span v-if="option.savings && option.savings > 0"
            ><b-badge variant="success"
              >-{{ option.savingsPercent }}%</b-badge
            ></span
          >
        </div>
      </template>
    </v-select>
  </b-col>
  <b-col v-if="removable" class="pl-0" cols="2">
    <b-button class="ml-1" variant="info" @click="removeFromCart(value)"
      >Remove</b-button
    >
  </b-col>
  <b-form-valid-feedback
    v-if="
      selectedFixedOption &&
      selectedFixedOption.savings &&
      selectedFixedOption.savings > 0
    "
    class="mx-3"
    force-show
  >
    You save ${{ bankersRound(selectedFixedOption.savings, 2) }}!
  </b-form-valid-feedback>
</b-row>
