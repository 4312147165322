export const ActivityLevel = {
  low: 'Light Activity',
  medium: 'Moderate Activity',
  hight: 'Very Active',
  sedentary: 'Sedentary',
  athlete: 'Athlete',
  'Light Activity': 'low',
  'Moderate Activity': 'medium',
  'Very Active': 'hight',
  Sedentary: 'sedentary',
  Athlete: 'athlete',
};

export const WeightGoal = {
  lose: 'Loose Weight',
  maintain: 'Maintain Weight',
  gain: 'Gain Weight',
  'Loose Weight': 'lose',
  'Maintain Weight': 'maintain',
  'Gain Weight': 'gain',
};

export const FormulaEnum = {
  Cunningham: 'Cunningham',
  HarrisBenedict: 'Harris-Benedict',
  MifflinStJeor: 'Mifflin-St. Jeor',
  KatchMcArdle: 'Katch-McArdle',
  'Harris-Benedict': 'HarrisBenedict',
  'Mifflin-St. Jeor': 'MifflinStJeor',
  'Katch-McArdle': 'KatchMcArdle',
};
