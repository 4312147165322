<template>
  <b-modal
    v-model="active"
    :title="value.title + ' Reviews'"
    hide-footer
    size="lg"
    @close="$emit('input', null)"
  >
    <b-row v-if="loading" class="justify-content-center">
      <b-spinner variant="dark" />
    </b-row>
    <div v-else>
      <b-row v-if="reviewable" class="mb-4">
        <b-col cols="12">
          <h4>Your Review:</h4>
        </b-col>
        <b-col
          v-if="review.id === null || editingReview"
          class="px-0 d-flex flex-wrap"
          cols="12"
        >
          <b-col cols="12" sm="9">
            <b-textarea
              v-model="review.comment"
              placeholder="Begin your review..."
              rows="5"
            />
            <b-form-rating
              v-model.number="review.rating"
              color="gold"
              no-border
            />
          </b-col>
          <b-col cols="12" sm="3">
            <b-button
              block
              class="mb-2 mt-2 mt-sm-0"
              size="lg"
              variant="info"
              :disabled="!review.comment || review.comment.length === 0"
              @click="submitReview"
            >
              <span v-if="!submitting">Submit</span>
              <b-spinner v-else variant="white" />
            </b-button>
            <b-button
              v-if="review.id"
              block
              size="lg"
              dark
              @click="cancelEditReview"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col class="mt-2" cols="12">
            <span class="font-italic"
              >Notice: Only your review and your rating will become public! Your
              personal information will remain private.</span
            >
          </b-col>
        </b-col>
        <b-col v-else class="px-2 d-flex flex-wrap" cols="12">
          <b-col cols="12">
            <h5 class="pb-2 pt-2">"{{ review.comment }}"</h5>
          </b-col>
          <b-col cols="8">
            <b-form-rating
              :value="review.rating"
              color="gold"
              no-border
              readonly
            />
          </b-col>
          <b-col cols="4">
            <b-button variant="info" @click="editingReview = true">
              <b-icon-pencil scale="0.7" />
              <span class="ml-2">Edit</span>
            </b-button>
          </b-col>
        </b-col>
        <b-col class="mt-2" cols="12">
          <b-alert :show="errorMessage !== String()" variant="danger">
            {{ errorMessage }}
          </b-alert>
        </b-col>
      </b-row>
      <div v-if="reviews && reviews.length > 0">
        <b-list-group>
          <b-list-group-item
            v-for="(review, index) in reviews"
            :key="index"
            class="pt-0"
          >
            <b-row align-v="center" class="pr-2" cols="2">
              <b-form-rating
                :value="review.rating"
                color="gold"
                no-border
                readonly
              />
              <h5 class="text-right mb-0">{{ review.rating }}/5</h5>
            </b-row>
            <p class="my-2">{{ review.comment }}</p>
            <span class="font-italic smaller-text">{{
              getFormattedDate(review.created_at)
            }}</span>
          </b-list-group-item>
        </b-list-group>
        <b-row class="justify-content-center mt-4">
          <b-pagination
            v-model="currentPage"
            :per-page="5"
            :total-rows="totalOrders"
            pills
          />
        </b-row>
      </div>
      <h5 v-else class="font-italic text-center text-muted">
        There are no reviews for this product
      </h5>
    </div>
  </b-modal>
</template>

<script>
import { axios } from '^/axios';
import { debounce } from 'lodash';
import { configuration } from '~/configuration';
import moment from 'moment';
import { auth } from '^/auth';
import loadingGif from '~/../assets/loading-small.gif';

export default {
  name: 'ProductReviews',
  props: ['value'],
  data() {
    return {
      loading: false,
      active: false,
      reviews: [],
      currentPage: 1,
      totalOrders: 0,
      review: {
        id: null,
        comment: '',
        rating: null,
      },
      editingReview: false,
      reviewable: false,
      errorMessage: String(),
      submitting: false,
      configuration,
      auth,
      loadingGif,
    };
  },
  mounted() {
    this.active = this.value != null;
    this.getReviews();
    if (auth.isAuthenticated()) this.getPersonalReview();
  },
  methods: {
    getFormattedDate: function (date) {
      return moment(date).format('dddd, MMMM Do YYYY');
    },
    getReviews: debounce(async function () {
      this.loading = true;
      let url = `product/${this.value.uuid}/reviews`;
      try {
        let response = await axios.get(url, {
          params: { page: this.currentPage },
        });
        this.reviews = response.data;
        this.totalOrders = response.total;
      } catch (error) {
        this.errorMessage = error.message;
      }
      this.loading = false;
    }),
    getPersonalReview: async function () {
      let url = `product/${this.value.uuid}/review`;
      try {
        let response = await axios.get(url);
        this.reviewable = response.reviewable;
        if (response.review) this.review = response.review;
        this.cachedReview = Object.assign({}, this.review);
      } catch (error) {
        //this.errorMessage = error.message;
      }
    },
    submitReview: async function () {
      let url = `product/${this.value.uuid}/review`;
      this.errorMessage = String(); // Reset local error message
      this.submitting = true;
      if (
        this.review.rating &&
        this.review.rating > 0 &&
        this.review.rating < 6
      ) {
        try {
          await axios[this.review.id ? 'put' : 'post'](url, this.review);
        } catch (error) {
          this.errorMessage = error.message;
        }
      } else {
        this.errorMessage = 'Please rate this product 1-5 stars.';
      }
      this.editingReview = false;
      await this.getPersonalReview();
      this.submitting = false;
    },
    cancelEditReview() {
      this.review = Object.assign({}, this.cachedReview);
      this.editingReview = false;
    },
  },
  watch: {
    currentPage() {
      this.getReviews();
    },
    value(val) {
      this.active = val != null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';
@include buttons;

>>> .page-item.active .page-link {
  background-color: #3e3e3e !important;
}

>>> .page-link {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.smaller-text {
  font-size: 80%;
}

p {
  font-size: 100%;
}
</style>
