
<b-row>
  <b-col cols="5">
    <b-form-group :label="weeklyFrequency ? `Select ${weeklyFrequency} day(s)` : 'Select at least one day.'">
      <template v-for="weekday in weekdays">
        <b-form-checkbox
          v-if="canSubOnDay(weekday)"
          :key="weekday"
          v-model="selectedDaysOfWeek[weekday.toLowerCase()]"
          switch
          size="lg"
          :disabled="weeklyFrequency ? (selectedDaysOfWeek[weekday.toLowerCase()] ? false : getTotalSelectedDaysQuantity() >= weeklyFrequency) : false"
          @input="syncDisabled(weekday)">
          {{ weekday }}
        </b-form-checkbox>
      </template>
    </b-form-group>
  </b-col>
  <b-col v-if="orderType !== order_types.shipping" cols="7">
    <b-row>
      <template v-for="(weekday, i) in weekdays">
        <b-col v-if="selectedDaysOfWeek[weekday.toLowerCase()]" :key="i" cols="12">
          <b-form-group :label="weekday">
            <b-input-group>
              <b-form-select v-model="daysOfWeekTimes[weekday.toLowerCase()]" :options="options[weekday.toLowerCase()]" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </template>
    </b-row>
  </b-col>
</b-row>
