
<div
  class="tag"
  :class="{ 'tag-selected': selected, [`tag-${size}`]: true }"
  :style="{
    backgroundColor: selected ? `color-mix(in srgb, ${value.color} 90%, white)` : `color-mix(in srgb, ${value.color} 50%, #fff)`,
    color: selected ? `color-mix(in srgb, ${value.color} 10%, black)` : `color-mix(in srgb, ${value.color} 50%, black)`,
    borderColor: selected ? `color-mix(in srgb, ${value.color} 10%, black)` : `color-mix(in srgb, ${value.color} 75%, black)`
  }">
  <span v-if="selected" class="selected"></span>
  <span v-else>{{ value.symbol }}</span>
</div>
