
<div class="block">
  <Label>
    <template #title>
      <span>
        <span :class="{ animation: true, disabled: !!disabled }">{{
          label
        }}</span>
      </span>
    </template>
  </Label>
  <div class="input-block" :class="{ animation: true, disabled: !!disabled }">
    <div class="icon">
      <icon-base
        size="16"
        :icon-color="active ? '#22a9ff' : 'black'"
        :icon-name="label"
        :error="!disabled && error"
      >
        <component :is="icon" />
      </icon-base>
    </div>
    <input
      class="input"
      v-bind="$attrs"
      :value="value"
      :class="{
        input_withIcon: !!icon,
        error_input: !disabled && error,
      }"
      :placeholder="placeholder"
      :type="type"
      :readonly="readonly"
      :disabled="disabled"
      :min="min"
      :max="max"
      @blur="active = false"
      @focus="active = true"
      @input="$emit('input', $event.target.value)"
    />
    <span v-if="!disabled && error" class="error">{{ error }}</span>
  </div>
</div>
