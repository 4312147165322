<template>
  <div class="wrapper">
    <div class="header-container">
      <h3 class="header">Tell Us About You.</h3>
      <!-- //TODO Move switcher to separate component -->
    </div>
    <p class="description">
      We need this information to calculate your macronutrient intake
      recommendation!
    </p>
    <div class="form">
      <DatePicker v-model="birthday" :inputLabel="'Birthday:'" />

      <div class="height-block">
        <div class="metric-switch">
          <RadioSelect
            v-model="lengthMeasurement"
            :active-button="lengthMeasurement"
            :small="true"
            :options="[
              { value: 'cm', label: 'cm' },
              { value: 'inch', label: 'inch' },
            ]"
          />
        </div>
        <Slider v-model="height" :value="height" :min="70" :max="250">
          <template slot="label">
            <Label>
              <template #title>
                <span>
                  <span>Height: </span>
                  <span v-if="lengthMeasurement === 'cm'" class="value"
                    >{{ height }} cm</span
                  >
                  <span v-else class="value" e>{{
                    getHeightInInches(height)
                  }}</span>
                </span>
              </template>
            </Label>
          </template>
        </Slider>
      </div>

      <RadioSelect
        v-model="gender"
        :options="[
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
        ]"
        :active-button="gender"
        :label="'Gender'"
      />

      <my-select
        v-model="goal"
        :label="'Weight Goal'"
        :options="[
          { value: 'lose', label: 'Cut Fat' },
          { value: 'gain', label: 'Gain Muscle' },
          { value: 'maintain', label: 'Maintain' },
        ]"
        :can-clear="false"
        :can-deselect="false"
      />

      <my-select
        v-model="activityLevel"
        :label="'Activity Level'"
        :options="[
          { value: 'sedentary', label: 'Sedentary' },
          { value: 'low', label: 'Low' },
          { value: 'medium', label: 'Moderate' },
          { value: 'hight', label: 'Very Active' },
          { value: 'athlete', label: 'Athlete' },
        ]"
        :can-clear="false"
        :can-deselect="false"
      />
      <div
        class="advanced-settings-toggle"
        @click="showAdvanced = !showAdvanced"
      >
        <span>
          {{
            showAdvanced ? 'Hide Advanced Settings ' : 'Show Advanced Settings '
          }}
        </span>
        <div :class="{ icon: true, iconOpen: showAdvanced }">
          <icon-base size="12" :icon-color="personalization.theme_color_1">
            <arrow-icon></arrow-icon>
          </icon-base>
        </div>
      </div>

      <div :class="{ advancedSettings: true, hidden: !showAdvanced }">
        <my-select
          v-model="formula"
          :can-clear="false"
          :can-deselect="false"
          :options="[
            {
              value: FormulaEnum.MifflinStJeor,
              label: FormulaEnum.MifflinStJeor,
            },
            {
              value: FormulaEnum.Cunningham,
              label: FormulaEnum.Cunningham,
            },
            {
              value: FormulaEnum.HarrisBenedict,
              label: FormulaEnum.HarrisBenedict,
            },
            {
              value: FormulaEnum.KatchMcArdle,
              label: FormulaEnum.KatchMcArdle,
            },
          ]"
          :label="'Formula'"
        />

        <my-input
          v-model="bodyFatPercentage"
          :disabled="formula !== FormulaEnum.KatchMcArdle"
          placeholder="Put your body fat percentage"
          :icon="FatIcon"
          type="number"
          :min="1"
          :max="80"
          :label="'Body Fat percentage'"
          :error="validateFatPercentage"
        >
        </my-input>
      </div>

      <my-button
        :on-click="postPhysicality"
        center
        :disabled="
          !(gender && goal && activityLevel && height && birthday) ||
          (formula === FormulaEnum.KatchMcArdle &&
            bodyFatPercentage === null) ||
          validateFatPercentage
        "
        >Save</my-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeightIcon from '@/common/Icons/HeightIcon.vue';
import AgeIcon from '@/common/Icons/AgeIcon.vue';
import GenderIcon from '@/common/Icons/GenderIcon.vue';
import FatIcon from '@/common/Icons/FatIcon.vue';
import RadioSelect from '@/common/RadioSelect/RadioSelect.vue';
import Select from '@/common/Select/Select.vue';
import Slider from '@/common/Slider/Slider.vue';
import Label from '@/common/Label/Label.vue';
import DatePicker from '@/common/DatePicker/DatePicker.vue';
import Button from '@/common/Button/Button.vue';
import MyInput from '@/common/Input/Input.vue';
import ArrowIcon from '@/common/Icons/ArrowIcon.vue';
import IconBase from '@/common/Icons/IconBase.vue';
import { axios } from '^/axios';
import { ActivityLevel, WeightGoal, FormulaEnum } from '~/enums/basic';
import { convertHeightToInch } from '%/convertMeasurement';

export default {
  components: {
    RadioSelect,
    Slider,
    DatePicker,
    MyButton: Button,
    Label,
    MyInput,
    ArrowIcon,
    IconBase,
    MySelect: Select,
  },
  computed: {
    ...mapGetters(['user', 'lengthMeasurement']),
    lengthMeasurement: {
      get() {
        return this.$store.getters.lengthMeasurement;
      },
      set(value) {
        if (value === this.lengthMeasurement) return;
        this.$store.dispatch('setLengthMeasurement', value);
      },
    },
    personalization() {
      return JSON.parse(localStorage.getItem('personalization'));
    },

    validateFatPercentage() {
      if (
        this.bodyFatPercentage === null ||
        this.formula !== FormulaEnum.KatchMcArdle
      ) {
        return undefined;
      } else if (isNaN(Number(this.bodyFatPercentage))) {
        return 'Body fat percentage should be number';
      } else if (this.bodyFatPercentage > 80 || this.bodyFatPercentage < 1) {
        return 'Should be between 1 and 80 percent';
      }
      return undefined;
    },
  },

  props: {
    onSaveFinished: { type: Function, default: () => {} },
  },

  data() {
    return {
      gender: '',
      goal: '',
      activityLevel: '',
      formula: '',
      height: 178,
      bodyFatPercentage: null,
      showAdvanced: false,
      weight: undefined,
      birthday: null,
      AgeIcon,
      GenderIcon,
      HeightIcon,
      FormulaEnum,
      FatIcon,
      loading: false,
    };
  },
  methods: {
    getHeightInInches() {
      return convertHeightToInch(this.height || 0);
    },
    //TODO Move to separate folder
    async postPhysicality() {
      try {
        const response = await axios.post(
          `/user/${this.user.uuid}/physicality`,
          {
            birth_date: this.birthday,
            gender: this.gender,
            goal: WeightGoal[this.goal],
            activity_factor: ActivityLevel[this.activityLevel],
            height_in_cm: this.height,
            formula: this.formula,
            ...(this.formula === FormulaEnum.KatchMcArdle
              ? { body_fat_percentage: Number(this.bodyFatPercentage) }
              : {}),
          }
        );
        this.onSaveFinished();

        return response;
      } catch (e) {
        console.log('🚀 ~ fileError', e);
      }
    },

    async getPhysicality() {
      try {
        const response = await axios.get(`/user/${this.user.uuid}/physicality`);
        if (!response) {
          return {
            goal: WeightGoal.maintain,
            activity_factor: ActivityLevel.low,
            gender: 'Male',
            height_in_cm: 178,
            formula: FormulaEnum.MifflinStJeor,
          };
        }
        return response;
      } catch (e) {
        console.log('🚀 ~ fileError', e);
        return {
          goal: WeightGoal.maintain,
          activity_factor: ActivityLevel.low,
          gender: 'Male',
          height_in_cm: 178,
          formula: FormulaEnum.MifflinStJeor,
        };
      }
    },

    async fetchInfo() {
      this.loading = true;
      const response = await this.getPhysicality();
      this.goal = WeightGoal[response.goal];
      this.activityLevel = ActivityLevel[response.activity_factor];
      this.birthday = response.birth_date;
      this.gender = response.gender;
      this.height = Math.floor(Number(response.height_in_cm));
      this.loading = false;
      this.formula = response.formula;
      this.body_fat_percentage = response.bodyFatPercentage;
    },
  },
  created: async function () {
    await this.fetchInfo();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.metric-switch {
  width: 100px;
  position: absolute;
  right: 0;
  top: -12px;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.header {
  font-size: 20px;
  font-weight: bold;
  font-family: $main-font;
}

.description {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
}

.cards {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}

.advancedSettings {
  transition: all 0.4s ease;

  max-height: 270px;
}

.hidden {
  max-height: 0;
  overflow: hidden;
}

.advanced-settings-toggle {
  display: flex;
  align-items: center;
  color: var(--primary);
  font-size: 14px;
  font-family: $main-font;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: -4px;
}
.icon {
  margin-left: 4px;
  display: flex;
  align-items: center;
  transition: max-height 0.3s ease;
}

.iconOpen {
  transform: rotate(90deg);
}

.form {
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;

  animation-duration: 0.4s;
  animation-name: animate-glow;
  animation-timing-function: ease;

  @keyframes animate-glow {
    0% {
      opacity: 0;
      transform: scale(0.8, 0.8);
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  .height-block {
    position: relative;
  }
}
</style>
