
<div class="card">
  <div class="icon">
    <icon-base size="36" :icon-name="title">
      <component :is="icon" />
    </icon-base>
  </div>
  <div class="title">{{ title }}</div>
  <p v-if="!loading" class="basicInfo">{{ basicInfo }}</p>
  <div v-else class="skeleton">
    <skeleton />
  </div>
</div>
