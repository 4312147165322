
  <g>
    <path
      d="M16.57,100.19c-0.22-0.11-0.44-0.24-0.66-0.33c-1.84-0.8-2.81-2.22-3.01-4.19c-0.02-0.19-0.01-0.39-0.01-0.58
			c0-29.85,0-59.7,0-89.54c0-2.6,1.39-4.47,3.73-5.03c0.44-0.1,0.9-0.11,1.35-0.11c5.33-0.01,10.65-0.01,15.98,0
			c3.06,0,5.03,1.99,5.03,5.07c0,29.88,0,59.76,0.01,89.64c0,2.65-1.4,4.17-3.71,5.08C29.04,100.19,22.81,100.19,16.57,100.19z
			M34.25,23.38c-0.42,0-0.77,0-1.12,0c-1.2,0-2.41,0.04-3.61-0.02c-1.38-0.07-2.37-1.12-2.34-2.38c0.03-1.26,0.96-2.19,2.32-2.29
			c0.58-0.04,1.17-0.02,1.75-0.03c0.99,0,1.99,0,2.95,0c0-1.38,0-2.6,0-3.86c-2.06,0-4.04,0.02-6.02-0.01
			c-1.45-0.02-2.46-1.04-2.44-2.36c0.02-1.29,1-2.26,2.4-2.31c1.1-0.04,2.21-0.01,3.31-0.01c0.93,0,1.85,0,2.75,0c0-1.76,0-3.37,0-5
			c-5.53,0-10.98,0-16.5,0c0,30.13,0,60.23,0,90.34c5.53,0,11.01,0,16.52,0c0-1.27,0-2.47,0-3.79c-0.45,0-0.83,0-1.22,0
			c-1.23-0.02-2.47,0.02-3.7-0.07c-1.22-0.09-2.02-0.98-2.1-2.17c-0.08-1.13,0.66-2.19,1.85-2.37c1.08-0.16,2.2-0.1,3.3-0.12
			c0.63-0.01,1.26,0,1.86,0c0-1.36,0-2.54,0-3.8c-0.4,0-0.72,0-1.04,0c-1.82-0.03-3.64,0-5.45-0.13c-1.16-0.08-1.93-1.05-1.98-2.17
			c-0.05-1.1,0.67-2.06,1.81-2.33c0.47-0.11,0.96-0.14,1.44-0.14c1.74-0.02,3.48-0.01,5.23-0.01c0-1.32,0-2.51,0-3.77
			c-0.68,0-1.29,0.01-1.9,0c-0.97-0.01-1.96,0.04-2.92-0.07c-1.37-0.16-2.19-1.08-2.22-2.28c-0.03-1.2,0.85-2.22,2.24-2.37
			c1.03-0.12,2.07-0.05,3.11-0.06c0.57-0.01,1.13,0,1.71,0c0-1.33,0-2.48,0-3.7c-0.36-0.02-0.68-0.04-0.99-0.04
			c-1.69,0-3.38,0.02-5.07-0.01c-1.41-0.03-2.4-1-2.43-2.28c-0.04-1.33,0.94-2.35,2.4-2.4c1.62-0.05,3.25-0.02,4.87-0.02
			c0.41,0,0.81,0,1.22,0c0-1.36,0-2.55,0-3.8c-0.37-0.02-0.68-0.05-1-0.05c-1.23-0.01-2.47,0.02-3.7-0.02
			c-1.14-0.03-1.99-0.71-2.23-1.69c-0.4-1.59,0.6-2.93,2.29-2.98c1.54-0.04,3.09-0.01,4.62-0.01c0-1.36,0-2.57,0-3.86
			c-0.46,0-0.85,0-1.24,0C31.32,48.96,29.66,49,28,48.93c-1.3-0.05-2.21-1.01-2.26-2.23c-0.05-1.23,0.87-2.3,2.19-2.42
			c0.77-0.07,1.56-0.03,2.34-0.03c1.32,0,2.64,0,3.97,0c0-1.33,0-2.51,0-3.82c-0.46,0-0.88,0.01-1.29,0
			c-1.26-0.03-2.54,0.02-3.79-0.11c-1.33-0.13-2.12-1.35-1.88-2.67c0.23-1.26,1.12-1.95,2.6-1.97c1.45-0.02,2.9,0,4.34,0
			c0-1.32,0-2.5,0-3.74c-1.37,0-2.67,0.02-3.97-0.01c-0.9-0.02-1.83,0.01-2.71-0.15c-1.14-0.21-1.85-1.22-1.79-2.33
			c0.05-1.12,0.83-2.04,1.99-2.17c1-0.11,2.01-0.1,3.01-0.11c1.15-0.02,2.31,0,3.5,0C34.25,25.87,34.25,24.71,34.25,23.38z"
    />
    <path
      d="M61.21,100.19c-0.3-0.1-0.59-0.2-0.9-0.29c-3.34-0.93-5.35-3.64-5.35-7.33c-0.01-10.88,0-21.76,0-32.64
			c0-0.39,0-0.77,0-1.25c-0.98,0-1.91,0.09-2.81-0.02c-3.21-0.39-5.52-2.99-5.54-6.25c-0.03-5.94-0.05-11.89,0.01-17.83
			c0.06-6.91,5.7-12.64,12.79-12.62c5.42,0.01,10.85-0.05,16.27,0.08c5.63,0.14,10.9,5.03,11.64,10.62
			c0.12,0.93,0.19,1.87,0.19,2.81c0.02,5.46-0.12,10.91,0.05,16.37c0.13,4.34-3.34,7.45-7.6,6.83c-0.22-0.03-0.44,0-0.78,0
			c0,0.44,0,0.85,0,1.27c0,10.81-0.02,21.63,0.01,32.44c0.01,3.29-1.29,5.7-4.28,7.11c-0.69,0.33-1.47,0.47-2.21,0.7
			c-0.52,0-1.04,0-1.56,0c-1.39-0.55-2.77-1.11-4.07-1.63c-1.27,0.53-2.6,1.08-3.92,1.63C62.51,100.19,61.86,100.19,61.21,100.19z
			M74.46,63.82c0-5.1,0-10.2,0-15.3c0-4.55-0.01-9.1,0.01-13.64c0.01-1.45,1.03-2.49,2.33-2.49c1.31,0,2.3,1.04,2.34,2.48
			c0.02,0.84,0.01,1.69,0.01,2.53c0,4.87,0,9.75,0,14.62c0,1.23,0.59,1.91,1.66,2c1.27,0.11,1.98-0.6,1.98-2.03
			c0.01-5.59,0.01-11.18-0.01-16.76c0-0.77-0.07-1.57-0.26-2.31c-1.03-3.88-4.09-6.2-8.14-6.21c-4.91-0.02-9.81-0.06-14.72,0.03
			c-1.26,0.02-2.62,0.3-3.75,0.84c-3.23,1.55-4.58,4.31-4.58,7.83c0.01,4.35,0,8.71,0,13.06c0,1.27-0.02,2.53,0.01,3.8
			c0.03,1.16,0.73,1.79,1.86,1.77c1.18-0.02,1.76-0.69,1.76-2.08c0.01-5.52,0-11.05,0.01-16.57c0-0.39-0.01-0.79,0.07-1.16
			c0.2-0.99,1.03-1.74,2.01-1.84c1.02-0.11,2.01,0.38,2.36,1.34c0.21,0.56,0.24,1.2,0.24,1.81c0.01,18.81,0.01,37.62,0.01,56.43
			c0,0.48,0.01,0.98,0.1,1.45c0.24,1.25,1.23,2.01,2.51,1.98c1.24-0.03,2.12-0.79,2.32-2.06c0.07-0.41,0.08-0.84,0.08-1.26
			c0-9.65,0-19.3,0.01-28.95c0-0.45,0-0.91,0.06-1.36c0.15-1.07,1.05-1.86,2.13-1.92c1.54-0.08,2.51,0.97,2.51,2.77
			c0,9.84,0,19.69,0.01,29.53c0,0.45,0.02,0.91,0.12,1.35c0.3,1.22,1.32,1.93,2.6,1.87c1.19-0.05,2.05-0.8,2.26-2.02
			c0.07-0.41,0.07-0.84,0.07-1.26C74.46,82.66,74.46,73.24,74.46,63.82z"
    />
    <path
      d="M76.87,10.15c0.04,5.31-4.39,9.83-9.71,9.89c-5.34,0.07-9.86-4.41-9.91-9.79c-0.05-5.29,4.37-9.82,9.62-9.86
			C72.38,0.35,76.83,4.7,76.87,10.15z M67.11,15.25c2.81-0.02,5.05-2.29,5.03-5.1c-0.02-2.77-2.38-5.1-5.12-5.05
			c-2.79,0.05-5.06,2.37-5.04,5.14C62.01,13.04,64.29,15.27,67.11,15.25z"
    />
  </g>
