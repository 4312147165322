
  <g>
    <path
      d="M93.07,44.89c-5.9,5.89-11.81,11.78-17.74,17.69c4.14,5.82,5.6,12.12,4.14,18.98c-1.24,5.82-4.34,10.5-9.17,14
		c-9.5,6.9-22.55,5.64-30.76-2.96c-7.99-8.36-8.46-21.56-1.11-30.66c7.28-9.02,21.14-11.96,32.06-4.15
		c5.92-5.92,11.84-11.83,17.79-17.78c-0.29,0-0.62,0-0.95,0c-2.5,0-5,0.02-7.49-0.01c-1.78-0.03-3.17-1.51-3.17-3.31
		c0-1.8,1.38-3.3,3.17-3.31c5.66-0.03,11.31-0.03,16.97,0c1.75,0.01,3.16,1.44,3.17,3.2c0.02,5.61,0.02,11.21,0,16.82
		c-0.01,1.85-1.5,3.24-3.36,3.23c-1.83-0.01-3.27-1.44-3.28-3.31c-0.02-2.76,0-5.53,0-8.29C93.25,44.99,93.16,44.94,93.07,44.89z
		M73.34,76.63c-0.02-9.14-7.49-16.66-16.56-16.66c-9.25-0.01-16.76,7.47-16.76,16.68c0,9.1,7.59,16.59,16.77,16.56
		C65.83,93.17,73.36,85.63,73.34,76.63z"
    />
    <path
      d="M29.52,42.19c-4.14,4.12-8.22,8.17-12.38,12.31c1.51,1.48,3.06,3,4.61,4.53c0.98,0.97,1.27,2.14,0.86,3.43
		c-0.75,2.39-3.69,3.09-5.54,1.31c-1.57-1.51-3.08-3.09-4.84-4.86c-0.42,0.52-0.75,1-1.15,1.41c-1.75,1.78-3.51,3.54-5.3,5.29
		c-1.4,1.36-3.45,1.38-4.76,0.1c-1.34-1.3-1.33-3.41,0.06-4.82c2.14-2.17,4.31-4.3,6.58-6.57c-1.62-1.59-3.24-3.1-4.77-4.7
		c-1.8-1.88-0.96-4.86,1.52-5.53c1.28-0.35,2.39,0.02,3.32,0.96c1.51,1.51,3.03,3.03,4.49,4.48c4.13-4.13,8.2-8.18,12.34-12.32
		c-4.34-6.22-5.71-13.13-3.5-20.61c1.63-5.53,4.98-9.84,9.83-12.94c9.63-6.15,22.4-4.37,29.99,4.13c7.71,8.63,7.94,21.54,0.59,30.45
		C54.31,46.9,40.57,50.07,29.52,42.19z M26.7,23.32c0,9.18,7.5,16.71,16.63,16.69C52.53,40,60,32.55,60.01,23.37
		c0.01-9.11-7.53-16.59-16.73-16.59C34.24,6.78,26.7,14.31,26.7,23.32z"
    />
  </g>
