
<div>
  <p v-if="label" class="title">{{ label }}</p>
  <Multiselect
    v-bind="$attrs"
    v-model="selectedValue"
    class="multiselect"
    v-on="$listeners"
  >
  <template v-slot:nooptions>
    <slot/>
  </template>
</Multiselect>
</div>
