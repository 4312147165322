<template>
  <b-container>
    <div class="user-settings align-center">
      <h1 class="mt-4 text-center">User Settings</h1>
      <b-row class="justify-content-center">
        <b-col md="6" class="align-center">
          <b-form class="contact-form" @submit.prevent="deleteAccount">
            <b-form-group label="Name">
              <b-form-input
                v-model.trim="userInfo.name"
                disabled
                required
                type="text"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label="Email">
              <b-form-input
                v-model.trim="userInfo.email"
                autocomplete="email"
                autofocus
                required
                type="email"
                disabled
              >
              </b-form-input>
            </b-form-group>
            <b-form-group label="Phone Number">
              <b-form-input
                v-model.trim="userInfo.phone"
                v-mask="'+1(###)###-####'"
                required
                type="text"
                disabled
              >
              </b-form-input>
            </b-form-group>
            <b-row align-h="center" :class="registering ? '' : 'mt-5'">
              <b-button
                class="delete-account-button"
                type="submit"
                :disabled="
                  registering == true &&
                  (!agreeTos ||
                    password.length < 8 ||
                    password != confirmPassword)
                "
                >{{ 'Delete account' }}</b-button
              >
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { auth } from '^/auth';
import { axios } from '^/axios';

export default {
  data() {
    return {
      userInfo: {
        name: '',
        phone: '',
        email: '',
      },
    };
  },

  created() {
    this.userInfo.email = this.user.email;
    this.userInfo.name = this.user.name;
    this.userInfo.phone = this.user.phone;
  },

  methods: {
    deleteAccount() {
      const confirmDelete = confirm(
        'Are you sure you want to delete your account?'
      );

      if (confirmDelete) {
        axios
          .delete(`/user/${this.user.uuid}`)
          .then(() => {
            this.$store.commit('user', null);
            auth.destroyToken();
            this.$router.push('/').catch(() => {});
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  computed: {
    ...mapGetters(['user']),
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';
@include buttons;
@include inputs;

.delete-account-button {
  background-color: white;
  border-color: $danger;
  box-shadow: none;
  color: $danger;
  &:hover {
    background-color: $danger !important;
    border-color: $danger !important;
    color: $white !important;
  }
}
</style>
