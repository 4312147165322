<template>
  <b-row>
    <b-col cols="5">
      <b-form-group :label="weeklyFrequency ? `Select ${weeklyFrequency} day(s)` : 'Select at least one day.'">
        <template v-for="weekday in weekdays">
          <b-form-checkbox
            v-if="canSubOnDay(weekday)"
            :key="weekday"
            v-model="selectedDaysOfWeek[weekday.toLowerCase()]"
            switch
            size="lg"
            :disabled="weeklyFrequency ? (selectedDaysOfWeek[weekday.toLowerCase()] ? false : getTotalSelectedDaysQuantity() >= weeklyFrequency) : false"
            @input="syncDisabled(weekday)">
            {{ weekday }}
          </b-form-checkbox>
        </template>
      </b-form-group>
    </b-col>
    <b-col v-if="orderType !== order_types.shipping" cols="7">
      <b-row>
        <template v-for="(weekday, i) in weekdays">
          <b-col v-if="selectedDaysOfWeek[weekday.toLowerCase()]" :key="i" cols="12">
            <b-form-group :label="weekday">
              <b-input-group>
                <b-form-select v-model="daysOfWeekTimes[weekday.toLowerCase()]" :options="options[weekday.toLowerCase()]" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </template>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

moment.locale('en-CA')

export default {
  name: 'SubscriptionEditor',
  props: {
    subscription: { type: Object, default: null },
    order: { type: Object, required: true },
    pickupScheduleIntervals: { type: Object, required: false },
    deliveryScheduleIntervals: { type: Object, required: false },
    weeklyFrequency: { type: Number, default: null }
  },
  data: () => {
    return {
      message: String(),
      selectedDaysOfWeek: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
      },
      daysOfWeekTimes: {
        sunday: null,
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null
      },
      weekdays: [1, 2, 3, 4, 5, 6, 0].map(day => moment.weekdays(true, day)), // change the order the days show up to sub here!
      order_types: {
        delivery: 'Delivery',
        pickup: 'Pickup',
        shipping: 'Shipping'
      },
      options: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
      }
    }
  },
  computed: {
    orderType() {
      return this.order.order_type ? this.order.order_type : this.order.type
    }
  },
  watch: {
    orderType() {
      this.weekdays.forEach(dayKey => {
        const day = dayKey.toLowerCase()
        this.options[day] = this.getOptions(dayKey)
      })
    },
    daysOfWeekTimes: {
      handler(val) {
        let arr = new Array(7)
        this.weekdays.forEach(day => {
          const isoDay = moment(day, 'dddd').weekday()
          const dayLower = day.toLowerCase()
          arr[isoDay] = val[dayLower]
        })
        this.$emit('input', { days_of_week_times: arr })
      },
      deep: true
    }
  },
  mounted() {
    this.weekdays.forEach(x => {
      const day = x.toLowerCase()
      const canSub = this.canSubOnDay(x)

      if (this.subscription && this.subscription[day] !== null && !canSub) {
        this.$emit('message', `Hey! This isn't available on ${x}s anymore.`)
      }

      if (this.subscription && canSub) {
        this.selectedDaysOfWeek[day] = Boolean(this.subscription[day])

        if (this.subscription[day]) {
          // In some cases the schedule time slots have changed and the saved value no longer exists.
          // In these cases: find the next greater value (if available)
          let schedule = this.getOptions(x)
          if (schedule.length > 0 && !schedule.find(x => x.value === this.subscription[day])) {
            // Sort by closest to value and greater than value
            schedule.sort((a, b) => {
              let subscriptionTime = moment(this.subscription[day], 'hh:mm:ss')
              let aTime = moment(a.value, 'hh:mm:ss')
              let bTime = moment(b.value, 'hh:mm:ss')

              let aPoints = 0
              let bPoints = 0

              if (Math.abs(subscriptionTime.diff(aTime)) < Math.abs(subscriptionTime.diff(bTime))) {
                aPoints += 1
              } else {
                bPoints += 1
              }

              if (aTime.isAfter(subscriptionTime)) aPoints += 2

              if (bTime.isAfter(subscriptionTime)) bPoints += 2

              if (aPoints === bPoints) return 0
              return aPoints > bPoints ? -1 : 1
            })
            this.daysOfWeekTimes[day] = schedule[0].value
          } else {
            this.daysOfWeekTimes[day] = this.subscription[day]
          }
        }
      }
      this.options[day] = this.getOptions(x)
    })
  },
  methods: {
    getTotalSelectedDaysQuantity() {
      return Object.values(this.selectedDaysOfWeek).filter(val => val).length
    },
    getOptions(day) {
      const schedules = this.orderType === this.order_types.delivery ? this.deliveryScheduleIntervals[day] : this.pickupScheduleIntervals[day]

      if (!schedules) return []
      return Object.entries(schedules).map(([text, value]) => ({ text, value }))
    },
    canSubOnDay(day) {
      if (this.getOptions(day).length === 0) return false

      if (this.orderType === this.order_types.delivery) {
        return this.deliveryScheduleIntervals[day] !== undefined
      }

      return this.pickupScheduleIntervals[day] !== undefined
    },
    syncDisabled(weekday) {
      weekday = weekday.toLowerCase()

      if (!this.selectedDaysOfWeek[weekday]) {
        this.daysOfWeekTimes[weekday] = null
      } else if (this.options[weekday].length > 0 && !this.daysOfWeekTimes[weekday]) {
        this.daysOfWeekTimes[weekday] = this.options[weekday][0].value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';
@include inputs;
</style>
