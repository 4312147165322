<template>
  <b-list-group-item class="border-0">
    <hr class="w-75 mt-0" />
    <h2>Summary</h2>
    <p>Order {{ order.code }}</p>
    <b-row>
      <b-col
        v-if="order.type === 'Delivery' || order.type === 'Shipping'"
        class="mb-2 summary-wrapper"
        sm="12"
        md="3"
      >
        <b v-if="order.type === 'Delivery'">Delivery Address</b>
        <b v-else>Shipping Address</b>
        <br />
        <p class="mb-0">{{ order.address }}</p>
      </b-col>
      <b-col class="mb-2 summary-wrapper" sm="12" md="3">
        <b>Order Status</b><br />
        <p class="mb-0">{{ order.status }}</p>
      </b-col>
      <b-col class="mb-2 summary-wrapper" sm="12" md="6">
        <b>Cost Summary</b>
        <CostBreakdown :value="order.breakdown" :gift-card-funds="order.breakdown.gift_card_credit" class="w-100" />
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import CostBreakdown from '@/cart/partials/CostBreakdown.vue';

export default {
  name: 'OrderSummary',
  components: { CostBreakdown },
  props: ['order'],
};
</script>

<style scoped>
.summary-wrapper > p {
  font-size: 0.9rem;
}
.summary-wrapper > div {
  font-size: 0.9rem !important;
}

::v-deep .summary-wrapper > .w-100 > .row {
  margin-top: 0 !important;
}

::v-deep .summary-wrapper > .w-100 > .row > .col {
  padding: 0 !important;
}

::v-deep .grand-total,
::v-deep .currency {
  font-size: 1.2rem !important;
}

::v-deep .currency-symbol {
  font-size: 0.9rem !important;
}
</style>
