<template>
  <b-row>
    <b-col cols="12" class="bordered">
      <b-table striped :items="value" :fields="fields" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'OrderProductModifiers',
  props: ['value'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Add On',
          sortable: false,
        },
        {
          key: 'item_quantity',
          label: 'Quantity',
          class: 'text-center text-sm-left',
          sortable: false,
        },
        {
          key: 'price_adjustment',
          label: 'Price',
          class: 'text-right text-sm-left',
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.bordered > table {
  border: 2px solid #dee2e6;
}
</style>
