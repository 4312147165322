
<div :class="className">
  <p v-if="label" class="title">{{ label }}</p>
  <div class="options">
    <div
      v-for="option in options"
      :key="option.value"
      class="option"
      :class="{
        'option-active': activeButton === option.value,
        'option-small': small,
      }"
      @click="updateActiveButton(option)"
    >
      {{ option.label }}
    </div>
  </div>
</div>
