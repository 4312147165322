export const rules = {
  required: (value) => !!value || "Required.",

  email: (value) => {
    const pattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return pattern.test(value) || "Invalid e-mail.";
  },

  number: (v) => !v || !isNaN(v) || "Must be a number.",

  quantity: (v) => !v || v > 0 || "Must be a greater than zero.",

  money: (v) => !v || v.length === 0 || /^(\d+\.\d{2})|(\d+)$/.test(v) || "Must be currency.",

  words: (v) => /^[\w|\s|\d]{3,50}$/.test(v) || "Must be letters, numbers and/or spaces; and between 3 and 50 characters.",

  distributionCenter: (v) => (v && v.id && !isNaN(v.id)) || "Please select a region.",

  supplier: (v) => (v && v.length > 3) || "Supplier must be at least 3 letters",

  brand: (v) => (v && v.length > 3) || "Brand must be at least 3 letters",

  discountCode: (v) => /^[A-Z0-9-]+$/.test(v) || "Only letters and numbers",

  percentDiscount: (v) => ("" + parseInt(v) === "" + v && v > 0 && v <= 100) || "Enter a valid percentage",

  postalCode: (v) => /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || "Enter a valid postal code",
};
