<template>
  <b-container fluid class="mt-3" align-v="center">
    <b-row align-v="stretch" class="text-center region-selection">
      <b-col>
        <b-jumbotron bg-variant="light">
          <h1 class="display-4">Let's do this.</h1>
          <p>Select a region to get started.</p>
          <div class="selectWrapper mt-4">
            <b-form-select
              :options="regionOptions"
              :value="null"
              class="selectBox"
              @change="changeRegion"
            >
              <b-form-select-option :value="null" disabled
                >Select a region
              </b-form-select-option>
            </b-form-select>
          </div>
        </b-jumbotron>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    regionOptions: { type: Array, required: true },
  },
  methods: {
    changeRegion(id) {
      this.$store.commit('regionId', id);
      this.$router.push('/').catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.selectWrapper {
  border-radius: 36px;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #cccccc;
}

.selectBox {
  padding-left: 18px;
  width: 180px;
  height: 50px;
  border: 0;
  outline: none;
  font-size: 0.85em;

  option {
    text-align: center;
  }
}
</style>
