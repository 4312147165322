<template>
  <svg
    viewBox="0 0 100 100"
    :width="size"
    :height="size"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <g :fill="iconColor" :class="{ error: error }">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    error: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

svg {
  display: inline-block;
  vertical-align: baseline;
}
.error {
  fill: $red;
}
</style>
