<template>
  <div class="w-100">
    <modifier
      v-for="(modifier, i) in value"
      :key="modifier.uuid"
      v-model="value[i]"
      :valid="valid[i]"
      :is-mods-disabled = "isModsDisabled"
      :expanded="expanded"
    />
  </div>
</template>

<script>
// This serves as a wrapping component to handle modifier validation
import Modifier from '@/shop/product-partials/modifiers/Modifier.vue';

export default {
  name: 'ProductModifiers',
  components: { Modifier },
  props: {
    value: Array,
    validated: { Array, required: true },
    expanded: {
      type: Boolean,
      default: false,
    },
    isModsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valid() {
      if (this.value.length < 1) return [];

      // Check to make sure each modifier has it's min items requirement met
      return this.value.map((modifier) => {
        let selected = modifier.items.reduce((allModQuantity, modifierItem) => {
          if (
            modifierItem.item_quantity >= modifierItem.min_quantity &&
            (modifierItem.item_quantity <= modifierItem.max_quantity ||
              modifierItem.max_quantity === null) &&
            modifierItem.item_quantity > 0
          ) {
            return allModQuantity + modifierItem.item_quantity;
          }
          return allModQuantity;
        }, 0);

        return selected >= modifier.min_items;
      });
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        this.$emit('model-changed', newVal);
      },
    },
    valid(val) {
      this.$emit('update:validated', val);
    },
  },
  mounted() {
    this.$emit('update:validated', this.valid);
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}
</style>
