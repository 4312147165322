<template>
  <div class="d-inline">
    <span
      v-if="prepend && currency !== 0"
      class="currency-symbol"
      :style="`font-size:${secondaryFontSize}`"
      >{{ prepend }}</span
    >
    <span
      v-if="currency === 0"
      class="currency ma-0 pa-0"
      :style="`font-size:${primaryFontSize}`"
      >FREE</span
    >
    <span v-else>
      {{ currency }}
    </span>
    <span
      v-if="append && currency !== 0"
      class="currency-symbol"
      :style="`font-size:${secondaryFontSize}`"
      >{{ append }}</span
    >
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue';

export default {
  name: 'Currency',
  components: {
    AnimatedNumber,
  },
  props: {
    currency: String,
    prepend: String,
    append: String,
    primaryFontSize: {
      type: String,
      default: '1rem',
    },
    secondaryFontSize: {
      type: String,
      default: '70%',
    },
  },
  methods: {
    formatToPrice(value) {
      return Math.max(0, value).toFixed(2);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/variables.module.scss';

.currency {
  transition: 0.5s;
}

.currency-symbol {
  color: $secondary-trim;
}
</style>
