<template>
  <b-container class="cart-container">
    <b-row
      v-if="!orderRequirementsMet && cartProducts.length !== 0 && false"
      align-h="center"
      class="mt-4"
    >
      <b-container>
        <b-alert v-if="!fetchingQuote" show variant="warning">
          <order-requirements-notice />
        </b-alert>
      </b-container>
    </b-row>

    <b-alert v-if="!isPending && !meetsMinimumSubtotal && vendor.minimum_order_subtotal"
      class="alert-toast mb-5"
      variant="warning" show >
      You need a subtotal of at least ${{ vendor.minimum_order_subtotal }} to proceed!
    </b-alert>

    <b-alert v-else-if="!isPending && !address && (orderType === order_types.delivery || orderType === order_types.shipping)" 
      class="alert-toast mb-5"
      variant="warning" show >
      You need to choose address for delivery or shipping before proceeding.
    </b-alert>

    <div class="row justify-center pt-3 pb-0">
      <div class="col-12">
        <h1 class="display-4 align-center text-center">Your Cart</h1>

        <h4 v-if="region" class="text-center px-1">
          You are ordering in
          <span class="mdi mdi-map-marker-radius-outline"></span>
          {{ region.name }}
        </h4>
      </div>
    </div>

    <b-container v-if="cartProducts.length === 0">
      <b-row align-h="center" class="empty-message text-center pt-3">
        <p>Your cart is empty!</p>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-button class="btn-cta" to="/">Shop</b-button>
      </b-row>
    </b-container>
    <template v-else>
      <div v-for="(_, index) in cartProducts" :key="index">
        <CartProduct v-model="cartProducts[index]" />
      </div>

      <ProductMacrosTable :macros="totalMacros" :isGoalCenter="vendor.settings.enable_goal_center" />

      <h1 class="mt-5 mb-4 text-center">Choose Your Style</h1>
      <b-button-group class="w-100 text-center mx-auto order-type-buttons">
        <b-button
          v-if="vendorRegion.delivery_enabled"
          :class="orderType === order_types.delivery ? 'btn' : 'btn-outline'"
          @click="updateOrderType(order_types.delivery)"
          >{{ order_types.delivery }}
        </b-button>
        <b-button
          v-if="vendorRegion.enable_pickup"
          :class="orderType === order_types.pickup ? 'btn' : 'btn-outline'"
          @click="updateOrderType(order_types.pickup)"
          >{{ order_types.pickup }}
        </b-button>
        <b-button
          v-if="vendorRegion.enable_shipping"
          :class="orderType === order_types.shipping ? 'btn' : 'btn-outline'"
          @click="updateOrderType(order_types.shipping)"
          >{{ order_types.shipping }}
        </b-button>
      </b-button-group>

      <b-row
        v-if="
          orderType === order_types.delivery ||
          orderType === order_types.shipping
        "
        class="mt-5"
        align-h="center"
      >
        <b-col cols="12">
          <h1 class="text-center">To Where?</h1>
        </b-col>

        <b-tabs v-model="tabs" class="tabs w-100 mx-3" align="center" justified>
          <b-tab title="Add an Address">
            <template v-if="addressLoading">
              <b-row align-h="center">
                <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
              </b-row>
            </template>
            <div v-else class="py-4">
              <div>
                <Address
                  v-model="selectedAddressAutocomplete"
                  label="Address"
                  @clear="clearAddress"
                />
              </div>
              <div class="mt-2">
                <my-input
                  v-model="selectedAddressAutocomplete.unit"
                  :disabled="false"
                  placeholder="Unit/Suite"
                  :label="'Unit/Suite'"
                >
                </my-input>
              </div>
              <b-row align-h="center" justify="center">
                <b-button
                  :disabled="!selectedAddressAutocomplete.description"
                  @click="saveAddress"
                >
                  Save Address
                </b-button>
              </b-row>
            </div>
          </b-tab>

          <b-tab title="My Addresses">
            <template v-if="addressLoading">
              <b-row align-h="center">
                <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
              </b-row>
            </template>

            <div v-else-if="addresses.length" class="py-4 text-center">
              <b-form-radio-group v-model="address" @input="selectAddress">
                <div v-for="addr in getAddressesToDisplay()" :key="addr.id">
                  <AddressComponent :address="addr">
                    <template #select>
                      <b-radio :value="addr.id" />
                    </template>

                    <template #delete>
                      <b-icon icon="trash" class="text-danger address-action" @click="onShowDeleteAddressModal(addr)"/>
                    </template>
                  </AddressComponent>
                </div>

                <b-modal :visible="showDeleteAddressModal" hide-header-close no-close-on-esc no-close-on-backdrop cancel-disabled hideFooter centered :title="`Are you sure you want to remove ${removedAddress && removedAddress.autocomplete} from your addresses?`">
                  <div class="delete-address-modal-footer">
                    <b-button variant="primary" :disabled="isAddressRemoving" @click="onCloseDeleteAddressModal">Cancel</b-button>
                    <b-button class="delete-address-modal-footer__delete-button" :disabled="isAddressRemoving" @click="handleDeleteAddress()">Remove</b-button>
                  </div>
                </b-modal>
              </b-form-radio-group>

              <div v-if="addresses && addresses.length > 3" class="py-2">
                <a
                  href="#"
                  @click.prevent="hideMoreAddresses = !hideMoreAddresses"
                >
                  <span v-if="hideMoreAddresses">Show more</span>
                  <span v-else>Show less</span>
                </a>
              </div>
            </div>
            <div v-else class="py-5 text-center">
              No addresses was added. Please add one.
            </div>
          </b-tab>
        </b-tabs>
      </b-row>

      <b-row v-if="orderType === order_types.shipping">
        <b-col cols="12 mt-5 mb-4">
          <h1 class="text-center">Shipping options</h1>
        </b-col>
        <b-col class="text-center">
          <b-dropdown
            id="dropdown-1"
            text="Dropdown Button"
            class="selectBox text-center"
            toggle-class="button"
            menu-class="menu"
            link-class="dropdown-item"
          >
            <template #button-content>
              <span>{{
                vendorRegion.shipping_rates.find(
                  (shipping_rate) => shipping_rate.id === shippingRateId
                ).shipping_label
              }}</span>
            </template>
            <div v-for="product in vendorRegion.shipping_rates" :key="product.id">
              <b-dropdown-item
                class="dropdownItem"
                @click="setShippingRate(product)"
                ><div>{{ product.shipping_label }}</div>
                <div>$ {{ product.shipping_rate }}</div></b-dropdown-item
              >
            </div>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row
        v-show="(vendor.settings.enable_tipping && orderType === order_types.delivery) || (vendor.settings.enable_tipping_pickup && orderType === order_types.pickup)"
        class="mt-4"
        align-h="center"
      >
        <b-col cols="12">
          <h1 class="text-center">Tip?</h1>
        </b-col>

        <Tip :selectedTip="selectedTip" @change="onUpdateTip" :orderType="orderType"/>
      </b-row>
      <div>
        <b-row class="mt-5" align-h="center" justify="center" align-v="center">
          <b-col cols="12">
            <h1 class="text-center">Promo Code</h1>
          </b-col>
          <b-col cols="auto">
            <b-input
              v-model="discountCode"
              class="discount-input text-center p0 m0"
              placeholder="Discount Code?  "
              type="text"
              @keyup="onChangeDiscount"
            />
          </b-col>
        </b-row>

        <b-row  v-if="giftCards.balance && vendor.settings.enable_gift_cards" align-h="center" justify="center" align-v="center">
          <b-col cols="12">
            <h1 class="mt-4 mb-3 text-center">Gift Card</h1>
          </b-col>

          <b-col cols="auto">
            <b-form-group>
              <template #description>
                <div style="text-align: center;">Gift Card Balance: {{ formatDefaultPrice(giftCards.balance) }}</div></template>
              <b-form-input 
                class="discount-input" 
                v-model.trim="gift_card_credit" 
                placeholder="Use funds from your gift card?" 
                :min="0" 
                :max="maxGiftCardSum" 
                type="number"
                @keyup="onChangeGiftCardDiscount" 
                @input="removeLeadingZero"
                step="0.01"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="giftCardFundsError" cols="12" class="mt-4">
            <b-alert variant="warning" show>
              Gift card funds used must be greater than $0 and less than ${{ maxGiftCardSum }}.
            </b-alert>
          </b-col>
        </b-row>

        <template
          v-if="
            (orderType === order_types.delivery && address) ||
            orderType === order_types.shipping ||
            orderType === order_types.pickup
          "
        >
          <b-row class="cart-totals" justify="center">
            <b-container v-if="!addressIsRequiredButEmpty">
              <div class="row justify-center pt-5">
                <div class="col-12">
                  <h1 class="display-4 align-center text-center">
                    Order Summary
                  </h1>
                </div>
              </div>

              <template v-if="fetchingQuote">
                <b-row align-h="center">
                  <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
                </b-row>
              </template>
              <template v-else-if="!quote">
                <p class="align-center text-center">
                  {{ alert.text }}
                </p>
              </template>
              <template v-else>
                <b-container style="max-width: 600px">
                  <CostBreakdown :value="quote" />
                </b-container>
              </template>
            </b-container>
          </b-row>
        </template>
        
        <b-alert v-if="!meetsMinimumSubtotal && vendor.minimum_order_subtotal" 
          class="alert-toast mb-5"
          variant="warning" show >
          You need a subtotal of at least ${{ vendor.minimum_order_subtotal }} to proceed!
        </b-alert>
        
        <b-alert v-if="!vendor.is_active_payment" 
          class="alert-toast mb-5"
          variant="warning" show >
          This website is under construction. Check back soon!
        </b-alert>
                
        <b-row ref="cta" align-h="center" class="my-4">
          <b-button
            v-if="auth.isAuthenticated()"
            class="btn-cta"
            to="/checkout"
            :disabled="disableCheckout"
            >Continue</b-button
          >
          <b-button
            v-else
            class="btn-cta"
            to="/validate?redirect=/checkout"
            :disabled="!cartValid || !meetsMinimumSubtotal"
            >Continue</b-button
          >
        </b-row>
      </div>
    </template>    
  </b-container>
</template>

<script>
import Address from '@/auth/Address.vue';
import CartProduct from '@/cart/partials/CartProduct.vue';
import CostBreakdown from '@/cart/partials/CostBreakdown.vue';
import Tip from '@/cart/partials/Tip.vue';
import { mapActions, mapGetters } from 'vuex';
import { auth } from '^/auth';
import { getProductMacros, handleSmartWeightConversion, minimumSubtotalCheck, bankersRound } from '^/utilities';
import loadingGif from '~/../assets/loading-small.gif';
import { configuration } from '~/configuration';
import { smartWeightOptions } from '~/enums/smartWeightOptions';
import OrderRequirementsNotice from '~/partials/components/OrderRequirements/OrderRequirementsNotice.vue';
import AddressComponent from '@/cart/Address.vue';
import Label from '@/common/Label/Label.vue';

import MyInput from '@/common/Input/Input.vue';

import '@vueform/multiselect/themes/default.css';

import { axios } from '^/axios';
import { formatDefaultPrice } from '%/formatPrice';
import ProductMacrosTable from '@/Macronutrients/Macros/ProductMacrosTable.vue';

export default {
  components: {
    CostBreakdown,
    OrderRequirementsNotice,
    CartProduct,
    Tip,
    Address,
    AddressComponent,
    MyInput,
    Label,
    ProductMacrosTable,
  },
  data: () => ({
    configuration,
    smartWeightOptions,
    handleSmartWeightConversion,
    auth,
    loadingGif,
    selectedAddressAutocomplete: { unit: '' },
    addressLoading: false,
    addresses: [],
    address: null,
    order_types: {
      delivery: 'Delivery',
      pickup: 'Pickup',
      shipping: 'Shipping',
    },
    governance: auth.getExtendedRoleClaims('settings'),
    loadingAddress: false,
    hideMoreAddresses: true,
    tabs: 1,
    selectedTip: undefined,
    gift_card_credit: 0,
    showDeleteAddressModal: false,
    removedAddress: undefined,
    isAddressRemoving: false,
    isPending: true
  }),
  async created() {
    this.isPending = true
    
    const cart = JSON.parse(localStorage.getItem('state.cart'));
    
    if (cart.tip) {
      this.selectedTip = cart.tip
    }

    if (cart.code) {
      this.discountCode = cart.code;
    }

    if (cart.order_type) {
      this.updateOrderType(cart.order_type);
    } else {
      this.$store.commit('setOrderType', this.order_types.delivery);
    }

    await this.getUser();

    await this.getUserAddresses();
    
    if (this.user && this.user.addresses.length > 0) {
      this.addresses = this.user.addresses.reverse();

      if (cart.address) {
        const currentAddress = this.addresses.find((address) => address && address.id === cart.address);
        this.setAddress(currentAddress || this.addresses[0]);
      } else {
        this.setAddress(this.addresses[0])
      }
    }

    if (this.cartProducts.length > 0) this.updateQuote();

    if (this.orderType === this.order_types.shipping && !this.shippingRateId) {
      setShippingRate(this.vendorRegion.shipping_rates[0])
    }

    if (cart.gift_card_credit && this.vendor.settings.enable_gift_cards) {
      this.gift_card_credit = cart.gift_card_credit
      this.updateQuote();
    }
    
    this.updateCartInStorage()
    this.isPending = false
  },

  methods: {
    ...mapActions(['hideAlert', 'updateQuote']),
    formatDefaultPrice,
    getProductMacros,

    logout() {
      this.$store.commit('user', null);
      auth.destroyToken();
      this.$router.push('/');
    },

    setAddress(address) {
      this.address = address ? address?.id : null
      this.$store.commit('setOrderAddress', address);
      this.updateQuote();
    },

    getAddressesToDisplay() {
      if (this.hideMoreAddresses) {
        if (this.addresses.length <= 3) {
          return this.addresses;
        } else {
          return this.addresses.slice(0, 3);
        }
      } else {
        return this.addresses;
      }
    },

    setShippingRate(shippingRate, value) {
      this.$store.commit('setShippingRateId', shippingRate?.id);
      this.updateQuote();
      this.updateCartInStorage()
    },

    updateOrderType(newOrderType) {
      this.$store.commit('setOrderType', newOrderType);

      if (newOrderType === this.order_types.shipping) {
        this.$store.commit(
          'setShippingRateId',
          this.vendorRegion.shipping_rates[0]?.id
        );
      } else {
        this.$store.commit('setShippingRateId', undefined);
      }
      this.updateQuote();
      this.updateCartInStorage()
    },

    clearAddress() {
      this.$store.commit('setOrderAddress', null);
      this.$store.commit('setShippingRateId', null);
      this.updateQuote();
      this.updateCartInStorage()
    },

    async getUser() {
      try {        
        this.addressLoading = true;
        const user = await axios.get('user');

        if (!user || !user.addresses || !user.addresses.length) {
          this.tabs = 0;
        }

        this.$store.commit('user', user);
        this.$store.dispatch('getGiftCardsInfo');
      } catch (error) {
        if (!this.auth.isAuthenticated()) {
          this.$router.push({ path: 'login' });
        }
      } finally {
        this.addressLoading = false;
      }
    },

    async getUserAddresses() {
      
      try {        
        this.addressLoading = true;
        const result = await axios.get(`user/${this.user.uuid}/addresses`, {
          params: {
            is_billing: 0
          },
        });
        this.user.addresses = result.data; 
        
        this.$store.commit('user', this.user);     
      } catch (error) {
        if (!this.auth.isAuthenticated()) {
          this.$router.push({ path: 'login' });
        }
      } finally {
        this.addressLoading = false;
      }

    },

    async getPlace(selected) {
      try {
        this.loadingAddress = true;
        return await axios.get(`address/place`, {
          params: {
            place_id: selected.place_id,
            sessiontoken: auth.getGoogleAutocompleteToken(),
          },
        });
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.loadingAddress = false;
      }
    },

    async saveAddress() {
      this.addressLoading = true;

      const place = await this.getPlace(this.selectedAddressAutocomplete);
      const component = (type) =>
        place.result.address_components
          .filter((x) => x.types.includes(type))
          .map((x) => x.short_name)[0];

      let addressPayload = {
        street_number: component('street_number'),
        street_name: component('route'),
        city: component('administrative_area_level_2'),
        province: component('administrative_area_level_1'),
        country: component('country'),
        postal: component('postal_code'),
        latitude: place.result.geometry.location.lat.toFixed(7),
        longitude: place.result.geometry.location.lng.toFixed(7),
        google_place_id: this.selectedAddressAutocomplete.place_id,
        google_autocomplete_json: JSON.stringify(
          this.selectedAddressAutocomplete // TODO: not the single address, the whole list (json) returned by google
        ),
        google_place_json: JSON.stringify(place),
        unit: this.selectedAddressAutocomplete?.unit || null,
      };

      // TODO: for some reason locally, the call below won't fire for me.
      try {
        const address = await axios[
          this.address && this.address.id ? 'put' : 'post'
        ]('/address', addressPayload);

        this.$emit('update:address', address);
        this.$emit('setAddress', address);
        this.address = address.id;
        this.$store.commit('setOrderAddress', address);
      
        await this.getUser();
        await this.getUserAddresses();

        this.addresses = this.user.addresses.reverse();
        this.updateQuote();
        this.updateCartInStorage(); // When customer adds new address, address_id hasn't been added in state.cart, and "Submit" order button is disabled
        this.selectedAddressAutocomplete.unit = null;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.tabs = 1;
        this.addressLoading = false;
      }

    },

    selectAddress(addressId) {
      let newSelectedAddress = this.addresses.find((x) => x.id === addressId);
      this.address = addressId;

      if (newSelectedAddress) {
        this.$store.commit('setOrderAddress', newSelectedAddress);
        this.updateQuote();
      }

      this.updateCartInStorage()
    },

    onShowDeleteAddressModal(address) {
      this.showDeleteAddressModal = true;
      this.removedAddress = address;
    },

    onCloseDeleteAddressModal() {
      this.showDeleteAddressModal = false;
      this.removedAddress = null;
    },

    async handleDeleteAddress() {
      try {
        this.isAddressRemoving = true

        await axios.delete(`user/address/${this.removedAddress.id}`);

        const newUserInfo = this.user

        const removedAddressIndex = this.addresses.findIndex((address) => address.id === this.removedAddress.id);
        newUserInfo.addresses.splice(removedAddressIndex, 1);

        if (this.address === this.removedAddress.id) {
          this.setAddress(newUserInfo.addresses.length ? newUserInfo.addresses[0] : null);
        }
        this.$store.commit('user', newUserInfo);
        this.onCloseDeleteAddressModal()
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.isAddressRemoving = false;
      }
    },

    updateCartInStorage() {
      const cart = JSON.parse(localStorage.getItem('state.cart'))
      
      if (this.selectedTip) {
        cart.tip = this.selectedTip;
      }

      if (this.address) {
        cart.address = this.address;
      }

      if (this.orderType) {
        cart.order_type = this.orderType;
      }

      if (this.gift_card_credit) {
        cart.gift_card_credit = bankersRound(this.gift_card_credit);
      }

      if (this.shippingRateId) {
        cart.shipping_rate_id = this.shippingRateId;
      } else {
        cart.shipping_rate_id = undefined;
      }

      cart.code = this.discountCode;

      localStorage.setItem('state.cart', JSON.stringify(cart));
    },

    onChangeDiscount() {
      this.updateCartInStorage()
      this.updateQuote()
    },

    onChangeGiftCardDiscount() {
      this.$store.commit('setGiftCardCredit', this.gift_card_credit);
      this.updateCartInStorage()
      this.updateQuote()
    },

    onUpdateTip(tip) {
      this.selectedTip = tip

      this.updateCartInStorage()
    }, 
    removeLeadingZero() {
      if (this.gift_card_credit.length > 1 && !this.isDecimalNumber(this.gift_card_credit)) {
        this.gift_card_credit = this.gift_card_credit.replace(/^0+/, '');
      }
      this.gift_card_credit = bankersRound(this.gift_card_credit);
    },
    isDecimalNumber(value) {
      return /^0[.,]\d+/.test(value);
    },

    
    formatGrams(number) {
      return this.formatToMacro(number) + 'g';
    },

    formatToMacro(value) {
      return Math.max(value).toFixed(0);
    }
  },
  computed: {
    ...mapGetters([
      'vendor',
      'vendorRegion',
      'region',
      'cartProducts',
      'cartValid',
      'user',
      'fetchingQuote',
      'orderRequirementsMet',
      'quote',
      'orderType',
      'shippingRateId',
      'alert',
      'giftCards'
    ]),
    discountCode: {
      get() {
        return this.$store.getters.discountCode;
      },
      set(val) {
        return this.$store.commit('addDiscountCode', val);
      },
    },

    meetsMinimumSubtotal() {
      return minimumSubtotalCheck(this.vendor, this.quote);
    },

    maxGiftCardSum() {
      if (!this.giftCards.balance) return 0
      if (!this.quote || !this.quote.subtotal) return 0
      return bankersRound(Math.min(this.quote.subtotal - this.quote?.discount + this.quote.vendor_tax, this.giftCards.balance))
    },

    giftCardFundsError() {
      return Number(this.gift_card_credit) > this.maxGiftCardSum || Number(this.gift_card_credit) < 0
    },

    addressIsRequiredButEmpty() {
      return (!this.address && (this.orderType === this.order_types.delivery || this.orderType === this.order_types.shipping))
    },

    disableCheckout() {
      return !this.quote || !this.cartValid || !this.orderType || !this.meetsMinimumSubtotal || !this.vendor.is_active_payment || this.giftCardFundsError || (this.orderType === this.order_types.shipping && !this.shippingRateId) || this.addressIsRequiredButEmpty
    },

    totalMacros() {
      const totalMacros = {
        calories: 0,
        proteins: 0,
        fats: 0,
        carbs: 0,
      }

      this.cartProducts.forEach((product) => {
        const { calories, proteins, fats, carbs } = this.getProductMacros(product)

        totalMacros.calories += (calories || 0) * product.quantityOrdered;
        totalMacros.proteins += (proteins || 0) * product.quantityOrdered;
        totalMacros.fats += (fats || 0) * product.quantityOrdered;
        totalMacros.carbs += (carbs || 0) * product.quantityOrdered;
      })
      return totalMacros
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';
@include buttons;

.title {
  font-family: $main-font;
  font-weight: 700;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 0;
  color: $dark-gray;
}

.cart-container {
  max-width: 600px !important;
}

.empty-message {
  font-size: 1.25rem;
}

.discount-input {
  border-radius: 36px;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #cccccc;
  padding-left: 18px;
  width: 80vw;
  max-width: 300px;
  height: 50px;
  outline: none;
  font-size: 0.85em;
  font-weight: 900;
  // color: rgb(34, 34, 34) !important;

  &::placeholder {
    color: lightgray !important;
  }
}

.cart-totals {
  .cart-title-row {
    display: flex;
    align-items: center;
  }

  .cart-title-row * {
    white-space: nowrap;
  }

  .list-group-item {
    border: 0;
  }
}

.selectBox {
  width: 300px;
  height: 50px;
  border: 0;
  outline: none;
  font-size: 0.85em;
  border-radius: 100px;
  option {
    text-align: center;
  }
}
.order-type-buttons {
  height: 60px;
}
</style>

<style lang="scss">
.selectBox {
  .button {
    border-radius: 100px !important;
    background-color: white !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
  }

  .button:hover,
  .button:active {
    background-color: var(--primary) !important;
    color: white !important;
  }
  .button:focus {
    background-color: white !important;
    color: var(--primary) !important;
    box-shadow: none !important;
  }
}
.menu {
  width: 100%;
  border-radius: 25px !important;
  overflow: hidden;
}
.dropdown-item {
  display: flex !important;
  justify-content: space-between !important;
}

.delete-address-modal-footer {
  display: flex;
  justify-content: space-between;

  &__delete-button {
  background-color: white !important;
  border-color: #d80023!important;
  box-shadow: none !important;
  color: #d80023!important;
  &:hover {
    background-color: #d80023!important;
    border-color: #d80023 !important;
    color: white !important;
  }
  }
}

.address-action {
  cursor: pointer;
}

.product-macros-table {
  margin-top: 16px;
}

.product-macros-table__body {
  font-size: 14px !important;
}
</style>
