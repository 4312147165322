<template>
  <div class="address-wrapper py-4 border items-center align-items-center">
    <b-img class="img" :src="visa" width="30" />
    <div class="address-content">
      <div class="address-info">
        {{ address.autocomplete.slice(0, -4) }}
      </div>
      <div v-if="address.unit" class="address-info">
        {{ address.unit }}
      </div>
    </div>

    <div class="address-actions">
      <slot name="select" />
      
      <slot name="delete" />
    </div>
  </div>
</template>

<script>
import visa from '~/../assets/pin.svg';
export default {
  props: { address: { type: Object, required: true } },
  
  data: () => ({ visa }),

  watch: {
    address: {
      handler() {
        return this.address;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  margin-left: 10px;
}

.address-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}
.address-content {
  font-size: 14px;
}

.address-info {
  font-weight: bold;
  color: #000;
}

.address-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
