
<div>
  <div class="spacer" />
  <transition name="slide-fade" :duration="{ enter: 800, leave: 200 }">
    <b-alert
      v-if="show"
      v-model="alert"
      class="position-fixed bottom-bar pl-4 pr-2 py-2"
      variant="black"
    >
      <!-- <b-container class="px-0 bottom-bar-contents"> -->
      <b-row
        class="justify-content-between cart-info-label pl-2 pr-0"
        align-v="center"
      >
        <b-col v-if="fetchingQuote" cols="auto">
          <b-spinner class="ml-2" small variant="light" />
        </b-col>
        <b-col v-else cols="auto" class="sticky-footer-text">
          <b-row>
            {{ itemsCount }}
            <span class="ml-1 quantity-text"
              >item<span v-if="itemsCount > 1">s</span>:</span
            >
            <span
              v-if="quote && parseFloat(quote.subtotal) > 0 && !fetchingQuote"
              class="ml-1"
              >&nbsp; <span class="currency-symbol">$</span>
              <number
                :to="quote.subtotal"
                :duration="0.12"
                :format="formatPriceToCompact"
              />
              <span class="currency-symbol">USD</span>
              <b-icon-info-circle-fill
                v-if="!meetsMinimumSubtotal"
                v-b-popover.hover.top="
                  `You need a subtotal of at least \$${vendor.minimum_order_subtotal} to proceed!`
                "
                class="info-cart"
                scale="0.6"
              />
              <span v-else class="ml-1"></span>
            </span>
          </b-row>
          <b-row
            v-if="region && region.max_weight_per_order_grams"
            :class="
              'region-requirements d-flex align-items-center' +
              (meetsMaxWeight ? '' : 'text-danger')
            "
          >
            {{ totalOrderWeightGrams ? totalOrderWeightGrams : 0
            }}<span class="green green--text mr-1">g</span> /<span
              class="ml-1"
            />{{ region.max_weight_per_order_grams
            }}<span class="green green--text">g</span>
            <b-icon-info-circle-fill
              v-b-popover.hover.top="
                `Orders must be ${region.max_weight_per_order_grams}g or under`
              "
              scale="0.6"
            />
          </b-row>
        </b-col>
        <b-col cols="auto">
          <b-btn class="btn" size="lg" to="/cart">Proceed</b-btn>
        </b-col>
      </b-row>
      <!-- </b-container> -->
    </b-alert>
  </transition>
</div>
