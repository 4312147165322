import preppedIcon from '#/icons/prepped.png';
import preppedLogo from '#/logos/prepped.png';

export const configuration = {
  apiUrl: import.meta.env.VITE_API_URL,
  production: import.meta.env.VITE_PRODUCTION === 'true',
  appIcon:
    JSON.parse(localStorage.getItem('personalization'))?.theme_logo_url ||
    preppedLogo,
  appMobileIcon:
    JSON.parse(localStorage.getItem('personalization'))?.theme_mobile_logo_url ||
    preppedIcon,
  externalLinks: import.meta.env.VITE_EXTERNAL_LINKS
    ? JSON.parse(import.meta.env.VITE_EXTERNAL_LINKS.replace(/'/g, '"'))
    : [],
  tosUrl: import.meta.env.VITE_TOS_URL,
  vendorUuid: import.meta.env.VITE_VENDOR_UUID,
};
