<template>
  <b-modal
    v-model="value"
    :title="`Code: ${promotion ? promotion.code : ''}`"
    ok-only
    @hidden="$emit('input', false)"
  >
    <div v-if="promotion">
      <p>{{ promotion.description }} 🙂</p>
      <span
        v-if="promotion && promotion.expires_at"
        class="font-italic text-muted"
        >Redeemable {{ expiry }}</span
      >
    </div>
    <template #modal-footer>
      <b-btn @click="$emit('input', false)">Ok</b-btn>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DiscountCodeInfo',
  props: ['value', 'promotion'],
  computed: {
    expiry() {
      return this.promotion && this.promotion.expires_at
        ? ' Until ' + moment(this.promotion.expires_at).format('MMM Do YYYY')
        : ' Forever!';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

@include buttons;
</style>
