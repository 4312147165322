export const formatPriceToCompact = (value) => {
  if (value < 1000) return formatDefaultPrice(value).replace('$', '')

    const options = {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      notation: 'compact',
      roundingMode: 'trunc'
    }

    return new Intl.NumberFormat('en', options).format(value)
}

export const formatDefaultPrice = (value) => {
  if (!value) value = 0
  const sum = typeof value === 'string' ? value.replaceAll(',', '') : value
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
    +(sum || 0),
  )
}