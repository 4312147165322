
<b-row class="py-4 border items-center align-items-center">
  <b-col class="text-center" cols="2">
    <b-img :src="getBrandImage(value.brand)" width="40" />
  </b-col>
  <b-col cols="6">
    <div class="credit-card-identifier">{{ value.identifier }}</div>
    <div class="text-muted d-flex justify-content-between">
      <span class="cardholder-label">{{ value.cardholder_name }}</span>
      <span class="exp-label"
        >Exp: {{ value.exp_month }}/{{ value.exp_year }}</span
      >
    </div>
  </b-col>
  <b-col class="text-center" cols="2">
    <slot name="delete" />
  </b-col>
  <b-col class="text-center" cols="2">
    <slot name="action" />
  </b-col>
</b-row>
