
<b-modal
  v-if="region"
  id="age-modal"
  :visible="visible"
  cancel-title="No"
  centered
  header-class="bg-info text-white"
  hide-header-close
  no-close-on-backdrop
  no-close-on-esc
  ok-title="Yes"
  title="Before you enter our site"
  @cancel="no"
  @ok="yes"
>
  <p>Are you {{ region.minimum_age }} years of age or older?</p>
  <p v-if="underAge" class="text-danger">
    Sorry, you must be {{ region.minimum_age }} years of age to shop here.
  </p>
</b-modal>
