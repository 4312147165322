
<b-container>
  <b-row align-h="center" class="py-5">
    <h1 class="display-4 align-center text-center">Subscriptions</h1>
  </b-row>
  <b-row v-if="loading" align-h="center">
    <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
  </b-row>
  <b-row
    v-else-if="subscriptions && subscriptions.length > 0"
    align-h="center"
  >
    <b-col cols="12" sm="10">
      <b-alert
        :show="alertCountDown"
        class="mt-2"
        dismissible
        variant="success"
        @dismissed="alertCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <span>{{ feedbackMessage }}</span>
        <br />
        <b-progress
          v-show="false"
          :max="defaultSecs"
          :value="alertCountDown"
          height="4px"
          precision="2"
          variant="success"
        ></b-progress>
      </b-alert>
    </b-col>
    <b-col cols="12" sm="10">
      <b-list-group>
        <b-list-group-item
          v-for="(subscription, i) in subscriptions"
          :key="i"
        >
          <subscription-list-item
            v-model="subscriptions[i]"
            :unsub.sync="unsub"
            :view-sub.sync="viewSub"
            :pause-sub.sync="pauseSub"
            :unpause-sub.sync="unpause"
            @update="handleSubUpdate"
          />
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col cols="12">
      <b-row align-h="center" class="mt-4">
        <b-pagination
          v-model="pagination.page"
          variant="info"
          pills
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
  <b-row v-else align-h="center">
    <b-col cols="12" sm="10">
      <b-jumbotron>
        <template #header>
          <div class="jumbotron-title">Let's Do This!</div>
        </template>
        <template #lead
          >Do you love your {{ vendor.name }}? Set it and forget it to receive your
          orders on a personalized schedule.</template
        >
        <hr class="my-4" />
        <div class="mb-2"><b>How to subscribe:</b></div>
        <div class="d-flex mb-1">
          <div class="number-bubble">1</div>
          <div class="ml-1">
            Fill your cart with items you'd like to subscribe to.
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="number-bubble">2</div>
          <div class="ml-1">
            On the checkout page, click the <u>subscribe</u> button
          </div>
        </div>
        <div class="d-flex">
          <div class="number-bubble">3</div>
          <div class="ml-1">Set your custom schedule</div>
        </div>
      </b-jumbotron>
    </b-col>
  </b-row>
  <b-modal
    :visible="unsub !== null"
    title="Cancel Subscription?"
    ok-title="Unsubscribe"
    @hidden="unsub = null"
    @ok="cancelSubscription"
  >
    <p>
      <b>NOTICE:</b> If this is a day that you would normally receive your
      subscription, you will still be charged and your order will be prepared
      for you.
    </p>
  </b-modal>
  <b-modal
    :visible="unpause !== null"
    title="Unpause Subscription?"
    ok-title="Unpause"
    @hidden="unpause = null"
    @ok="unpauseSubscription"
  >
    <p><b>NOTICE:</b> You subscription will be unpaused</p>
  </b-modal>
  <b-modal
    v-if="viewSub"
    :visible="viewSub !== null"
    title="Subscription Details"
    ok-title="close"
    ok-only
    @hidden="viewSub = null"
  >
    <b-list-group>
      <div v-for="(product, i) in viewSub.order.products" :key="i">
        <order-product :product-wrapper="product" />
        <hr v-if="i < viewSub.order.products.length - 1" class="w-75" />
      </div>
    </b-list-group>
  </b-modal>
  <b-modal
    v-if="pauseSub"
    :visible="pauseSub !== null"
    title="Pause Subscription"
    ok-title="Pause"
    @hidden="pauseSub = null"
    @ok="updateSubscription"
  >
    <b-list-group>
      <p>Pause Until</p>
        <b-form-datepicker
          ref="pausedTo"
          v-model="pausedTo"
          :max="max"
          :min="min"
          hide-header
          :date-format-options="{ day: 'numeric' }"
        />
    </b-list-group>
  </b-modal>
</b-container>
