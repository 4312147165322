
<b-list-group-item class="border-0">
  <hr class="w-75 mt-0" />
  <h2>Summary</h2>
  <p>Order {{ order.code }}</p>
  <b-row>
    <b-col
      v-if="order.type === 'Delivery' || order.type === 'Shipping'"
      class="mb-2 summary-wrapper"
      sm="12"
      md="3"
    >
      <b v-if="order.type === 'Delivery'">Delivery Address</b>
      <b v-else>Shipping Address</b>
      <br />
      <p class="mb-0">{{ order.address }}</p>
    </b-col>
    <b-col class="mb-2 summary-wrapper" sm="12" md="3">
      <b>Order Status</b><br />
      <p class="mb-0">{{ order.status }}</p>
    </b-col>
    <b-col class="mb-2 summary-wrapper" sm="12" md="6">
      <b>Cost Summary</b>
      <CostBreakdown :value="order.breakdown" :gift-card-funds="order.breakdown.gift_card_credit" class="w-100" />
    </b-col>
  </b-row>
</b-list-group-item>
