
<div>
  <h4>We can't fulfill your order for the following reasons:</h4>
  <ul>
    <li v-if="!meetsMinimumSubtotal">
      The minimum order subtotal is ${{ region.minimum_order_subtotal }} and
      the subtotal of your cart is ${{ quote.subtotal }}. <br />
      <span class="highlight"
        >Please add ${{
          region.minimum_order_subtotal - quote.subtotal
        }}
        worth of product(s) to continue.</span
      >
    </li>
    <li v-if="!meetsMaxWeight">
      The maximum order weight is {{ region.max_weight_per_order_grams }}g and
      the weight of product(s) in your cart is {{ totalOrderWeightGrams }}g.
      <br />
      <span class="highlight"
        >Please remove {{ gramsDifference }}g worth of product(s) to
        continue.</span
      >
    </li>
  </ul>
  <b-row align-h="center">
    <b-button href="/" variant="info"
      ><span class="mdi mdi-storefront mr-1"></span>Back to Shop</b-button
    >
  </b-row>
</div>
