
<b-container fluid class="mt-3" align-v="center">
  <b-row align-v="stretch" class="text-center region-selection">
    <b-col>
      <b-jumbotron bg-variant="light">
        <h1 class="display-4">Let's do this.</h1>
        <p>Select a region to get started.</p>
        <div class="selectWrapper mt-4">
          <b-form-select
            :options="regionOptions"
            :value="null"
            class="selectBox"
            @change="changeRegion"
          >
            <b-form-select-option :value="null" disabled
              >Select a region
            </b-form-select-option>
          </b-form-select>
        </div>
      </b-jumbotron>
    </b-col>
  </b-row>
</b-container>
