import _axios from 'axios';
import { configuration } from '~/configuration';
import { auth } from './auth';

export const axios = init();

function init() {
  const MAX_REQUESTS_COUNT = 25;
  const INTERVAL_MS = 10;
  let pendingRequests = 0;

  let axios = _axios.create({
    baseURL: configuration.apiUrl,
    timeout: configuration.requestTimeout,
  });

  axios.interceptors.request.use(
    function (config) {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if (pendingRequests < MAX_REQUESTS_COUNT) {
            pendingRequests++;
            clearInterval(interval);
            if (auth.isAuthenticated()) {
              config.headers['Authorization'] = 'Bearer ' + auth.getBearerJwt();
            }
            
            if (Capacitor.isNativePlatform()) {
              config.headers['X-Fcm-Token'] = localStorage.getItem('fcmToken');
            }

            if (configuration.production !== true) {
              if (!config.params) {
                config.params = {};
              }
            }

            resolve(config);
          }
        }, INTERVAL_MS);
      });
    },
    function (error) {
      return Promise.reject(error.response.data);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      pendingRequests = Math.max(0, pendingRequests - 1);
      return response.data;
    },
    function (error) {
      pendingRequests = Math.max(0, pendingRequests - 1);
      if (!error.response || !error.response.status) return Promise.reject({ message: 'Network error' });
      if (
        error.response &&
        error.response.status === 401 &&
        !error.request.responseURL.includes('api/login')
      ) {
        auth.destroyToken();
        location.reload();
      } else if (error.response && error.response.status === 401) {
        return Promise.reject(error);
      } else if (error.response && error.response.status === 422) {
        if (error.response.data && error.response.data.errors) {
          const errors = Object.entries(error.response.data.errors).map(
            (x) => `${x[1][0]}`
          );
          error.response.data.message = `${errors[0]}`;
        }
      }

      return Promise.reject(error.response.data);
    }
  );

  axios.all = _axios.all;
  axios.spread = _axios.spread;

  return axios;
}
