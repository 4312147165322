
<b-form @submit="onSubmit">
  <Label v-if="$slots.title">
    <template #title>
      <slot name="title" />
    </template>
  </Label>

  <b-input-group>
    <b-input-group-prepend v-if="$slots.icon">
      <b-input-group-text>
        <IconBase
          size="16"
          :icon-color="active ? personalization.theme_color_1 : ''"
          :icon-name="'icon'"
          >
          <slot name="icon" />
        </IconBase>
      </b-input-group-text>
    </b-input-group-prepend>

    <b-form-input
      :value="currentValue"
      v-bind="$attrs"
      :placeholder="placeholder"
      :type="type"
      :readonly="readonly"
      :formatter="formatter"
      :min="min"
      :max="max"
      step="0.01"
      @blur="active = false"
      @focus="active = true"
      @input="$emit('input', $event)"
    />

    <b-input-group-append>
      <b-button
        :disabled="isButtonDisabled"
        class="action-button"
        type="submit"
      >
        Save
      </b-button>
    </b-input-group-append>
  </b-input-group>
</b-form>
