
<b-container>
  <b-row class="my-4">
    <b-img
      class="text-center mx-auto"
      fluid
      width="400"
      src="https://hello.orderprepped.com/wp-content/uploads/2021/08/undraw_city_driver_re_0x5e.png"
    ></b-img>
  </b-row>

  <h1>For Couriers</h1>
  <p class="grey grey--text">
    Couriers make more commission delivering with
    us than with big-name delivery apps.
  </p>

  <p class="hint text-center">
    You are applying to work in the <b>{{ region.name }}</b> region. Use the
    button at the top of the screen to change this.
  </p>

  <b-form class="mt-4">
    <transition
      mode="out-in"
      name="fade"
      :duration="{ enter: 400, leave: 0 }"
    >
      <b-form-row align-h="center">
        <b-col md="4">
          <b-form-group label="Name (First & Last)">
            <b-form-input
              v-model.trim="courier.driver.name"
              required
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Email">
            <b-form-input
              v-model.trim="courier.driver.email"
              autocomplete="email"
              autofocus
              required
              type="email"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Phone Number">
            <b-form-input
              v-model.trim="courier.driver.phone"
              v-mask="'+1(###)###-####'"
              required
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
    </transition>
  </b-form>
  <Address :address.sync="address" :loading.sync="loading"></Address>
  <b-row class="mt-5">
    <b-button class="btn-cta mx-auto" :disabled="!validForm" @click="register"
      >Submit</b-button
    >
  </b-row>
</b-container>
