import convert from 'convert';

export const convertHeightToCm = (heightStr) => {
  const [feet, inches] = heightStr.split("'").map(parseFloat);
  const heightInCm =
    convert(feet).from('ft').to('cm') + convert(inches).from('in').to('cm');
  return heightInCm;
};
export const convertHeightToInch = (heightInCm) => {
  const feet = Math.floor(heightInCm / 30.48);
  const inches = Math.round((heightInCm / 2.54) % 12);
  const lengthStr = `${feet}'${inches}''`;
  return lengthStr;
};

export const convertKgToLbs = (kg) => {
  return kg * 2.20462262185;
};

export const convertLbsToKg = (lbs) => {
  return lbs / 2.20462262185;
};
