<template>
  <b-container>
    <b-row class="justify-center pt-2">
      <div class="col-12 align-items-center d-flex">
        <router-link :to="`/cart`" class="position-absolute align-middle">
          <h2 class="px-0 mb-0">
            <b-icon-arrow-left-circle class="back-arrow"></b-icon-arrow-left-circle>
          </h2>
        </router-link>

        <h1 class="text-center px- mt-1 mb-0 w-100">
          {{ order.code ? 'Success' : 'Checkout' }}
          <span class="mdi mdi-cart cart-icon"></span>
        </h1>
      </div>
      <div class="col-12 align-items-center">
        <template v-if="!loading && region && vendor && !order.code">
          <h4 class="mt-1 mb-2 text-center px-1">{{ vendor.name }}</h4>
        </template>
      </div>
    </b-row>
    <hr class="mt-2 mb-4" />

    <b-row v-show="loading" key="loading" align-h="center">
      <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
    </b-row>
    <div v-show="!loading">
      <b-row v-if="order && order.code !== null" key="success">
        <b-container class="checkout-container text-center">
          <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled">
            <p>
              Order <b>{{ order.code }}</b> has been <b>Scheduled</b>.
            </p>
            <template v-if="order.type === ORDER_TYPES.delivery">
              <p>We'll remind you by email before it departs for:</p>
              <p>
                <u>{{ order.address }}</u>
              </p>
            </template>
            <template v-else-if="order.type === ORDER_TYPES.pickup">
              <p> We'll remind you by email {{ vendorRegion.prep_time_minutes }} minutes before it's ready for
                <b>Pickup</b> on {{ moment(order.scheduled).format('MMM D, h:mma') }} at: </p>
              <p>
                <u>{{ vendorRegion.address.autocomplete }}</u>
              </p>
            </template>
          </template>
          <template v-else-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">
            <p>Thanks for Subscribing, {{ contact.name }}!</p>
            <template v-if="order.type === ORDER_TYPES.delivery">
              <p> We'll email you when your <b>Deliveries</b> are on the way to: </p>
              <p>
                <u>{{ order.address }}</u>
              </p>
            </template>
            <template v-else-if="order.type === ORDER_TYPES.pickup">
              <p> We'll email you {{ vendorRegion.prep_time_minutes }} minutes before your orders will be ready for
                <b>Pickup</b> at: </p>
              <p>
                <u>{{ vendorRegion.address.autocomplete }}</u>
              </p>
            </template>
          </template>
          <template v-else>
            <p>
              Order <b>{{ order.code }}</b> has been <b>Received</b>.
            </p>
            <template v-if="order.type === ORDER_TYPES.delivery">
              <p>We'll email you when it's on it's way to:</p>
              <p>
                <u>{{ order.address }}</u>
              </p>
            </template>
            <template v-else-if="order.type === ORDER_TYPES.pickup">
              <p> It will be ready for <b>Pickup</b> on {{ moment(order.scheduled).format('MMM D, h:mma') }} at: </p>
              <p>
                <u>{{ vendorRegion.address.autocomplete }}</u>
              </p>
            </template>
          </template>

          <p>
            Questions? Get support
            <a target="_blank" :href="`mailto:${vendorRegion.operator_email}`">here</a>.
          </p>

          <b-row align-h="center" class="mt-5">
            <b-button class="btn-cta" to="/">Shop</b-button>
          </b-row>
        </b-container>
      </b-row>
      <b-row v-else>
        <b-container class="checkout-container">
          <h1 class="mb-3 text-center text-md-left">When?</h1>
          <b-row v-if="vendorRegion.delivery_enabled || vendorRegion.enable_pickup" class="justify-space-around"
            align-h="center">
            <b-col cols="12" lg="6">
              <b-form-group
                v-if="cartProducts && cartProducts.length && !cartProducts[0].max_weekly_frequency && !cartProducts[0].subscription"
                label="Choose the Frequency">
                <b-form-select v-model="order.deliveryType" class="d-inline" prepend-icon="mdi-clock"
                  :options="options"></b-form-select>
              </b-form-group>
              <span v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.now" class="hint">
                {{ vendor.name }} needs
                {{ moment.duration(vendorRegion.prep_time_minutes, 'minutes').humanize(false, 'm') }}
                notice to prepare an order.
              </span>
              <p v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled" class="mt-3"> 🕘 We'll see you later!
              </p>
            </b-col>

            <b-col v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled" cols="12" lg="6">
              <b-row>
                <b-col md="12">
                  <b-form-group :label="orderType === ORDER_TYPES.delivery ? 'Delivery Date' : 'Pickup Date'">
                    <b-input-group>
                      <b-form-datepicker v-model="order.date" class="date-picker" :date-disabled-fn="dateDisabled"
                        :max="maxDate" :min="minDate" locale="en"
                        :placeholder="`Choose a ${orderType === ORDER_TYPES.delivery ? 'delivery date' : 'pickup date'}`" />
                      <b-input-group-prepend>
                        <b-button class="rounded-end" variant="info" @click="order.date = order.time = null">
                          <b-icon-x />
                        </b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="orderType !== ORDER_TYPES.shipping">
                <b-col md="12">
                  <b-form-group
                    :label="noSlotsOnDay ? `${vendor.name} isn't open that day!` : orderType === ORDER_TYPES.delivery ? 'Delivery Window' : 'Pickup Time'">
                    <b-input-group>
                      <b-form-select v-model="order.time" :disabled="!order.date || noSlotsOnDay"
                        :options="order.date ? intervalOptions[moment(order.date).format('dddd').toLowerCase()] : []" />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">
              <b-col v-if="cartProducts[0].subscription" cols="12">
                <p class="mt-3">You will get your food at:</p>
                <p v-for="item in productSubscription" :key="item.day">
                  <span class="time">{{ item.day }}: </span>
                  <span>{{ moment(item.time, 'hh:mm:ss').format('h:mm A') }}</span>
                </p>
              </b-col>

              <b-col v-else-if="!loading" cols="12" lg="6">
                <p class="mt-3">🕘 Set it and forget it. We've got you.</p>
                <b-row>
                  <b-col cols="12">
                    <SubscriptionEditor v-model="order.subscription" :subscription="subscribedDays" :vendor="vendor"
                      :order="order" :delivery-schedule-intervals="deliveryScheduleIntervals"
                      :pickup-schedule-intervals="pickupScheduleIntervals"
                      :weekly-frequency="(cartProducts.length === 1 && cartProducts[0].max_weekly_frequency) || null"
                      @updateSelectedDays="updateSelectedDays" />
                  </b-col>
                </b-row>
              </b-col>
            </template>
          </b-row>

          <h1 class="mt-4 mb-2 text-center text-md-left">Who?</h1>
          <b-form ref="form" class="contact-form" novalidate @submit.prevent="checkout">
            <b-form-row align-h="center">
              <b-col md="4">
                <b-form-group label="Name">
                  <b-form-input v-model.trim="contact.name" :state="contact.name && contact.name.length > 1"
                    placeholder="" required type="text" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Email">
                  <b-form-input v-model.trim="contact.email" disabled placeholder="" required type="email">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Phone">
                  <b-form-input v-model.trim="contact.phone" v-mask="'+1(###)###-####'"
                    :state="contact.phone && contact.phone.length === 15" required type="text" />
                  <div id="credit-card-anchor"></div>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>

          <div>
            <h1 id="credit-card-title" class="mt-4 mb-3 text-center text-md-left"> Billing </h1>
            <payment-methods v-model="order.payment_method_uuid" />
          </div>

          <h1 class="mt-5 mb-3 text-center text-md-left">Order Note</h1>
          <b-form-textarea id="textarea" v-model="order.note" style="border-radius: 5px !important" max-rows="6"
            maxlength="500" rows="3"></b-form-textarea>

          <template v-if="order.order_type === ORDER_TYPES.delivery">
            <h1 class="mt-5 mb-3 text-center text-md-left">Delivery Note</h1>
            <b-form-textarea id="textarea" v-model="order.delivery_note" style="border-radius: 5px !important"
              max-rows="6" maxlength="500" rows="3"></b-form-textarea>
          </template>

          <p class="my-5 text-center" v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.now">
            {{ vendor.name }} needs
            {{ moment.duration(vendorRegion.prep_time_minutes, 'minutes').humanize(false, 'm') }}
            notice to prepare an order.
          </p>

          <b-row align-h="center" class="my-5">
            <b-button :disabled="disableCheckout" class="btn-cta" @click="checkout">
              <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled">Schedule</template>
              <template v-else-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">Subscribe</template>
              <template v-else>Continue</template>
            </b-button>
          </b-row>
        </b-container>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import loadingGif from '#/loading.gif'
import Address from '@/auth/Address.vue'
import PaymentMethods from '@/cart/PaymentMethods.vue'
import SubscriptionEditor from '@/subscriptions/SubscriptionEditor.vue'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { auth } from '^/auth'
import { axios } from '^/axios'
import { configuration } from '~/configuration'
import moment from 'moment-timezone'

const now = new Date()
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
// 15th two months prior
const minDate = new Date(today)
const maxDate = new Date(today)
maxDate.setMonth(maxDate.getMonth() + 1)

moment.relativeTimeRounding(Math.floor)
moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

const ORDER_TYPES = {
  delivery: 'Delivery',
  pickup: 'Pickup',
  shipping: 'Shipping'
}

const intervalOptions = {
  sunday: [],
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: []
}

const WEEK_DAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export default {
  components: {
    Address,
    PaymentMethods,
    SubscriptionEditor
  },

  directives: { mask },

  data: () => ({
    options: [
      { value: 'scheduled', text: 'Schedule this for later.' }
    ],
    governance: auth.getExtendedRoleClaims('settings'),
    contactless_options: {
      leave: 'LeaveUnattended',
      attempt_accept: 'AttemptAccept',
      must_accept: 'MustAccept'
    },

    DELIVERY_TYPE_OPTIONS: {
      now: 'now',
      scheduled: 'scheduled',
      subscribe: 'subscribe'
    },
    address: {
      id: null,
      autocomplete: null,
      unit: null
    },
    contact: {
      name: String(),
      email: String(),
      phone: String()
    },
    pickupScheduleIntervals: {},
    deliveryScheduleIntervals: {},
    valid: false,
    loading: false,
    configuration,
    moment,
    minDate,
    maxDate,
    loadingGif,
    order: {
      deliveryType: 'scheduled',
      order_type: String(),
      code: null,
      promo_code: null,
      products: [],
      date: null,
      time: null,
      note: null,
      delivery_note: undefined,
      contactless_option: null,
      payment_method_uuid: String(),
      gift_card_credit: undefined,
      gift_card_uuid: undefined,
      tip: {
        value: Number(),
        type: Number()
      },
      address_id: Number(),
      subscription: {
        days_of_week_times: new Array(7)
      }
    },
    productSubscription: [],
    noSlotsOnDay: false,
    selectedDaysQuantity: 0,
    ORDER_TYPES,
    intervalOptions,
    WEEK_DAYS,
    soonestCutOffProduct: null,
    soonestCutOff: null
  }),
  beforeDestroy() {
    // If order has been done, and customer leave order page, remove order data
    if(this.order.code){
      Object.keys(this.order).forEach((key) => {
        this.order[key] = null;
      });
      localStorage.removeItem('state.checkout');
      localStorage.removeItem('state.cart');
    }
  },

  async mounted() {
    if (!this.user) {
      return await this.$router.push({
        path: 'validate',
        query: { redirect: '/checkout' }
      })
    }
    if (this.cartProducts.length === 0) {
      await this.$store.dispatch('alertError', "You don't have anything in your cart. Select some products on the shop page to proceed!")
      return this.$router.push({
        path: '/'
      })
    }

    this.loading = true

    const storedOrder = localStorage.getItem('state.checkout')
    const storedCart = JSON.parse(localStorage.getItem('state.cart'))

    if (storedCart) {
      if (storedCart.order_type) {
        this.$store.commit('setOrderType', storedCart.order_type);
      }

      if (storedCart.gift_card_credit) {
        this.order.gift_card_credit = Number(storedCart.gift_card_credit).toFixed(2)
        this.order.gift_card_uuid = this.giftCards?.uuid
      }

      if (storedCart.shipping_rate_id) {
        this.$store.commit('setShippingRateId', storedCart.shipping_rate_id);
      }
    }


    this.contact = { ...this.contact, ...this.user }
    this.order.contactless_option = this.contactless_options.attempt_accept

    if (this.cartProducts.length && this.cartProducts[0].subscription) {
      const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

      days.forEach(dayKey => {
        if (!this.cartProducts[0].subscription[dayKey]) return
        this.productSubscription = [...this.productSubscription, { day: this.days[dayKey], time: this.cartProducts[0].subscription[dayKey] }]
      })
    }

    this.address = this.user.addresses.find((address) => address.id === storedCart.address)

    await this.getVendorDeliveryScheduleIntervals()
    await this.getVendorPickupScheduleIntervals()

    if (storedOrder) {
      const { subscription, date, time, deliveryType, note, delivery_note } = JSON.parse(storedOrder)

      if (deliveryType) {
        this.order.deliveryType = deliveryType
      } else {
        this.order.deliveryType = this.vendorRegion && this.vendorRegion.default_order_cadence === 'Subscription' ? 'subscribe' : 'scheduled'
      }
      this.order.subscription = subscription || this.order.subscription
      this.note = note || null
      this.order.time = time || null
      this.order.note = note || undefined
      this.order.delivery_note = delivery_note || undefined

      this.handleDateChange(date)
    } else {
      this.order.deliveryType = this.vendorRegion && this.vendorRegion.default_order_cadence === 'Subscription' ? 'subscribe' : 'scheduled'
    }

    if (this.quote && Number(this.quote.discount)) {
      this.order.promo_code = this.discountCode
    }

    this.order.tip = this.tip

    this.order.order_type = this.orderType

    let filtered = this.cartProducts.filter(p => p.cut_off_time)
    if (filtered.length > 0) {
      let soonest = filtered.reduce(function (prev, curr) {
        if (prev.cut_off_day < curr.cut_off_day) return prev;
        if (prev.cut_off_day > curr.cut_off_day) return curr;

        return prev.cut_off_time < curr.cut_off_time ? prev : curr;
      });

      if(!this.vendor.settings.disable_cutoffs){          
        this.soonestCutOffProduct = soonest
        this.soonestCutOff = moment(soonest.cut_off_time, 'HH:mm:ss').isoWeekday(soonest.cut_off_day).set("week", moment().week());

        const now = moment()
        if(this.soonestCutOff && now.isSameOrAfter(this.soonestCutOff)){
          this.$store.dispatch(
            'alertInfo',
            `${this.soonestCutOffProduct.title} must be ordered by ${this.soonestCutOff.format('dddd')} at ${this.soonestCutOff.format('h:mma')} to be received the following week.`
          );
        }
      }
    }

    if (this.vendorRegion.enable_on_demand && this.isVendorOpen(this.vendorRegion, this.orderType === ORDER_TYPES.delivery) && !this.soonestCutOff) {
      this.options.push({ value: 'now', text: 'I want this right now.' })

      if (this.vendorRegion.default_order_cadence === this.DELIVERY_TYPE_OPTIONS.now) {
        this.order.deliveryType = this.DELIVERY_TYPE_OPTIONS.now
      }
    }

    if (this.vendor.settings.enable_subscriptions) {
      this.options.push({ value: 'subscribe', text: 'Subscribe to this.' })

      if (this.vendorRegion.default_order_cadence === this.DELIVERY_TYPE_OPTIONS.subscribe) {
        this.order.deliveryType = this.DELIVERY_TYPE_OPTIONS.subscribe
      }
    } 

    this.compileIntervalOptions()
    this.$forceUpdate()
    this.loading = false
  },

  computed: {
    ...mapGetters(['orderAddress', 'user', 'vendor', 'vendorRegion', 'region', 'regionId', 'cartProducts', 'discountCode', 'tip', 'orderType', 'shippingRateId', 'quote', 'giftCards', 'cart']),

    stateEmail: function () {
      const re = new RegExp('^[\\w|+]+@([\\w-]+\\.)+[\\w]+$', 'gi')
      return re.test(this.contact.email)
    },

    vendorUuid() {
      return this.vendor.uuid
    },

    prepTimeInMinutes() {
      return this.vendorRegion.prep_time_minutes
    },

    isFormValid() {
      if (this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.scheduled && !(this.order.date && this.order.time)) return false
      if (this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.subscribe && !this.order.subscription) return false
      if (this.order.order_type === this.ORDER_TYPES.delivery && !this.address?.id) return false
      if (this.order.order_type === this.ORDER_TYPES.shipping && !this.address?.id) return false
      return this.cartProducts.length > 0 && this.order.payment_method_uuid
    },

    subscribedDays() {
      const formatedSub = this.order.subscription.days_of_week_times.map((value, index) => [WEEK_DAYS[index], value]).filter(Boolean)
      return Object.fromEntries(formatedSub)
    },

    disableCheckout() {
      if (!this.isFormValid) return true
      return !this.cartProducts[0].subscription &&
        this.order.subscription &&
        this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.subscribe &&
        Object.values(this.order.subscription.days_of_week_times).filter(Boolean).length < (this.cartProducts[0]?.max_weekly_frequency || 1)
    }
  },

  methods: {
    ...mapActions(['hideAlert', 'alertError']),

    isVendorOpen(vendorRegion, isDelivery) {
      const currentDateTime = moment().tz(this.region.timezone)
      const schedules = isDelivery ? this.$store.getters?.vendorRegion?.delivery_schedules : this.$store.getters?.vendorRegion?.schedules

      for (const schedule of schedules) {
        const startTime = moment(schedule.start, 'HH:mm:ss')
        const endTime = moment(schedule.end, 'HH:mm:ss')

        if (schedule.day === currentDateTime.day() && startTime.isSameOrBefore(currentDateTime) && endTime.isSameOrAfter(currentDateTime)) {
          const closingTime = endTime.clone().subtract(vendorRegion.prep_time_minutes, 'minutes')

          if (closingTime.isAfter(currentDateTime)) {
            return true
          }
        }
      }

      return false
    },

    async getVendorDeliveryScheduleIntervals() {
      this.deliveryScheduleIntervals = await axios.get(`region/${this.region.uuid}/vendor/${this.vendorUuid}/schedule/delivery-intervals`, {
        params: {
          prep_time_minutes: this.prepTimeInMinutes
          // todo: currently doing this client-side in compileIntervalOptions, as we get all slots on created()
          // 'include_past': this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.scheduled && this.order.date.isSame(new Date(), 'day')
        }
      })
    },

    async getVendorPickupScheduleIntervals() {
      this.pickupScheduleIntervals = await axios.get(
        `region/${this.region.uuid}/vendor/${this.vendorUuid}/schedule/pickup-intervals`,
        // `vendor/${this.vendorUuid}/schedule/intervals`,
        {
          params: {
            prep_time_minutes: this.prepTimeInMinutes
            // 'include_past': this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.scheduled && this.order.date.isSame(new Date(), 'day')
          }
        }
      )
    },

    updateUser() {
      return axios.put('user', {
        ...this.user,
        ...this.contact,
        addresses: [
          {
            user_id: this.user.id,
            address_id: this.address.id
          }
        ]
      })
    },

    handleDateChange(value) {
      const day = moment(value).format('dddd')
      const dayLower = day.toLowerCase()

      this.intervalOptions[day.toLowerCase()] = this.getIntervalOptions(day)

      this.noSlotsOnDay = !this.intervalOptions[dayLower] || !this.intervalOptions[dayLower].length

      if (!this.noSlotsOnDay && !this.order.time) this.order.time = this.intervalOptions[dayLower][0].value
    },

    async checkout() {
      try {
        this.$store.dispatch('hideAlert')
        this.loading = true

        if (this.order.order_type === this.ORDER_TYPES.delivery || this.order.order_type === this.ORDER_TYPES.shipping) this.order.address_id = this.address ? this.address.id : null
        else {
          delete this.order.address_id
          this.order.contactless_option = null
        }

        if (this.order.order_type === this.ORDER_TYPES.shipping) {
          this.order.shipping_rate_id = this.shippingRateId
        }

        if (this.order.order_type !== this.ORDER_TYPES.delivery) {
          this.order.delivery_note = undefined
        }

        this.order.products = this.cartProducts.map(x => ({
          product_uuid: x.uuid,
          product_quantity: x.quantityOrdered,
          product_modifiers: x.modifiers
            .map(x => x.items)
            .flat()
            .filter(x => x.item_quantity > 0 || x.item_quantity < 0)
            .map(x => ({ item_uuid: x.uuid, item_quantity: x.item_quantity }))
        }))

        if (this.order.deliveryType !== this.DELIVERY_TYPE_OPTIONS.subscribe) delete this.order.subscription

        if (this.order.deliveryType !== this.DELIVERY_TYPE_OPTIONS.scheduled) {
          this.order.date = null
          this.order.time = null
        }

        const response = await axios.post(`region/${this.region.uuid}/vendor/${this.vendorUuid}/order`, this.order)

        this.$store.commit('clearCart')
        this.$store.dispatch('getGiftCardsInfo');
        this.order = response
        localStorage.removeItem('state.checkout')
        localStorage.removeItem('state.cart')
      } catch (e) {
        this.$store.dispatch('alertError', e.message)
      } finally {
        this.loading = false
        window.scrollTo(0, 0)
      }
    },
    dateDisabled(_, date) {
      const type = this.order.order_type
      const now = moment()

      let enabledWeekDays = []
      if (type === this.ORDER_TYPES.delivery) {
        enabledWeekDays = Object.keys(this.deliveryScheduleIntervals)
      } else if (type === this.ORDER_TYPES.pickup || type === this.ORDER_TYPES.shipping) {
        enabledWeekDays = Object.keys(this.pickupScheduleIntervals)
      }

      if(this.soonestCutOff && this.soonestCutOff.week() == moment(date).week()){
         return true
      }

      if(this.soonestCutOff && now.isSameOrAfter(this.soonestCutOff) && (now.week() + 1 == moment(date).week())){
         return true
      }

      const dateEnabled = !enabledWeekDays.includes(moment(date).format('dddd'))
      return dateEnabled
    },

    canSubOnDay(day) {
      if (this.order.order_type === this.ORDER_TYPES.delivery) return this.$store.getters.vendorRegion.delivery_schedules.find(x => x.day === day)
      else if (this.order.order_type === this.ORDER_TYPES.pickup) return this.$store.getters.vendorRegion.schedules.find(x => x.day === day)
    },

    compileIntervalOptions(type = this.order.order_type) {
      moment.weekdays().forEach(weekday => (this.intervalOptions[weekday.toLowerCase()] = this.getIntervalOptions(weekday, null, type)))
    },

    getIntervalOptions(day, date = this.order.date, type = this.order.order_type) {
      const options = []
      let schedules = []

      if (type === this.ORDER_TYPES.delivery) {
        schedules = this.deliveryScheduleIntervals[day]
      } else if (type === this.ORDER_TYPES.pickup || type === this.ORDER_TYPES.shipping) {
        schedules = this.pickupScheduleIntervals[day]
      }

      const scheduledForToday = date ? moment(date).isSame(moment(), 'date') : false

      const todaysCutOff = moment().add(this.prepTimeInMinutes, 'minutes').format('H:mm:ss')

      if (schedules) {
        const scheduleKeys = Object.keys(schedules)
        const scheduleValues = Object.values(schedules)

        scheduleKeys.forEach((key, index) => {
          const schedule = scheduleValues[index]
          const scheduleMoment = moment(schedule, 'HH:mm:ss')
          const scheduleIsAfterNow = scheduleMoment.isAfter(moment())
          const scheduleIsAfterCutOff = scheduleMoment.isAfter(moment(todaysCutOff, 'H:mm:ss'))

          if (scheduledForToday) {
            if (scheduleIsAfterNow && scheduleIsAfterCutOff) {
              options.push({ text: key, value: schedule })
            }
          } else {
            options.push({ text: key, value: schedule })
          }
        })
      }

      if (this.order.deliveryType === this.DELIVERY_TYPE_OPTIONS.scheduled && options.length > 0 && !this.order.time) {
        this.order.time = options[0].value
      }

      return options
    },
    updateSelectedDays(newData) {
      this.selectedDaysQuantity = newData
    }
  },
  watch: {
    'order.order_type'(value) {
      if (this.options[0].disabled) this.order.deliveryType = 'subscribe'

      this.compileIntervalOptions(value)
    },
    'order.date'(value) {
      this.handleDateChange(value)
    },
    order: {
      handler(value) {
        this.$nextTick(() => {
          localStorage.setItem('state.checkout', JSON.stringify(value))
        })
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

@include inputs;
@include buttons;

.checkout-container {
  p {
    font-size: 1.25rem;
  }

  #textarea {
    textarea {
      border-radius: 5px !important;
    }
  }
}

.hours-of-operation {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  letter-spacing: 0.065rem;
  background-color: black;
  text-decoration: underline;
  color: white;
  font-family: $staatliches-font;
  font-weight: 900;
  font-size: 1.25rem;
}

.schedule {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.date-picker.form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end {
  font-family: $staatliches-font;
  font-weight: 900;
  overflow: hidden !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.type-button {

  &:active,
  &:focus {
    color: white !important;
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.time {
  width: 140px;
  display: block;
  float: left;
}

.order-type {
  width: 100%;

  button {
    display: inline-block;
    width: 100%;
    max-width: 240px;
  }
}

.sub-info {
  padding: 7px 14px;
  border: 2px solid var(--primary);
  border-radius: 8px !important;
  // color: white;
  font-weight: bold;
}

@media only screen and (min-width: 992px) {
  .mt-32 {
    margin-top: 32px;
  }
}
</style>
