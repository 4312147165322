<template>
  <b-modal
    v-model="active"
    :title="value.title"
    hide-footer
    @hidden="handleClose"
  >
    <b-row>
      <b-col sm="6">
        <div class="product-img-wrapper mx-auto mx-sm-0">
          <b-badge v-if="value.brand" variant="info">{{
            value.brand.name
          }}</b-badge>
          <b-img
            :alt="value.title"
            :src="value.image"
            :title="value.title"
            fluid
            thumbnail
          />
          <div class="tags-wrapper">
            <tag
              v-for="tag in value.tags"
              :key="tag"
              v-b-popover.hover.top="tag.name"
              size="sm"
              :value="tag"
              class="mr-1"
            />
          </div>
        </div>
      </b-col>
      <b-col class="text-center text-md-right" sm="6">
        <b-form-rating
          v-model="value.rating"
          class="my-0 mx-0"
          color="gold"
          inline
          no-border
          readonly
          show-value
        />
        <div class="w-100 pr-3 reviews-wrapper">
          <a
            @click.prevent="
              () => {
                viewReviews($event, value);
                handleClose();
              }
            "
            >{{ value.ratings_count }} Reviews</a
          >
        </div>
        <div class="w-100 pr-3 pt-1">
          <b-badge
            v-for="(category, index) in value.category_list"
            :key="index"
            pill
            variant="info"
            >{{ category.category }}</b-badge
          >
        </div>
      </b-col>
    </b-row>
    <template v-if="value.description">
      <h5 class="pt-3">Description:</h5>
      <div v-html="value.description" />
    </template>
  </b-modal>
</template>

<script>
import Tag from '^/components/Tag.vue';

export default {
  name: 'ProductInfo',
  components: { Tag },
  props: ['value', 'viewReviews'],
  data() {
    return {
      active: false,
      loading: false,
    };
  },
  watch: {
    value(val) {
      this.active = val != null;
    },
  },
  async created() {
    this.active = true;
  },
  methods: {
    handleClose() {
      this.$emit('input', null);
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.product-img-wrapper {
  position: relative;
  max-width: 210px;

  .badge {
    padding-top: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 0.85em;
    letter-spacing: 0.085em;
    font-family: $staatliches-font;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    border: 1px solid #dee2e6;
    border-top: none;
    color: var(--primary);
  }

  img {
    max-width: 210px;
    height: auto;
  }
}

.reviews-wrapper > a {
  letter-spacing: 0.06rem;
  font-family: $staatliches-font;
  font-size: 80%;
  color: $placeholder-font-color !important;
  cursor: pointer;

  &:hover {
    color: var(--primary) !important;
  }
}

.product-placeholder {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 210px;
  min-height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-family: 'Lobster', Helvetica, sans-serif;

  div {
    width: 100px;
    height: 100px;
    border-radius: 25rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tags-wrapper {
  position: absolute;
  bottom: 8px;
  right: 5px;
  display: inline-flex;
}
</style>
