
  <g>
    <path
      d="M99.85,46.81c-0.09,0.16-0.19,0.31-0.26,0.48c-0.8,1.78-2.61,2.75-4.41,2.36c-1.85-0.41-3.07-1.94-3.07-3.92
			c-0.01-7.13,0.01-14.26-0.01-21.39c-0.01-3.33-1.32-5.96-4.39-7.46c-0.96-0.47-2.08-0.72-3.15-0.8c-1.69-0.13-3.41-0.03-5.24-0.03
			c0,1.22,0,2.4,0,3.58c-0.01,2.45-1.6,4.15-3.88,4.14c-2.27,0-3.85-1.7-3.86-4.16c0-1.16,0-2.31,0-3.52c-5.89,0-11.71,0-17.62,0
			c0,1.25,0.01,2.47,0,3.68c-0.02,2.3-1.63,3.98-3.83,4c-2.25,0.02-3.89-1.67-3.91-4.01c-0.01-1.19,0-2.38,0-3.65
			c-5.79,0-11.54,0-17.43,0c0,1.16,0,2.34,0,3.52c-0.01,2.45-1.6,4.15-3.87,4.15c-2.27,0-3.85-1.7-3.86-4.16c0-1.16,0-2.31,0-3.7
			c-2.28,0.13-4.54-0.01-6.68,0.44c-3.6,0.74-5.8,3.8-5.91,7.62c0,0.16,0,0.32,0,0.48c0,19.68,0,39.35,0,59.03
			c0,3.36,1.24,6.09,4.38,7.55c1.24,0.57,2.72,0.84,4.1,0.85c9.58,0.07,19.16,0.04,28.74,0.04c1.55,0,2.75,0.63,3.51,1.98
			c0.74,1.31,0.7,2.66-0.09,3.94c-0.71,1.15-1.77,1.8-3.12,1.8c-10.29,0.01-20.58,0.1-30.87-0.04C8.27,99.5,1.78,93.41,0.94,86.62
			c-0.11-0.89-0.19-1.8-0.19-2.69c-0.01-19.97-0.01-39.93-0.01-59.9c0-7.6,4.85-13.69,12.27-15.4c0.84-0.19,1.72-0.3,2.58-0.32
			c1.77-0.05,3.55-0.01,5.48-0.01c0-0.92,0.07-1.82-0.01-2.7c-0.22-2.39,0.63-4.13,2.91-5.06c0.65,0,1.29,0,1.94,0
			c2.28,0.93,3.13,2.67,2.91,5.06c-0.08,0.88-0.01,1.77-0.01,2.64c5.88,0,11.61,0,17.43,0c0-0.37,0-0.68,0-0.99
			c0-0.97-0.01-1.94,0-2.9c0.03-1.97,1.26-3.04,2.9-3.8c0.65,0,1.29,0,1.94,0c2.28,0.93,3.13,2.67,2.91,5.06
			c-0.08,0.88-0.01,1.77-0.01,2.64c5.95,0,11.74,0,17.62,0c0-0.37,0-0.68,0-0.99c0-0.97-0.01-1.94,0-2.9
			c0.03-1.97,1.26-3.04,2.9-3.8c0.65,0,1.29,0,1.94,0c2.28,0.93,3.13,2.67,2.91,5.06c-0.08,0.88-0.01,1.77-0.01,2.69
			c1.86,0,3.57-0.05,5.28,0.01c6.21,0.23,10.78,3.16,13.62,8.64c0.78,1.51,1.1,3.25,1.63,4.89C99.85,30.16,99.85,38.48,99.85,46.81z
			"
    />
    <path
      d="M99.85,78.55c-0.26,1.19-0.45,2.4-0.78,3.57c-2.66,9.22-8.7,15.07-18.02,17.04C68.56,101.8,56.68,93.98,53.6,81.5
			c-2.53-10.25,2.77-21.64,12.33-26.17c8.92-4.23,17.44-3.29,25.21,2.74c4.86,3.77,7.6,8.91,8.49,15.01
			c0.04,0.28,0.14,0.55,0.21,0.83C99.85,75.46,99.85,77,99.85,78.55z M76.42,91.91c8.56,0,15.63-7.04,15.68-15.63
			c0.05-8.57-7.08-15.73-15.68-15.73c-8.56,0-15.63,7.04-15.68,15.63C60.69,84.75,67.83,91.91,76.42,91.91z"
    />
    <path
      d="M28.8,41.23c-0.01,2.14-1.73,3.83-3.88,3.83c-2.18,0-3.88-1.75-3.85-3.95c0.03-2.13,1.77-3.8,3.93-3.78
			C27.13,37.35,28.81,39.07,28.8,41.23z"
    />
    <path
      d="M45.64,41.19c0,2.15-1.7,3.86-3.84,3.87c-2.19,0.01-3.91-1.71-3.89-3.91c0.02-2.14,1.73-3.83,3.89-3.82
			C43.94,37.33,45.64,39.04,45.64,41.19z"
    />
    <path
      d="M62.48,41.23c-0.01,2.14-1.73,3.83-3.88,3.83c-2.18,0-3.88-1.75-3.85-3.95c0.03-2.13,1.77-3.8,3.93-3.78
			C60.81,37.35,62.49,39.07,62.48,41.23z"
    />
    <path
      d="M75.46,37.33c2.15,0,3.85,1.7,3.86,3.85c0.01,2.19-1.72,3.91-3.92,3.89c-2.14-0.02-3.82-1.74-3.81-3.89
			C71.6,39.02,73.31,37.32,75.46,37.33z"
    />
    <path
      d="M28.8,58.08c-0.01,2.14-1.73,3.83-3.88,3.82c-2.18-0.01-3.88-1.75-3.85-3.96c0.03-2.13,1.77-3.8,3.93-3.78
			C27.13,54.19,28.81,55.92,28.8,58.08z"
    />
    <path
      d="M41.78,54.17c2.15,0,3.85,1.7,3.86,3.85c0.01,2.19-1.72,3.9-3.92,3.89c-2.14-0.02-3.82-1.74-3.81-3.89
			C37.92,55.86,39.63,54.17,41.78,54.17z"
    />
    <path
      d="M24.97,78.74c-2.16,0.01-3.88-1.67-3.9-3.81c-0.02-2.2,1.69-3.93,3.88-3.92c2.15,0.01,3.85,1.71,3.85,3.86
			C28.8,77.02,27.11,78.73,24.97,78.74z"
    />
    <path
      d="M45.64,74.91c-0.01,2.14-1.73,3.83-3.88,3.83c-2.18-0.01-3.88-1.75-3.85-3.95c0.03-2.13,1.77-3.8,3.93-3.78
			C43.97,71.03,45.65,72.76,45.64,74.91z"
    />
    <path
      d="M80.29,72.37c0.7,0,1.3-0.02,1.91,0c2.11,0.07,3.73,1.79,3.71,3.92c-0.02,2.07-1.65,3.76-3.72,3.8
			c-1.97,0.04-3.94,0.04-5.9,0c-2.04-0.04-3.68-1.67-3.71-3.71c-0.04-2.74-0.04-5.48,0-8.22c0.03-2.11,1.77-3.74,3.89-3.73
			c2.07,0.01,3.77,1.64,3.82,3.71C80.32,69.51,80.29,70.89,80.29,72.37z"
    />
  </g>
