
<b-button-group class="tips">
  <b-button
    v-for="tip in tipPresentAmounts"
    :key="tip.id"
    :class="[selectedTip && tip.id === selectedTip.id ? 'primary' : 'btn-light']"
    class="mr-2 circle border mr-xs-1"
    @click="selectOption(tip)"
  >
    {{ tip.value }}%
  </b-button>

  <b-button v-if="selectedOpt"
    :class="[selectedTip && customAmount.id === selectedOpt.id ? 'primary active' : 'btn-light']"
    class="custom-amt border circle px-0"
    pill
    @click="selectOption(customAmount)"
  >
    Custom
  </b-button>

  <transition name="slide-open">
    <b-form-input
      v-show="isCustomAmount"
      id="custom-amt"
      v-model="customAmount.value"
      autofocus
      class="text-center p0 m0 custom-amt-input"
      step="0.01"
      @keyup="updateTip"
    />
  </transition>
</b-button-group>
