<template>
  <div class="fees">
    <div v-show="title" class="title-block">
      <span class="title"
        >{{ title }}<span class="sizes"> ({{ sizes }})</span></span
      >
    </div>
    <div v-for="fee in fees" :key="fee.destination" class="fee">
      <span>
        {{ fee.type === 'flat' ? `Flat Rate: ` : `${fee.destination} miles` }}
      </span>
      <span v-if="!fee.isPricePerMile">
        ${{ fee.price.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}
      </span>
      <span v-else>
        ${{
          fee.price.toLocaleString('en-US', { minimumFractionDigits: 2 })
        }}
        per mile over {{ fee.overMilage }} miles
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fees: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    sizes: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

.fees {
  font-family: $main-font;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.title {
  font-weight: bold;
  text-align: left;
}
.title-block {
  display: flex;
}

.sizes {
  font-weight: normal;
  text-decoration: solid;
}
.fee {
  display: flex;
  width: 320px;
  justify-content: space-between;
}
</style>
