<template>
  <div>
    <div class="spacer" />
    <transition name="slide-fade" :duration="{ enter: 800, leave: 200 }">
      <b-alert
        v-if="show"
        v-model="alert"
        class="position-fixed bottom-bar pl-4 pr-2 py-2"
        variant="black"
      >
        <!-- <b-container class="px-0 bottom-bar-contents"> -->
        <b-row
          class="justify-content-between cart-info-label pl-2 pr-0"
          align-v="center"
        >
          <b-col v-if="fetchingQuote" cols="auto">
            <b-spinner class="ml-2" small variant="light" />
          </b-col>
          <b-col v-else cols="auto" class="sticky-footer-text">
            <b-row>
              {{ itemsCount }}
              <span class="ml-1 quantity-text"
                >item<span v-if="itemsCount > 1">s</span>:</span
              >
              <span
                v-if="quote && parseFloat(quote.subtotal) > 0 && !fetchingQuote"
                class="ml-1"
                >&nbsp; <span class="currency-symbol">$</span>
                <number
                  :to="quote.subtotal"
                  :duration="0.12"
                  :format="formatPriceToCompact"
                />
                <span class="currency-symbol">USD</span>
                <b-icon-info-circle-fill
                  v-if="!meetsMinimumSubtotal"
                  v-b-popover.hover.top="
                    `You need a subtotal of at least \$${vendor.minimum_order_subtotal} to proceed!`
                  "
                  class="info-cart"
                  scale="0.6"
                />
                <span v-else class="ml-1"></span>
              </span>
            </b-row>
            <b-row
              v-if="region && region.max_weight_per_order_grams"
              :class="
                'region-requirements d-flex align-items-center' +
                (meetsMaxWeight ? '' : 'text-danger')
              "
            >
              {{ totalOrderWeightGrams ? totalOrderWeightGrams : 0
              }}<span class="green green--text mr-1">g</span> /<span
                class="ml-1"
              />{{ region.max_weight_per_order_grams
              }}<span class="green green--text">g</span>
              <b-icon-info-circle-fill
                v-b-popover.hover.top="
                  `Orders must be ${region.max_weight_per_order_grams}g or under`
                "
                scale="0.6"
              />
            </b-row>
          </b-col>
          <b-col cols="auto">
            <b-btn class="btn" size="lg" to="/cart">Proceed</b-btn>
          </b-col>
        </b-row>
        <!-- </b-container> -->
      </b-alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { routeMap } from '~/routes';
import {
  bankersRound,
  getCartTotalWeightGrams,
  maximumOrderWeightCheck,
  minimumSubtotalCheck,
} from '^/utilities';
import AnimatedNumber from 'animated-number-vue';
import { formatPriceToCompact } from '%/formatPrice';

export default {
  name: 'StickyFooter',
  data() {
    return {
      loading: false,
      alert: true,
      forceCompUpdate: 0,
      hiddenOnPages: [
        routeMap.cart.name,
        routeMap.validate.name,
        routeMap.checkout.name,
      ],
    };
  },
  methods: {
    ...mapActions(['updateQuote']),
    handleRedirect() {
      this.$router.push('/cart').catch(() => {});
    },
    formatPriceToCompact
  },
  watch: {
    cartInteractions() {
      if (this.show) this.updateQuote();
    },
    cartProducts() {
      if (this.show) this.updateQuote();
    },
  },
  computed: {
    ...mapGetters([
      'cartProducts',
      'user',
      'region',
      'cartInteractions',
      'quote',
      'fetchingQuote',
      'orderRequirementsMet',
      'vendor',
    ]),
    show() {
      return (
        this.cartProducts &&
        this.cartProducts.length > 0 &&
        !this.hiddenOnPages.includes(this.$route.name)
      );
    },
    meetsMinimumSubtotal() {
      return minimumSubtotalCheck(this.vendor, this.quote);
    },
    meetsMaxWeight() {
      return maximumOrderWeightCheck(this.region, this.cartProducts);
    },
    totalOrderWeightGrams() {
      return bankersRound(getCartTotalWeightGrams(this.cartProducts));
    },
    itemsCount() {
      return this.cartProducts.reduce(
        (a, product) => a + product.quantityOrdered,
        0
      );
    },
  },
  components: {
    AnimatedNumber,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

@include buttons;

.bottom-bar {
  bottom: 0;
  width: 100%;
  border-radius: 0 !important;
  z-index: 999;
  background-color: #000000;
  color: whitesmoke;
  border: 0;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  margin: 0 !important;
  font-size: 110%;

  .btn {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 120%;
  }

  .sticky-footer-text {
    font-size: 140%;
  }

  .bottom-bar-contents {
    padding-bottom: env(safe-area-inset-bottom);

    span.green.green--text {
      color: darkgrey;
      font-size: 80%;
      margin-top: 2px;
      margin-left: 0.125em;
    }
  }
}

.cart-info-label {
  font-family: 'Staatliches', cursive;
  font-size: 1.125em;
  display: flex;
  align-items: center;

  .info-cart {
    margin-left: 2px;
    margin-bottom: -2px;
    font-size: 120%;
    color: ghostwhite;
    vertical-align: center;
  }

  .currency-symbol {
    color: $secondary-trim;
    font-size: 70%;
    margin-right: 0.1em;
    margin-left: 0.1em;
  }

  .quantity-text {
    color: whitesmoke;
    font-size: 70%;
    margin-top: 6px;
    margin-right: 0.1em;
  }
}

.spacer {
  width: 100%;
  height: 50px;
}

.region-requirements {
  font-family: 'Staatliches', cursive;
  font-size: small;
}

.slide-fade-enter-active {
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}
</style>
