
  <path
    d="M91.8,100.19c-6.84,0-13.67,0-20.51,0c-1.64-0.83-2.13-2.18-2.13-3.98c0.05-9.94,0.03-19.88,0.02-29.82
			c0-0.34-0.04-0.67-0.07-1.01c-0.25,0.52-0.29,1.03-0.36,1.53c-0.47,3.45-2.25,5.97-5.42,7.42c-0.65,0.3-0.81,0.64-0.81,1.3
			c0.02,6.95-0.02,13.9,0.04,20.86c0.01,1.77-0.55,3.01-2.21,3.7c-6.77,0-13.54,0-20.31,0c-1.66-0.68-2.22-1.93-2.21-3.69
			c0.06-6.91,0.02-13.83,0.02-20.74c0-0.36-0.04-0.71-0.07-1.07c-0.08,0-0.17,0.01-0.25,0.01c-0.02,0.3-0.03,0.6-0.06,0.9
			c-0.32,3.72-2.06,6.51-5.48,8.12c-0.65,0.31-0.81,0.65-0.81,1.3c0.03,3.7-0.05,7.4,0.04,11.1c0.04,1.81-0.43,3.23-2.12,4.07
			c-6.84,0-13.67,0-20.51,0c-1.64-0.76-2.2-2.05-2.16-3.83c0.08-4.08,0.03-8.17,0.03-12.27c-2.01-0.76-3.69-1.92-4.75-3.75
			c-0.64-1.1-1.01-2.35-1.51-3.54c0-2.21,0-4.42,0-6.63c0.05-0.12,0.14-0.23,0.15-0.35c0.92-6.27,4.2-10.92,9.71-14.02
			c0.13-0.07,0.22-0.2,0.33-0.29C6.3,50.19,8.09,44.1,11.46,40.87c3.6-3.46,9.39-3.92,13.47-1.03c2.18,1.55,3.61,3.64,4.29,6.2
			c0.93,3.48,0.2,6.66-1.98,9.45c1.45,1.01,2.83,1.95,4.44,3.07c-0.94-9.35,1.47-17.05,10.09-21.76c-3.47-6.02-2.9-11.48,1.55-15.17
			c4-3.32,9.78-3.31,13.74-0.01c3.36,2.8,6.01,9.23,1.59,15.11c1.4,0.96,2.79,1.92,4.26,2.94c0-1.81-0.04-3.53,0.01-5.25
			c0.15-5.13,1.97-9.57,5.71-13.12c1.35-1.28,2.93-2.3,4.42-3.45c-3.32-5.75-2.88-10.91,1.32-14.73c3.61-3.29,9.21-3.71,13.17-0.97
			c2.17,1.5,3.62,3.53,4.36,6.05c1.05,3.57,0.3,6.84-1.95,9.79c5.06,2.78,8.35,6.8,9.73,12.31c0.19,0.75,0.34,1.5,0.51,2.25
			c0,8.51,0,17.02,0,25.54c-0.07,0.17-0.16,0.34-0.21,0.51c-0.75,2.91-2.44,5.06-5.19,6.3c-0.67,0.3-0.88,0.64-0.88,1.36
			c0.03,10.03-0.01,20.06,0.04,30.09C93.97,98.14,93.45,99.44,91.8,100.19z M88.06,94.31c0-0.5,0-0.88,0-1.27
			c0-9.97,0-19.94,0-29.91c0-1.88,1.05-3.09,2.78-3.26c2.41-0.24,3.49-1.44,3.49-3.9c0-6.66,0.01-13.32-0.01-19.97
			c0-0.94-0.05-1.88-0.16-2.81c-0.59-4.71-2.92-8.26-7.36-10.1c-4.67-1.94-9.2-1.45-13.26,1.64c-3.39,2.57-4.69,6.21-4.71,10.33
			c-0.04,7.01-0.01,14.03-0.01,21.04c0,0.36,0.02,0.72,0.08,1.07c0.22,1.4,1.42,2.53,2.83,2.68c2.54,0.28,3.38,1.22,3.38,3.79
			c0,9.81,0,19.62,0,29.42c0,0.41,0,0.81,0,1.24C79.5,94.31,83.72,94.31,88.06,94.31z M56.66,94.31c0-0.48,0-0.86,0-1.25
			c0-6.82,0-13.64,0-20.46c0-2.05,1.06-3.18,3.12-3.36c1.93-0.17,3.14-1.46,3.14-3.41c0.01-4.13,0.04-8.25,0-12.38
			c-0.07-6.87-4.65-11.86-11.49-12.59c-7.43-0.79-13.78,4.73-13.92,12.19c-0.08,4.32-0.03,8.64-0.01,12.96
			c0.01,1.76,1.28,3.06,3.02,3.22c2.25,0.2,3.23,1.28,3.23,3.55c0,6.79,0,13.58,0,20.37c0,0.38,0,0.75,0,1.17
			C48.08,94.31,52.3,94.31,56.66,94.31z M25.26,94.31c0-3.99,0.06-7.88-0.02-11.77c-0.05-2.33,1.01-3.81,3.39-3.95
			c1.63-0.1,2.79-1.3,2.86-2.92c0.08-1.78,0.14-3.59-0.09-5.34c-0.79-5.94-5.78-10.38-12-10.83c-5.58-0.41-11.23,3.48-12.72,9.06
			c-0.54,2.03-0.52,4.22-0.6,6.35c-0.09,2.26,1.06,3.47,3.31,3.76c2.01,0.26,2.93,1.28,2.94,3.29c0.01,3.77,0,7.53,0,11.3
			c0,0.34,0,0.69,0,1.06C16.68,94.31,20.9,94.31,25.26,94.31z M76.71,11.12c0.01,2.84,2.1,4.91,4.94,4.88
			c2.74-0.03,4.85-2.17,4.83-4.92c-0.01-2.59-2.26-4.81-4.88-4.83C78.92,6.22,76.7,8.43,76.71,11.12z M50.21,34.76
			c2.8-0.01,4.86-2.05,4.88-4.84c0.02-2.67-2.2-4.86-4.91-4.85c-2.67,0.01-4.86,2.15-4.88,4.78C45.29,32.68,47.37,34.77,50.21,34.76
			z M23.68,48.73c0.02-2.66-2.11-4.85-4.76-4.88c-2.71-0.03-4.97,2.12-5.01,4.78c-0.04,2.76,2.14,4.97,4.9,4.97
			C21.56,53.6,23.65,51.51,23.68,48.73z"
  />
