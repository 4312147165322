
<b-container class="no-gutters">
  <b-row align-h="center">
    <b-col v-if="interactive" align="center">
      <template v-if="schedule.length > 0">
        <p>(Tap to see our hours)</p>
        <b-collapse id="collapse" class="mt-2">
          <b-card>
            <table
              cellpadding="6"
              class="hours"
              v-html="scheduleHtml"
            ></table>
          </b-card>
        </b-collapse>
      </template>
    </b-col>
    <div v-else>
      <table cellpadding="6" class="hours" v-html="scheduleHtml"></table>
    </div>
  </b-row>
</b-container>
