
<div>
  <multiselect
    v-model="selectedOption"
    :options="options"
    :multiple="false"
    :close-on-select="true"
    placeholder="Begin typing your address."
    :searchable="true"
    :loading="isLoading"
    :filter-results="false"
    v-bind="$attrs"
    @search-change="handleSearch"
    v-on="$listeners"
  >
    <div class="address-no-options">
      <span class="error-message-custom" v-if="errorMessage">{{ errorMessage }}</span>
      <span v-else>The list is empty</span>
    </div>
  </multiselect>
</div>
