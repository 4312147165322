
<b-row no-gutters>
  <b-col cols="12" class="modifier-header py-2 px-3 d-flex flex-wrap align-center"
    :class="{ 'bottom-border': !collapse }">
    <h3 class="mb-0 w-100 text-left">
      {{ value.name }}
      <b-btn class="float-right btn toggle-btn" @click="toggle">
        <b-icon-caret-right-fill v-if="!collapse" size="md" />
        <b-icon-caret-down-fill v-else size="md" />
      </b-btn>
    </h3>
    <span v-if="collapse || !valid" :class="{ 'text-error': !valid }">
      <template v-if="value.min_items === value.max_items">Please select {{ value.min_items }} item<template
          v-if="value.min_items > 1">s</template></template>
      <template v-else-if="value.min_items >= 0 && value.max_items !== null">
        Please select
        <template v-if="value.min_items > 0">at least {{ value.min_items }} or</template>
        up to {{ value.max_items }} item<template v-if="value.max_items > 1">s</template>
      </template>
      <template v-else>Select as many as you like</template>
    </span>
    <span v-else-if="selectedModifiers > 0">
      {{ selectedModifiers }} selected
      <span v-if="parseFloat(modifierSubtotal) > 0">:
        <currency primary-font-size="1rem" secondary-font-size="0.9rem" prepend="$" :currency="modifierSubtotal" />
      </span>
    </span>
    <span v-else> None selected </span>
  </b-col>
  <b-col cols="12">
    <b-collapse v-model="collapse">
      <div v-for="(item, i) in value.items" :key="i" class="col-12">
        <modifier-item v-model="value.items[i]" :class="{ 'bg-grey': i % 2 === 0 }"
          :disabled="maxItemsReached || shouldBeDisabledBaseOnBinary(item)" />
      </div>
    </b-collapse>
  </b-col>
</b-row>
