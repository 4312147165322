<template>
  <div class="block">
    <slot name="label" />
    <vue-slider
      v-model="currentValue"
      class="slider"
      :tooltip="false"
      process-class="process-bar"
      :bg-style="{
        backgroundColor: styles.lightGray,
        cursor: 'pointer',
      }"
      :process-style="{
        backgroundColor: personalization.theme_color_1,
        cursor: 'pointer',
      }"
      :marks="true"
      :data="data"
      :min="min"
      :max="max"
    >
      <template #dot><div class="dot"></div></template>
      <template #piecewise><div class="piecewise"></div></template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import styles from '$/variables.module.scss';

export default {
  components: {
    VueSlider,
  },

  data() {
    return { styles };
  },

  model: {
    prop: 'value',
    event: 'updateValue',
  },

  props: {
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    data: { type: Array, default: undefined },
    value: { type: [String, Number], required: true },
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('updateValue', newValue);
      },
    },
    personalization() {
      return JSON.parse(localStorage.getItem('personalization'));
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/variables.module.scss';

.block {
  margin-top: 24px;
}

.slider {
  padding: 0 !important;
}

.dot {
  width: 16px;
  height: 16px;
  background-color: var(--primary);
  border-radius: 10px;
}

.value {
  color: var(--primary);
}
</style>
