<template>
  <b-row align-h="center" class="pt-2 pb-1">
    <b-col :cols="cols" class="pr-1">
      <b-form-input
        v-model.number="value.quantityOrdered"
        :max="value.weight"
        min="1"
        step="1"
        type="number"
      />
    </b-col>
    <b-col :class="removable ? 'px-0' : 'pl-0'" :cols="cols">
      <b-form-select
        v-model="value.orderedWeight"
        :disabled="disableWeightSelect"
        :options="selectableSmartWeightOptions"
      />
    </b-col>
    <b-col v-if="removable" class="pl-0" cols="2">
      <b-button class="ml-1" variant="info" @click="removeFromCart(value)"
        >Remove</b-button
      >
    </b-col>
  </b-row>
</template>
<script>
import { removeFromCart } from '^/utilities';
import { smartWeightOptions } from '~/enums/smartWeightOptions';

export default {
  name: 'SmartWeightControls',
  props: ['value', 'removable'],
  computed: {
    cols() {
      return this.removable ? 5 : 6;
    },
    selectableSmartWeightOptions() {
      return smartWeightOptions.filter(
        (option) => option.value >= this.value.sold_by_weight
      );
    },
    disableWeightSelect() {
      return (
        this.value.sold_by_weight ===
        smartWeightOptions[smartWeightOptions.length - 1].value
      );
    },
  },
  methods: {
    removeFromCart,
  },
};
</script>

<style scoped></style>
