
<b-container class="reset-password">
  <b-row class="py-5">
    <div v-if="resetNotify" class="col-12">
      <h1 class="display-4 align-center text-center">Oops</h1>
      <p class="text-muted align-center text-center">
        We'll send you an email with reset instructions.
      </p>
    </div>
    <div v-else class="col-12">
      <h1 class="display-4 align-center text-center">
        Choose a new password
      </h1>
      <p class="text-muted align-center text-center">
        We'll send you back to the shop when we're through here.
      </p>
    </div>
  </b-row>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form
      ref="form"
      class="reset-form"
      @submit.prevent="handleSubmit(process)"
    >
      <b-row align-h="center">
        <template>
          <b-col align-self="start" md="4">
            <b-form-group label="Email">
              <b-form-input
                v-model.trim="username"
                :disabled="submitted"
                required
                type="email"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </template>
        <template v-if="!resetNotify">
          <b-col md="4">
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true }"
              name="Password"
            >
              <b-form-group label="Password">
                <b-input-group>
                  <b-form-input
                    v-model.trim="password"
                    :disabled="submitted"
                    :state="validateState(validationContext)"
                    :aria-describedby="`password-feedback-${_uid}`"
                    required
                    :type="passwordType"
                    @keypress.enter="process"
                    >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline" @click="togglePasswordVisibility">
                      <b-icon :icon="passwordType === 'password' ? 'eye' : 'eye-slash'"  />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :id="`password-feedback-${_uid}`"
                  >Please enter a password</b-form-invalid-feedback
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, is: password }"
              name="Confirm Password"
            >
              <b-form-group label="Confirm Password">
                <b-form-input
                  v-model.trim="confirmPassword"
                  :disabled="submitted"
                  :state="validateState(validationContext)"
                  :aria-describedby="`confirm-password-feedback-${_uid}`"
                  required
                  :type="passwordType"
                  @keypress.enter="process"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  :id="`confirm-password-feedback-${_uid}`"
                  >Your passwords must match</b-form-invalid-feedback
                >
              </b-form-group>
            </validation-provider>
          </b-col>
        </template>
      </b-row>
      <b-row align-h="center" class="mt-5">
        <b-button :disabled="submitted" class="btn-cta" type="submit">
          {{ resetNotify ? 'Send Me a Reset Email' : 'Reset Password' }}
        </b-button>
      </b-row>
    </b-form>
  </validation-observer>
</b-container>
