
<svg
  viewBox="0 0 100 100"
  :width="size"
  :height="size"
  :aria-labelledby="iconName"
  role="presentation"
>
  <g :fill="iconColor" :class="{ error: error }">
    <slot />
  </g>
</svg>
