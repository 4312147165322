
  <g>
    <path
      d="M100,50.03c0,10.57,0,21.13,0,31.7c0,9.5-6.54,16.93-15.96,18.12c-0.66,0.08-1.34,0.06-2.01,0.06
		c-21.35,0-42.71,0.01-64.06,0C7.89,99.91,0.01,92.02,0,81.95C0,60.59,0,39.24,0,17.88C0.01,7.8,7.89-0.08,17.97-0.08
		c21.35-0.01,42.71-0.01,64.06,0c10.08,0,17.96,7.88,17.96,17.96C100,28.6,100,39.31,100,50.03z M8.48,49.92
		c0,10.23-0.01,20.47,0,30.7c0.01,6.32,4.51,10.81,10.84,10.81c20.43,0.01,40.86,0.01,61.3,0c6.4,0,10.89-4.47,10.89-10.87
		c0.01-20.43,0.01-40.86,0-61.3c0-6.39-4.49-10.86-10.9-10.87c-20.43-0.01-40.86-0.01-61.3,0.01c-0.96,0-1.95,0.1-2.88,0.33
		C11.6,9.93,8.5,13.98,8.49,19.11C8.47,29.38,8.48,39.65,8.48,49.92z"
    />
    <path
      d="M49.99,14.29c11.18-0.01,20.77,4.13,29.13,11.33c1.97,1.69,3.77,3.59,5.54,5.49c1.65,1.77,1.41,3.93-0.5,5.44
		c-5.21,4.13-10.43,8.26-15.67,12.36c-2.1,1.64-4.25,1.23-5.67-1.04c-2.62-4.2-6.34-6.84-11.26-7.3
		c-6.18-0.59-10.82,2.18-14.13,7.31c-1.13,1.75-2.58,2.5-4.27,2c-0.52-0.15-1.02-0.45-1.45-0.79c-5.23-4.11-10.46-8.23-15.67-12.35
		c-1.77-1.4-2.17-3.59-0.79-5.21c5.66-6.65,12.4-11.83,20.67-14.85C40.45,15.03,45.17,14.32,49.99,14.29z M66.76,41.21
		c3.42-2.7,6.73-5.32,10.05-7.94c-15.39-15.94-38.48-15.73-53.63,0c3.34,2.64,6.68,5.27,10.06,7.94c3.54-4.14,7.95-6.64,13.19-7.66
		c0-1.7-0.01-3.3,0-4.9c0.01-2.18,1.5-3.73,3.57-3.73c2.06,0,3.55,1.56,3.56,3.73c0.01,1.63,0,3.26,0,4.9
		C58.87,34.59,63.23,37.09,66.76,41.21z"
    />
  </g>
