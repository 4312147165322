<template>
  <b-container fluid>
    <b-row>
      <Map
        v-if="!loading && vendorRegion && region && polygon.coordinates.length > 0"
        :height="mapHeight"
        :polygon="polygon"
        :latitude="Number(vendorRegion.address.latitude)"
        :longitude="Number(vendorRegion.address.longitude)"
        :with-controls="true"
        :zoom="Number(region.polygon_zoom_factor)"
      ></Map>
    </b-row>
    <b-row class="mt-4 mb-2 text-center">
      <b-col cols="12">
        <h2>{{ region.name }}</h2>
      </b-col>
      <b-col cols="12">
        <p>Delivery Hours</p>
      </b-col>
    </b-row>
    <b-row>
      <Schedule v-if="$store.getters.vendorRegion" :schedule="$store.getters.vendorRegion.schedules" :interactive="false"></Schedule>
    </b-row>
    <hr class="w-75 my-4" />
    <b-row align-h="center" align-v="center" class="text-center mb-4 fees">
      <h3>
        Delivery Fee{{
          vendorRegion.delivery_method === 'SkipCart' ||
          vendorRegion.delivery_method === 'Roadie'
            ? 's'
            : ''
        }}
      </h3>
      <div>
        <div v-if="vendorRegion.delivery_method === 'SkipCart'">
          <delivery-fee :fees="fees.deliveryFeesSkipcart" />
        </div>
        <div v-else-if="vendorRegion.delivery_method === 'Roadie'">
          <delivery-fee
            title="Small"
            :fees="fees.deliveryFeesRoadie.small.pricing"
            :sizes="fees.deliveryFeesRoadie.small.sizes"
          />
          <delivery-fee
            title="Large"
            :fees="fees.deliveryFeesRoadie.large.pricing"
            :sizes="fees.deliveryFeesRoadie.large.sizes"
          />
        </div>
        <div v-else>
          <delivery-fee :fees="fees.flatRate" />
        </div>
      </div>
    </b-row>
    <hr v-if="closures.length" class="w-75 my-4" />
    <b-row v-if="closures.length" class="text-center mb-0">
      <b-col cols="12">
        <p>{{ region.name }} Closures</p>
      </b-col>
      <b-col v-for="c in closures" :key="c.id" cols="12" class="mt-2">
        <h6 class="d-inline">{{ formatDate(c.start) }}</h6>
        <template v-if="formatDate(c.start) !== formatDate(c.end)">
          &ndash;
          <h6 class="d-inline">{{ formatDate(c.end) }}</h6></template
        >
        <br />
        <span class="text-muted small"
          >{{ formatTime(c.start) }} &ndash; {{ formatTime(c.end) }}</span
        >
        <p>{{ c.short_description }}</p>
      </b-col>
    </b-row>
    <hr class="w-75 mt-2 mb-4" />
    <b-row class="mb-2 text-center">
      <b-col cols="12">
        <p>
          Questions? Get support
          <a target="_blank" :href="`mailto:${vendorRegion.operator_email}`">here</a>.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Map from '@/MapPage/Map.vue';
import Schedule from '@/MapPage/Schedule.vue';
import DeliveryFee from '@/MapPage/DeliveryFee.vue';
import {
  SKIPCART_DELIVERY_FEES,
  ROADIE_DELIVERY_FEES,
  FLAT_RATE,
} from '@/MapPage/deliveryFees.js';
import { mapGetters } from 'vuex';
import { axios } from '^/axios';
import moment from 'moment';
import { configuration } from '~/configuration';

export default {
  name: 'MapPage',
  components: {
    Map,
    Schedule,
    DeliveryFee,
  },
  data: () => ({
    configuration,
    polygon: {
      type: 'Polygon',
      coordinates: [],
    },
    closures: [],
    loading: false,
    fees: {
      deliveryFeesSkipcart: SKIPCART_DELIVERY_FEES,
      deliveryFeesRoadie: ROADIE_DELIVERY_FEES,
      flatRate: FLAT_RATE,
    },
  }),
  async created() {
    this.loading = true;

    Promise.all([this.getPolygon(), this.getClosures()])
      .then((response) => {
        this.polygon = response[0];
        this.closures = response[1];
      })
      .catch((error) => {
        this.$store.dispatch('alertError', error.message);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getPolygon() {
      return axios.get(`region/${this.region.uuid}/polygon`);
    },
    getClosures() {
      return axios.get(`region/${this.region.uuid}/vendor/${this.vendor.uuid}/closure`);
    },
    formatDate(date) {
      return moment(date).format('dddd, MMMM Do');
    },
    formatTime(date) {
      return moment(date).format('h:mma');
    },
  },
  computed: {
    ...mapGetters(['region', 'vendor', 'vendorRegion']),
    mapHeight() {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      return vw < 360 ? vw : 360;
    },
  },
};
</script>

<style scoped>
a{
  text-decoration: underline;
}
.fees {
  display: flex;
  flex-direction: column;
}
</style>
