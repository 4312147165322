//TODO: Organize store
import { debounce } from 'lodash';
import { axios } from '^/axios';
import { maximumOrderWeightCheck, minimumSubtotalCheck } from '^/utilities';
import { tipEnum } from '~/enums/tip';
import { orderTypesEnum } from '~/enums/orderStatuses'

export default {
  state: {
    regionId: null,
    regions: null,
    user: null,
    ageRestrictedCheck: null,
    quote: {
      promotion: {
        min_tip: null,
      },
      discount_codes: null,
      subtotal: 0,
      gift_card_credit: 0,
      vendor_tax: 0,
      courier_tax: 0,
      fees: 0,
      discount: 0,
      tax: 0,
      tip: 0,
      total: 0,
    },
    fetchingQuote: false,
    cart: {
      products: [],
      code: null,
      gift_card_credit: 0.0,
      tip: {
        type: tipEnum.FIXED,
        value: 0.0,
      },
      checks: {
        modifiers: [],
      },
    },
    cartInteractions: 0,
    orderRequirementsMet: null,
    alert: {
      text: String(),
      show: false,
      variant: String(),
    },
    order: {
      address: {},
      type: undefined,
      shippingRateId: undefined,
    },
    measurement: {
      weight: 'lbs',
      length: 'inch',
    },
    activeVendor: null,
    giftCards: {
      balance: 0
    }
  },
  mutations: {
    setActiveVendor: (state, activeVendor) => {
      state.activeVendor = activeVendor;
    },
    regionId: (state, regionId) => {
      state.alert.show = false;
      state.regionId = regionId;
    },
    quote: (state, quote) => (state.quote = quote),
    orderRequirementsMet: (state, orderRequirementsMet) =>
      (state.orderRequirementsMet = orderRequirementsMet),
    fetchingQuote: (state, fetchingQuote) =>
      (state.fetchingQuote = fetchingQuote),
    regions: (state, regions) => (state.regions = regions),
    user: (state, user) => (state.user = user),
    clearCart: (state) => {
      state.cart.products = [];
      state.cart.code = null;
      state.cart.tip = { type: tipEnum.FIXED, value: 0.0 };
      state.cart.discount_code = ''
      state.cart.gift_card_credit = undefined
    },
    toggleModifierValidation: (state, valid) =>
      (state.cart.checks.modifiers = valid),
    ageRestrictedCheck: (state, ageRestrictedCheck) =>
      (state.ageRestrictedCheck = ageRestrictedCheck),
    addDiscountCode(state, code) {
      state.cart.code = code.toUpperCase();
    },
    setTip: (state, tip) => {
      if(state.order.type == orderTypesEnum.DELIVERY && state.activeVendor.settings.enable_tipping){
        state.cart.tip = tip;
      }
      else if(state.order.type == orderTypesEnum.PICKUP && state.activeVendor.settings.enable_tipping_pickup){
        state.cart.tip = tip;
      }    
      else{
        state.cart.tip = { id: 4, value: 0, type: tipEnum.FIXED };
      }
    },
    setGiftCardCredit: (state, gift_card_credit) => {
      state.cart.gift_card_credit = gift_card_credit;
     },
    setOrderAddress: (state, address) => {
      localStorage.setItem('state.user', JSON.stringify(state.user));
      return (state.order.address = address);
    },
    setOrderType: (state, orderType) => {
      return (state.order.type = orderType);
    },
    setShippingRateId: (state, shippingRateId) => {
      return (state.order.shippingRateId = shippingRateId);
    },
    addProductToCart(state, p) {
      const index = state.cart.products.findIndex((x) => x.uuid === p.uuid);
      if (
        !p.fixed_weight_enabled ||
        (p.fixed_weight_enabled && p.fixed_weight_prices.length > 0)
      ) {
        state.cart.products.push({ ...p });
      } else {
        const proposedTotal =
          state.cart.products[index].quantityOrdered + p.quantityOrdered;
        if (
          (!p.smart_weight_enabled && proposedTotal <= p.quantity) ||
          (p.smart_weight_enabled && proposedTotal < p.weight)
        ) {
          state.cart.products[index].quantityOrdered += p.quantityOrdered;
        }
      }
      state.cartInteractions++;
    },
    updateProductInCart(state, product) {
      const index = state.cart.products.findIndex(
        (x) => x.uuid === product.uuid
      );

      if (index !== -1) {
        state.cart.products[index] = product;
      }
      state.cartInteractions++;
    },
    removeProductFromCart(state, product) {
      const index = state.cart.products.findIndex(
        (x) => x.uuid === product.uuid
      );

      if (index !== -1) {
        state.cart.products.splice(index, 1);
      }

      state.cartInteractions++;
    },
    removeProductsFromCart(state) {
      state.cart.products.splice(0, state.cart.products.length)

      state.cartInteractions++;
    },
    showAlert(state, alert) {
      state.alert = {};
      state.alert.text = alert.text;
      state.alert.variant = alert.variant;
      state.alert.show = alert.show;
    },
    deserializeStore(state) {
      if (typeof Storage !== 'undefined') {
        const regionId = localStorage.getItem('state.regionId');
        const cart = localStorage.getItem('state.cart');
        const user = localStorage.getItem('state.user');
        const measurement = localStorage.getItem('state.measurement');
        const quote = localStorage.getItem('state.quote');
        const giftCards = localStorage.getItem('state.giftCards');
        const ageRestrictedCheck = localStorage.getItem(
          'state.ageRestrictedCheck'
        );

        if (regionId) state.regionId = JSON.parse(regionId);

        if (giftCards) state.giftCards = JSON.parse(giftCards);

        if (cart) state.cart = JSON.parse(cart);

        if (user) state.user = JSON.parse(user);

        if (measurement) state.measurement = JSON.parse(measurement);

        if (quote) state.quote = JSON.parse(quote);

        if (ageRestrictedCheck)
          state.ageRestrictedCheck = JSON.parse(ageRestrictedCheck);
      }
    },
    setLengthMeasurement(state, value) {
      state.measurement.length = value;
      localStorage.setItem(
        'state.measurement',
        JSON.stringify(state.measurement)
      );
    },
    setWeightMeasurement(state, value) {
      state.measurement.weight = value;
      localStorage.setItem(
        'state.measurement',
        JSON.stringify(state.measurement)
      );
    },
    setGiftCardInfo(state, value) {
      state.giftCards = value;
      localStorage.setItem('state.giftCards', JSON.stringify(value));
    }
  },

  actions: {
    hideAlert({ commit }) {
      commit('showAlert', {
        text: String(),
        variant: String(),
        show: false,
      });
    },
    alertError({ commit }, text) {
      commit('showAlert', {
        text: text,
        variant: 'primary',
        show: true,
      });
    },
    alertInfo({ commit }, text) {
      commit('showAlert', {
        text: text,
        variant: 'primary',
        show: true,
      });
    },
    async fetchUser({commit}) {
      const user = await axios.get('user')
      commit('user', user)
      return user;
    },
    serializeStore: debounce(function ({ state }) {
      if (typeof Storage !== 'undefined') {
        localStorage.setItem('state.regionId', JSON.stringify(state.regionId));
        localStorage.setItem('state.cart', JSON.stringify(state.cart));
        localStorage.setItem('state.user', JSON.stringify(state.user));
        localStorage.setItem(
          'state.activeVendor',
          JSON.stringify(state.activeVendor)
        );
        localStorage.setItem(
          'state.measurement',
          JSON.stringify(state.measurement)
        );
        localStorage.setItem(
          'state.ageRestrictedCheck',
          JSON.stringify(state.ageRestrictedCheck)
        );
      }
    }, 100),

    updateQuote: debounce(
      async function ({ commit, dispatch, getters }) {
        if (getters.cartProducts.length > 0 && getters.region && getters.vendor) {
          if (getters.orderType === 'Delivery' && !getters.addressId) return
          if (getters.orderType === 'Shipping' && !getters.addressId) return

          commit('fetchingQuote', true);

          let data = {
            promo_code: getters.discountCode,
            gift_card_credit: getters.gift_card_credit,
            gift_card_uuid: getters.giftCards.uuid,
            products: getters.cartProducts.map((x) => ({
              product_uuid: x.uuid,
              product_quantity: x.quantityOrdered,
              product_modifiers: x.modifiers
                ? x.modifiers
                    .map((x) => x.items)
                    .flat()
                    .map((x) => ({
                      item_uuid: x.uuid,
                      item_quantity: x.item_quantity,
                    }))
                : [],
            })),
            tip: getters.state.cart.tip,
            ...(getters.addressId &&
            (getters.orderType === 'Delivery' ||
              getters.orderType === 'Shipping')
              ? { address_id: getters.addressId }
              : {}),
            ...(getters.orderType === 'Shipping'
              ? { shipping_rate_id: getters.shippingRateId }
              : {}),
            order_type: getters.orderType || 'Pickup',
          };


          try {
            const quote = await axios.post(
              `region/${getters.region.uuid}/vendor/${getters.vendor.uuid}/order/quote`,
              data
            );
            if (quote) {
              commit('quote', quote);
              localStorage.setItem('state.quote', JSON.stringify(quote));
            } else {
              localStorage.removeItem('state.quote');
            }
            dispatch('hideAlert');
          } catch (e) {
            dispatch('alertError', e.message);
            commit('quote', null);
          } finally {
            commit('fetchingQuote', false);
          }
        }
      },
      500,
      { leading: false, trailing: true }
    ),
    checkOrderRequirementsMet({ commit, getters }) {
      let region = getters.region;

      let met =
        minimumSubtotalCheck(region, getters.quote) &&
        maximumOrderWeightCheck(region, getters.cartProducts);

      if (region.governance.EnableFeatureModifiers)
        met = met && getters.cartChecks.modifiers;

      commit('orderRequirementsMet', met);
    },
    setLengthMeasurement({ commit }, value) {
      commit('setLengthMeasurement', value);
    },
    setWeightMeasurement({ commit }, value) {
      commit('setWeightMeasurement', value);
    },
    async getGiftCardsInfo({ state, commit }) {
      try {
        const userGiftCard = await axios.get(`user/${state.user.uuid}/giftcard`)
        commit('setGiftCardInfo', userGiftCard);
      } catch {
        commit('setGiftCardInfo', { balance: 0 });
      }
    }
  },
  getters: {
    vendor: (state) => state.activeVendor,
    vendorRegion: (state) =>
      state.activeVendor.vendor_regions.find(
        (x) => x.region_id == state.regionId
      ) || state.activeVendor.vendor_regions[0],
    regionId: (state) => state.regionId,
    regions: (state) => state.regions,
    ageRestrictedCheck: (state) => state.ageRestrictedCheck,
    orderRequirementsMet: (state) => state.orderRequirementsMet,
    region: (state) =>
      state.regions ? state.regions.find((x) => x.id === state.regionId) : null,
    user: (state) => state.user,
    state: (state) => ({ regionId: state.regionId, cart: state.cart }),
    cartChecks: (state) => state.cart.checks,
    cartValid: (state) => {
      let items = state.cart.products.map((x) => x.vendor_uuid);
      let uniqueItems = [...new Set(items)];
      return (
        Object.values(state.cart.checks).findIndex((x) => x === false) === -1 &&
        uniqueItems.length < 2
      );
    },
    cartProducts: (state) => state.cart.products,
    discountCode: (state) => state.cart.code,
    alert: (state) => state.alert,
    selectedCategoryId: (state) => state.selectedCategoryId,
    cartInteractions: (state) => state.cartInteractions,
    quote: (state) => state.quote,
    tip: (state) => state.cart.tip,
    gift_card_credit: (state) => state.cart.gift_card_credit,
    fetchingQuote: (state) => state.fetchingQuote,
    addressId: (state) => state.order.address ? state.order.address.id : null,
    orderAddress: (state) => state.order.address,
    orderInfo: (state) => state.order,
    orderType: (state) => state.order.type,
    shippingRateId: (state) => state.order.shippingRateId,
    weightMeasurement: (state) => state.measurement.weight,
    lengthMeasurement: (state) => {
      return state.measurement.length;
    },
    giftCards: (state) => state.giftCards,
  },
};
