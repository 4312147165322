<template>
  <b-modal
    v-if="region"
    id="age-modal"
    :visible="visible"
    cancel-title="No"
    centered
    header-class="bg-info text-white"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    ok-title="Yes"
    title="Before you enter our site"
    @cancel="no"
    @ok="yes"
  >
    <p>Are you {{ region.minimum_age }} years of age or older?</p>
    <p v-if="underAge" class="text-danger">
      Sorry, you must be {{ region.minimum_age }} years of age to shop here.
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'MinimumAgeModal',
  data() {
    return {
      underAge: false,
      visible: false,
    };
  },
  computed: {
    ...mapGetters(['ageRestrictedCheck', 'region']),
  },
  watch: {
    region() {
      this.checkShowModal();
    },
  },
  mounted() {
    this.checkShowModal();
  },
  methods: {
    checkShowModal() {
      if (this.region && this.region.minimum_age) {
        if (
          this.ageRestrictedCheck === null ||
          (this.ageRestrictedCheck !== null &&
            moment().diff(this.ageRestrictedCheck.date, 'days') > 30) ||
          (this.ageRestrictedCheck !== null &&
            this.ageRestrictedCheck.minimum_age < this.region.minimum_age)
        ) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    },
    yes() {
      this.$store.commit('ageRestrictedCheck', {
        date: moment.now(),
        minimum_age: this.region.minimum_age,
      });
      this.visible = false;
      this.$store.dispatch('serializeStore');
    },
    no(bvModalEvent) {
      this.underAge = true;
      bvModalEvent.preventDefault();
    },
  },
};
</script>
