
  <g>
    <path
      d="M3.13,100c-1.67-0.67-2.27-1.91-2.26-3.68c0.05-5.43,0.02-10.87,0.02-16.3c0-2.53,1.1-3.63,3.62-3.63
		c7.39,0,14.77,0,22.16,0c0.38,0,0.76,0,1.25,0c0-0.45,0-0.79,0-1.14c0-3.97,0-7.94,0-11.91c0-2.2,1.16-3.35,3.38-3.35
		c7.45,0,14.9,0,22.35,0c0.38,0,0.76,0,1.25,0c0-0.44,0-0.79,0-1.14c0-6.12,0-12.23,0-18.35c0-2.23,1.14-3.37,3.34-3.38
		c3.03,0,6.05,0,9.08,0c0.32,0,0.64,0,1.07,0c0-0.46,0-0.84,0-1.22c0-10.87,0-21.73,0-32.6c0-1.82,1.09-3.15,2.66-3.28
		c1.68-0.14,3.06,1,3.2,2.69c0.05,0.54,0.01,1.1,0.01,1.79c0.47,0,0.85,0,1.22,0c6.9,0,13.8,0.04,20.69-0.03
		c1.8-0.02,3.09,0.51,3.83,2.17c0,0.52,0,1.04,0,1.56c-0.5,0.67-0.95,1.38-1.5,2c-1.68,1.88-3.4,3.72-5.14,5.62
		c1.1,1.1,2.15,2.1,3.15,3.16c1.19,1.27,2.33,2.58,3.5,3.87c0,0.52,0,1.04,0,1.56c-0.68,1.73-1.99,2.26-3.78,2.25
		c-6.9-0.05-13.8-0.02-20.69-0.02c-0.38,0-0.76,0-1.15,0c0,3.57,0,6.99,0,10.47c3.53,0,6.98,0,10.42,0c2.42,0,3.49,1.07,3.49,3.51
		c0,18.51-0.02,37.03,0.03,55.54c0,1.78-0.49,3.1-2.17,3.83C58.47,100,30.8,100,3.13,100z M82.37,43.02c-7.2,0-14.35,0-21.54,0
		c0,17.05,0,34.04,0,51.08c7.2,0,14.35,0,21.54,0C82.37,77.07,82.37,60.1,82.37,43.02z M33.85,65.93c0,9.46,0,18.81,0,28.15
		c7.04,0,14.02,0,20.97,0c0-9.42,0-18.77,0-28.15C47.8,65.93,40.85,65.93,33.85,65.93z M6.86,94.08c7.03,0,14.01,0,20.98,0
		c0-3.95,0-7.83,0-11.74c-7.03,0-13.98,0-20.98,0C6.86,86.26,6.86,90.12,6.86,94.08z M89.96,20.7c-0.88-0.85-1.55-1.49-2.22-2.15
		c-1.9-1.88-1.94-3.23-0.15-5.19c0.86-0.95,1.73-1.89,2.69-2.93c-5.43,0-10.67,0-15.92,0c0,3.46,0,6.85,0,10.27
		C79.51,20.7,84.57,20.7,89.96,20.7z"
    />
    <path
      d="M42.31,18.29c-1.62,0.32-3.08,0.62-4.53,0.9c-1.67,0.33-3.22-0.65-3.56-2.24c-0.34-1.6,0.65-3.11,2.35-3.48
		c3.46-0.76,6.92-1.48,10.38-2.19c1.31-0.27,2.52,0.24,3.13,1.44c1.69,3.3,3.34,6.61,4.94,9.94c0.69,1.43,0.02,3.13-1.37,3.83
		c-1.43,0.71-3.14,0.17-3.92-1.31c-0.71-1.34-1.37-2.71-2.09-4.16c-0.99,1.74-1.87,3.4-2.85,4.99C35.08,41.97,21.7,53.75,4.57,61.22
		c-0.66,0.29-1.45,0.46-2.15,0.39c-1.29-0.14-2.27-1.27-2.4-2.47c-0.15-1.35,0.48-2.47,1.74-3.09c2.74-1.35,5.55-2.56,8.2-4.07
		c13.76-7.81,24.36-18.72,31.85-32.67C41.96,19.03,42.09,18.74,42.31,18.29z"
    />
  </g>
