<template>
  <div class="gift-cards">
    <h1 class="pt-4 text-center">Gift Cards</h1>

    <div class="gift-cards__new__preview">
      <img v-if="vendor.logo_image" class="gift-cards__new__preview__logo" :src="vendor.logo_image">
      <div class="gift-cards__new__preview__title">{{ user.name }}'s {{ vendor.name }} Gift Card</div>
      <div class="gift-cards__new__preview__sum">{{ formatDefaultPrice(giftCards.balance) }}</div>
    </div>

    <div class="gift-cards__header">
      <div v-for="tab in OPTIONS" :key="tab.value" class="gift-cards__header__item"
        :class="{ 'gift-cards__header__item--active': activeTab === tab.value }" @click="changeTab(tab.value)">
        <button>{{ tab.label }}</button>
      </div>
    </div>

    <div class="gift-cards__list" v-if="activeTab === 'gift-cards-history'">
      <span class="gift-cards__list__title">Balance: {{ formatDefaultPrice(giftCards.balance) }}</span>

      <div v-for="card in giftCardsHistory" :key="card.id" class="gift-cards__list__item">
        <div>{{ moment(card.created_at).format('MMMM D, YYYY') }} - {{ card.label }}</div>
        <div>{{ card.type === 'credit' ? '+' : '-' }}{{ formatDefaultPrice(card.change) }}</div>
      </div>

      <div v-if="!giftCardsHistory.length" class="gift-cards__list__empty">You haven't used a Gift Card, yet!</div>
    </div>

    <div v-else-if="!vendor.is_active_payment">
      <b-alert show variant="info" fade>{{ vendor.name }} needs to activate a payment processor to enable this
        feature.</b-alert>
    </div>

    <div v-else-if="!vendor.settings.enable_gift_cards">
      <b-alert show variant="info" fade>{{ vendor.name }} needs to activate a gift cards to enable this
        feature.</b-alert>
    </div>

    <div v-else-if="activeTab === 'buy-new-gift-card'" class="gift-cards__new">
      <PaymentMethods v-model="form.payment_method_uuid" class="gift-cards__new__payment-methods" />

      <b-form class="gift-cards__new__form" @submit="onSubmit">
        <b-form-group id="receiver-group" label="For Who?" label-for="receiver">
          <b-form-select id="receiver" v-model="form.reciever" :options="newCardReceiverOptions" required />
        </b-form-group>

        <b-form-group v-show="form.reciever !== 'own'" id="email-group" label="Recipient Email Address"
          label-for="email">
          <b-form-input if="email" placeholder="mail@example.com" type="email" v-model="form.email"
            :required="form.reciever === 'present'" />
        </b-form-group>
        <b-form-group id="price-group" label="Credit Amount" label-for="price">
          <b-form-input id="price" placeholder="$100.00" type="number" step="1" v-model="form.price" required :min="15"
            :max="1000" :formatter="priceFormatter" />
        </b-form-group>

        <b-button type="submit" class="btn-cta" size="lg" variant="primary" :disabled="isPurchasing">
          <div v-show="isPurchasing" class="spinner-border text-light loading-icon" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Purchase
        </b-button>
      </b-form>

      <b-alert :show="showSuccessAlert" variant="success" class="gift-cards__new__alert" dismissible fade>
        A Gift Card credit was successfully purchased.
      </b-alert>
    </div>
  </div>
</template>

<script>
import { formatDefaultPrice } from '%/formatPrice';
import PaymentMethods from '@/cart/PaymentMethods.vue';
import { Device } from '@capacitor/device'
import { axios } from '^/axios';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone'

const OPTIONS = [
  {
    label: 'Purchase',
    value: 'buy-new-gift-card'
  },
  {
    label: 'History',
    value: 'gift-cards-history'
  }
]

export default {
  components: { PaymentMethods },

  data() {
    return {
      moment,
      activeTab: 'buy-new-gift-card',
      userGiftCard: {},
      giftCardsHistory: [],
      OPTIONS,
      newCardReceiverOptions: null,
      form: {
        reciever: 'own',
        email: '',
        user_email: '',
        price: 150.00,
        payment_method_uuid: null
      },
      isPurchasing: false,
      showSuccessAlert: false
    }
  },

  computed: {
    ...mapGetters(['region', 'vendor', 'user', 'giftCards']),
  },

  methods: {
    formatDefaultPrice,
    changeTab(value) {
      this.activeTab = value

      if (value === 'gift-cards-history') {
        this.getGiftCardHistory()
      }
    },

    resetForm() {
      this.form = {
        ...this.form,
        email: '',
        price: null,
        reciever: null,
      }
    },

    async onSubmit(e) {
      e.preventDefault()
      this.$store.dispatch('hideAlert');

      try {
        this.isPurchasing = true
        this.showSuccessAlert = false

        const deviceInfo = await Device.getInfo();

        const payload = {
          amount: Number(this.form.price),
          to_email: this.form.reciever === 'own' ? this.form.user_email : this.form.email,
          payment_method_uuid: this.form.payment_method_uuid,
          device_type: deviceInfo?.platform || 'web',
        }
        const response = await axios.post(`region/${this.region.uuid}/vendor/${this.vendor.uuid}/purchase/giftcard`, payload)

        if (this.user.email === response.to_email) {
          this.$store.commit('setGiftCardInfo', response)
        }
        this.showSuccessAlert = true
        this.resetForm()
        this.$store.dispatch('getGiftCardsInfo');
      } catch (error) {
        await this.$store.dispatch('alertError', error.message);
      } finally {
        this.isPurchasing = false
      }
    },

    async getGiftCardHistory() {
      if (!this.giftCards.uuid) return

      try {
        const response = await axios.get(`user/${this.user.uuid}/giftcard/${this.giftCards.uuid}/ledger`)
        this.giftCardsHistory = response.data.reverse()//.map(x => x.created_at = moment(x.created_at))
      } catch (error) {
        await this.$store.dispatch('alertError', error.message);
      }
    },

    priceFormatter(value) {
      if (value > 1000) return 1000
      const cents = `${value}`.split('.')[1]
      if (cents && cents.length > 2) return (Math.floor(value * 100) / 100).toFixed(2) // used to always get 2 decimal places, whenever needed.
      return value
    }
  },

  mounted() {
    this.form.user_email = this.user?.email
    this.$store.dispatch('getGiftCardsInfo');
    this.getGiftCardHistory()
    this.newCardReceiverOptions = [
      { value: 'own', text: this.user.name },
      { value: 'present', text: 'Send it to a friend.' }
    ]
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';
@include buttons;
@include inputs;

.gift-cards {
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;

  padding: 16px;

  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 16px;

    font-size: 24px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    &__item {
      padding: 4px 10%;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0%;
        height: 2px;
        background-color: var(--primary)
      }

      &--active {
        &::after {
          width: 100%;
          transition: 0.3s;
        }
      }

      button {
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }

  &__list {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    &__title {
      font-size: 24px;
      font-weight: bold;
    }

    &__item {
      display: flex;
      justify-content: space-between;

      padding: 8px;
      border-bottom: 1px solid var(--secondary);
    }

    &__empty {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }
  }

  &__new {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;

    padding: 0 12px;

    &__form {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;

      width: 100%;
    }

    &__preview {
      height: 300px;
      position: relative;

      margin: 0 auto;

      background-color: var(--secondary);
      box-shadow: 4px 4px 8px color-mix(in srgb, var(--primary) 100%, transparent);
      border-radius: 12px;
      padding: 16px;

      color: var(--primary);

      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: space-between;

      width: calc(50% - 8px);

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &__logo {
        position: absolute;
        bottom: 16px;
        left: 16px;

        width: 64px;
        height: 64px;

        border-radius: 12px;
        object-fit: cover;
      }

      &__title {
        padding-top: 28px;
        width: 100%;
        font-size: 24px;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      &__sum {
        margin-bottom: 80px;
        font-size: 48px;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-size: 30px;
        }
      }

      &__email {
        min-height: 24px;
        width: 75%;
        text-align: right;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__payment-methods {
      width: 100%;
    }

    &__alert {
      width: 100%;
    }
  }
}

.loading-icon {
  width: 20px;
  height: 20px;
}
</style>
