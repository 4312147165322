import '@mdi/font/css/materialdesignicons.min.css';
import { BootstrapVue, BootstrapVueIcons, ToastPlugin } from 'bootstrap-vue';
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';
import VueNumber from 'vue-number-animation';
import VueRouter from 'vue-router';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import UUID from 'vue-uuid';
import Vue2Filters from 'vue2-filters';
import Vuex from 'vuex';
import { auth } from '^/auth';
import { axios } from '^/axios';
import { configuration } from '~/configuration';
import { routes } from '~/routes';
import store from '~/vuex';
import App from './App.vue';
import fetchAndSettingsColors from './utils/fetchAndSettingsColors';
// import Smartlook from 'smartlook-client';
import { Capacitor } from '@capacitor/core';
import { useFavicon, usePreferredDark } from '@vueuse/core';

Vue.config.productionTip = configuration.production;
Vue.config.silent = configuration.production;
if (configuration.production) {
  console.log = () => {};
}

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vue2Filters);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ToastPlugin);
Vue.use(VueNumber);
Vue.use(UUID);

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('en', en);

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('VSelect', vSelect);

const vuex = new Vuex.Store(store);

// Vuex watch does not behave as expected when all the getters are lumped together
const watchCb = async () => {
  await vuex.dispatch('serializeStore');
};

vuex.watch((state, getters) => getters.cartProducts, watchCb, {
  deep: true,
  immediate: true,
});
vuex.watch((state) => state.user || state.regionId, watchCb);

let router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
      window.scrollTo(0, 0);
    });
  },
});

router.beforeEach(async (to, from, next) => {
  await vuex.dispatch('hideAlert');

  let authenticated = auth.isAuthenticated();
  let role = auth.getRole();

  if (to.meta.public) {
    next();
  }
  else if(!authenticated && to.name === 'cart'){
    auth.destroyToken();
    next({
      path: '/register',
    });
  }
  else if (
    authenticated &&
    (!to.meta.roles || to.meta.roles.includes(role))
  ) {
    next();
  } else {
    auth.destroyToken();
    next({
      path: '/login',
    });
  }
});

let url = '';
let params = {};
if (Capacitor.isNativePlatform() || configuration.vendorUuid) {
  url = `vendor/${configuration.vendorUuid}`;
} else {
  url = 'vendor';
  params = { domain: window.origin };
}

new Vue({
  el: '#app',
  router,
  store: vuex,
  async mounted() {
    document.documentElement.style.setProperty('--primary', '#ffffff');
    document.documentElement.style.setProperty('--secondary', '#ffffff');

    this.$store.commit('deserializeStore');

    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    try {
      const vendor = await fetchAndSettingsColors()
        
      vuex.commit('setActiveVendor', vendor);

      if (vendor.settings.google_code) {
        this.injectGoogleAnalyticsScript(vendor.settings.google_code);
      }
    } catch (error)  {
      vuex.dispatch('alertError', error.message);
    }
  
  },
  methods: {
    injectGoogleAnalyticsScript(googleAnalyticsId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', googleAnalyticsId);
    },
  },
  render: (h) => h(App),
});
