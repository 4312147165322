<template>
  <span class="title">
    <slot name="title"></slot>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.title {
  font-family: $main-font;
  font-weight: 700;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 0;
  color: $dark-gray;
}
</style>
