<template>
  <b-button-group class="tips">
    <b-button
      v-for="tip in tipPresentAmounts"
      :key="tip.id"
      :class="[selectedTip && tip.id === selectedTip.id ? 'primary' : 'btn-light']"
      class="mr-2 circle border mr-xs-1"
      @click="selectOption(tip)"
    >
      {{ tip.value }}%
    </b-button>

    <b-button v-if="selectedOpt"
      :class="[selectedTip && customAmount.id === selectedOpt.id ? 'primary active' : 'btn-light']"
      class="custom-amt border circle px-0"
      pill
      @click="selectOption(customAmount)"
    >
      Custom
    </b-button>

    <transition name="slide-open">
      <b-form-input
        v-show="isCustomAmount"
        id="custom-amt"
        v-model="customAmount.value"
        autofocus
        class="text-center p0 m0 custom-amt-input"
        step="0.01"
        @keyup="updateTip"
      />
    </transition>
  </b-button-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { bankersRound } from '^/utilities';
import { tipEnum } from '~/enums/tip';
import { orderTypesEnum } from '~/enums/orderStatuses'

export default {
  name: 'Tip',

  props: {
    selectedTip: {
      type: Object,
      default: () => ({})
    },
    orderType: {
      type: String,
      default: null
    },
  },

  data() {
    return {
      selectedOpt: null,

      tipPresentAmounts: [
        { id: 1, value: 10, state: false },
        { id: 2, value: 15, state: false },
        { id: 3, value: 20, state: false },
      ],
      customAmount: {
        id: 4,
        value: '',
        state: false,
      }
    };
  },
  mounted() { 
    if(this.orderType == orderTypesEnum.DELIVERY && this.vendor.settings.enable_tipping){
      if (this.selectedTip && this.selectedTip.id) {
        return this.selectOption(this.selectedTip);
      }
      this.selectOption({ id: 2, value: 15, state: false });
    }
    else if(this.orderType == orderTypesEnum.PICKUP && this.vendor.settings.enable_tipping_pickup){
      if (this.selectedTip && this.selectedTip.id) {
        return this.selectOption(this.selectedTip);
      }
      this.selectOption({ id: 1, value: 10, state: false });
    }    
    else{
      this.selectOption({ id: 4, value: 0, state: false });
    }
  },
  methods: {
    ...mapActions(['updateQuote']),

    selectOption(tip) {
      this.selectedOpt = tip;
      this.updateTip()
    },

    getBtnVariant(state) {
      return state ? 'primary' : 'btn-light';
    },

    getCalculatedTip() {
      if (this.isCustomAmount) {
        let tip = 0.0

        if (this.customAmount.value > 0) {
          tip = bankersRound(this.customAmount.value);
        }

        return { value: tip, type: tipEnum.FIXED, id: 4 }
      } else {
        return { value: this.selectedOpt.value, type: tipEnum.PERCENT, id: this.selectedOpt?.id };
      }
    },

    updateTip() {
      this.customAmount.value = this.customAmount.value.replace(/[^0-9]/g, '');
      const tip = this.getCalculatedTip();
      this.$emit('change', tip);
      this.$store.commit('setTip', tip);
      this.updateQuote();
    },
  },
  watch: {
    orderType(newVal){
      this.$nextTick(() => {
        this.updateTip()
      })
    }
  },
  computed: {
    ...mapGetters(['vendor']),
    isCustomAmount() {
      return this.selectedOpt && this.customAmount && this.selectedOpt.id === this.customAmount.id;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.primary {
  background-color: var(--primary);
}

.circle {
  height: 75px;
  width: 75px;
  max-width: 75px;
  border-radius: 20em !important;
}

@media only screen and (max-width: 600px) {
  .mr-xs-1 {
    margin-right: 0.25rem !important;
  }

  .circle {
    height: 60px;
    width: 60px;
    max-width: 60px;
    border-radius: 20em !important;
  }
}

.custom-amt {
  max-width: 70px;
}

.custom-amt.active {
  color: white !important;
}

.custom-amt-input {
  width: 100px;
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
  margin-left: -28px;
  padding-left: 30px;
  height: 100%;
}

.slide-open-enter-active,
.slide-open-leave-active {
  transition: all 0.2s ease-in-out;
}

.slide-open-enter,
.slide-open-leave-to {
  width: 0;
  padding: 0;
  border: 0;
}

button.circle.btn-info {
  color: white !important;
}
</style>
