
  <g>
    <path
      d="M41.41,0c5.73,0,11.46,0,17.19,0c0.32,0.05,0.64,0.13,0.96,0.14c5.24,0.17,10.43,0.67,15.56,1.78
		c4.51,0.98,8.82,2.48,12.61,5.21c5.08,3.65,7.89,8.78,9.57,14.64c2.03,7.08,2.58,14.37,2.69,21.68c0.09,6.5-0.14,13-0.34,19.49
		c-0.14,4.65-0.87,9.23-1.95,13.75c-2.62,11.01-9.3,17.98-20.36,20.77c-6.86,1.72-13.84,2.29-20.86,2.44
		c-7.35,0.16-14.7,0.19-22.04-0.52c-4.53-0.44-9.02-1.09-13.39-2.4C12.94,94.55,7,89.68,3.9,81.65c-2.26-5.85-3.07-11.97-3.54-18.17
		C0.23,61.84,0.12,60.19,0,58.54c0-5.72,0-11.45,0-17.17c0.18-2.32,0.36-4.65,0.54-6.97c0.36-4.5,1.07-8.94,2.36-13.28
		c2.53-8.54,7.78-14.55,16.28-17.57c5.59-1.98,11.4-2.75,17.27-3.2C38.11,0.23,39.76,0.12,41.41,0z M8.25,49.92
		c0.25,4.9,0.46,9.83,0.77,14.76c0.26,4.09,0.92,8.12,2.15,12.04c1.44,4.57,3.86,8.41,8.36,10.51c1.84,0.86,3.78,1.57,5.75,2.09
		c6.52,1.72,13.22,2.09,19.91,2.12c6.53,0.03,13.07-0.26,19.6-0.54c4.09-0.18,8.12-0.94,12.05-2.14c5.74-1.75,9.71-5.29,11.67-11.06
		c1.93-5.66,2.54-11.53,2.82-17.42c0.24-5.03,0.28-10.08,0.2-15.12c-0.09-6.14-0.43-12.27-1.73-18.3c-0.64-2.96-1.53-5.83-3.08-8.47
		c-2.54-4.32-6.63-6.36-11.24-7.62c-6.73-1.84-13.66-2.16-20.58-2.31c-6.57-0.14-13.14-0.09-19.7,0.53
		c-4.08,0.38-8.11,0.99-12.04,2.18c-5.78,1.75-9.77,5.34-11.71,11.14c-1.32,3.94-2.07,7.98-2.39,12.09
		C8.66,39.55,8.51,44.71,8.25,49.92z"
    />
    <path
      d="M54.29,35.39c0,2.99,0,5.98,0,8.97c0,1.31,0,1.31,1.36,1.31c5.89,0,11.78-0.01,17.67,0.01c0.77,0,1.57,0.06,2.31,0.27
		c2.26,0.64,3.7,2.73,3.59,5.08c-0.64,13.69-10.09,24.89-23.5,27.57c-15.83,3.16-30.99-6.98-34.27-22.44
		c-3.43-16.11,6.63-31.47,22.78-34.85c1.58-0.33,3.22-0.49,4.83-0.54c3.01-0.11,5.18,2.19,5.21,5.45
		C54.31,29.28,54.28,32.33,54.29,35.39z M70.23,54.24c-0.5,0-0.92,0-1.33,0c-5.82,0-11.65,0-17.47,0c-1.47,0-2.83-0.31-3.97-1.33
		c-1.31-1.18-1.74-2.69-1.74-4.38c0-5.85,0-11.7,0-17.56c0-0.4-0.05-0.8-0.08-1.21c-8.3,1.55-17.34,9.71-16.27,22.17
		c0.86,9.98,8.74,17.8,18.68,18.65C60.61,71.64,68.72,62.49,70.23,54.24z"
    />
  </g>
