<template>
  <b-form @submit="onSubmit">
    <Label v-if="$slots.title">
      <template #title>
        <slot name="title" />
      </template>
    </Label>

    <b-input-group>
      <b-input-group-prepend v-if="$slots.icon">
        <b-input-group-text>
          <IconBase
            size="16"
            :icon-color="active ? personalization.theme_color_1 : ''"
            :icon-name="'icon'"
            >
            <slot name="icon" />
          </IconBase>
        </b-input-group-text>
      </b-input-group-prepend>

      <b-form-input
        :value="currentValue"
        v-bind="$attrs"
        :placeholder="placeholder"
        :type="type"
        :readonly="readonly"
        :formatter="formatter"
        :min="min"
        :max="max"
        step="0.01"
        @blur="active = false"
        @focus="active = true"
        @input="$emit('input', $event)"
      />

      <b-input-group-append>
        <b-button
          :disabled="isButtonDisabled"
          class="action-button"
          type="submit"
        >
          Save
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form>
</template>

<script>
import Label from '@/common/Label/Label.vue';
import IconBase from '@/common/Icons/IconBase.vue';

export default {
  components: { Label, IconBase },

  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: [String, Number],
      default: null
    },
    max: {
      type: [String, Number],
      default: null
    },
    onButtonClick: {
      type: Function,
      default: () => {}
    },
    formatter: {
      type: Function,
      default: (value) => value
    }
  },

  data() {
    return {
      active: false,
      personalization: {}
    };
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
        this.$emit('update:modelValue', newValue);
      },
    },

    isButtonDisabled() {
      return !this.currentValue || Number(this.currentValue) < 0
    }
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.onButtonClick();
    }
  },

  mounted() {
    this.personalization = JSON.parse(localStorage.getItem('personalization'));
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentValue = newValue;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.action-button {
  letter-spacing: 0.075rem;
  padding: 0 32px;
  background-color: var(--primary);
  border-color: var(--primary);
}
</style>
