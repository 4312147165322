export const SKIPCART_DELIVERY_FEES = [
  { destination: '0-3', price: 6.49, isPricePerMile: false },
  { destination: '3-5', price: 7.49, isPricePerMile: false },
  { destination: '5-7', price: 8.49, isPricePerMile: false },
  { destination: '7-10', price: 9.49, isPricePerMile: false },
  {
    destination: '10+',
    price: '1.00',
    isPricePerMile: true,
    overMilage: 10,
  },
];

export const ROADIE_DELIVERY_FEES = {
  small: {
    sizes: '14" x 7.5" x 5"',
    pricing: [
      { destination: '0-5', price: 11.0, isPricePerMile: false },
      { destination: '6-10', price: 12.0, isPricePerMile: false },
      { destination: '11-20', price: 17.0, isPricePerMile: false },
      { destination: '21-30', price: 21.0, isPricePerMile: false },
    ],
  },
  large: {
    sizes: '24" x 24" x 36"',
    pricing: [
      { destination: '0-5', price: 12.0, isPricePerMile: false },
      { destination: '6-10', price: 14.0, isPricePerMile: false },
      { destination: '11-20', price: 18.0, isPricePerMile: false },
      { destination: '21-30', price: 22.0, isPricePerMile: false },
    ],
  },
};

export const FLAT_RATE = [
  { destination: 'Flat Rate', price: 10.0, type: 'flat' },
];
