export const orderStatusEnum = {
  CANCELLED: 0,
  RECEIVED: 1,
  PICKED_UP: 2,
  DELIVERED: 3,
  REFUNDED: 4,
  SCHEDULED: 5,
  VOIDED: 6,
  // QUEUED: 7,
  SHIPPED: 8,
  COMPLETE: 9,
  PENDING_PAYMENT: 10,
  PENDING_DRIVER: 11
};

export const orderTypesEnum = {
  SHIPPING: 'Shipping',
  PICKUP: 'Pickup',
  DELIVERY: 'Delivery'
};

export const orderStatuses = [
  { text: 'Scheduled', value: orderStatusEnum.SCHEDULED },
  { text: 'Received', value: orderStatusEnum.RECEIVED },
  { text: 'Picked Up', value: orderStatusEnum.PICKED_UP },
  { text: 'Delivered', value: orderStatusEnum.DELIVERED },
  { text: 'Shipped', value: orderStatusEnum.SHIPPED },
  //{ text: 'Queued', value: orderStatusEnum.QUEUED },
  { text: 'Complete', value: orderStatusEnum.COMPLETE },
  { text: 'Cancelled', value: orderStatusEnum.CANCELLED },
  { text: 'Refunded', value: orderStatusEnum.REFUNDED },
  { text: 'Voided', value: orderStatusEnum.VOIDED },
  { text: 'Payment Failed', value: orderStatusEnum.PENDING_PAYMENT },
  { text: 'Pending Driver', value: orderStatusEnum.PENDING_DRIVER },
];

export const settableOrderStatuses = [
  { text: 'Received', value: orderStatusEnum.RECEIVED },
  { text: 'Picked Up', value: orderStatusEnum.PICKED_UP },
  { text: 'Delivered', value: orderStatusEnum.DELIVERED },
  { text: 'Shipped', value: orderStatusEnum.SHIPPED },
  { text: 'Complete', value: orderStatusEnum.COMPLETE },
  { text: 'Cancelled', value: orderStatusEnum.CANCELLED },
  { text: 'Refunded', value: orderStatusEnum.REFUNDED }
];

export const completeStatuses = [
  { text: 'Picked Up', value: orderStatusEnum.PICKED_UP },
  { text: 'Delivered', value: orderStatusEnum.DELIVERED },
  { text: 'Shipped', value: orderStatusEnum.SHIPPED },
  { text: 'Complete', value: orderStatusEnum.COMPLETE }
];

export function validTransitionBatch(orders, status_id){
  if(!status_id || !orders.length) return true

  const unique = [...new Set(orders.map(item => item.type))];
  var csv = unique.join(", and ");
  if(unique.length > 1){
    return `${csv} orders must be updated separately. These have different final statuses.`
  }

  const roadie = orders.find(x => !x.type == 'Delivery' && x.vendorRegion.delivery_method == 'Roadie')

  if(roadie && status_id == orderStatusEnum.PICKED_UP)
    return "Roadie orders can't be manually Picked Up. Roadie drivers are responsible for this."

  const complete = completeStatuses.find(x => x.value == status_id)
  const unpaid = orders.find(x => !x.paid_at)
  const status = settableOrderStatuses.find(x => x.value == status_id)

  if((status_id == orderStatusEnum.REFUNDED || status_id == orderStatusEnum.RECEIVED || complete) && unpaid)
    return `Orders being transitioned to ${status.text} must first be paid.`

  return true
}

export function settableOrderStatusesConsideringOrderType(order) {
  if(!order)
    return settableOrderStatuses

  switch (order.type) {
    case "Pickup": return settableOrderStatuses.filter(s => ![orderStatusEnum.DELIVERED, orderStatusEnum.SHIPPED, orderStatusEnum.COMPLETE].includes(s.value));
    case "Delivery": return settableOrderStatuses.filter(s => ![orderStatusEnum.SHIPPED, orderStatusEnum.COMPLETE].includes(s.value));
    case "Shipping": return settableOrderStatuses.filter(s => ![orderStatusEnum.DELIVERED, orderStatusEnum.PICKED_UP].includes(s.value));
  }
}

export const orderStatusesWithReason = ['Cancelled', 'Refunded', 'Voided', 'Payment Failed', 'Pending Driver']