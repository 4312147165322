<template>
  <b-container class="login-container">
    <b-tabs
      v-model="registering"
      align-h="center"
      :class="alert.show ? 'mt-5' : 'mt-3'"
      fill
      active-nav-item-class="tabs"
    >
      <b-tab title="Login"></b-tab>
      <b-tab title="Register"></b-tab>
    </b-tabs>
    <b-row class="justify-center pt-5">
      <div class="col-12">
        <template v-if="cartProducts.length > 0">
          <h1 :class="(registering ? 'register' : 'login') +' display-5 align-center text-center'">{{ !registering ? 'Login' : 'Step 1: You' }}</h1>
          <p class="pt-2 px-2 text-center" v-if="!registering"><b>Login</b> to complete your order, or <b>register</b> in under 30 seconds <a class="primary" to="/register" @click="registering = 1"><u><b>here</b></u></a>.</p>
          <p class="pt-2 px-2 text-center" v-else><b>Register</b> in under 30 seconds to complete your order, or <b>login</b> <a class="primary" to="/login" @click="registering = 0"><u><b>here</b></u></a>.</p>
        </template>
        <template v-else>
          <h1 :class="(registering ? 'register' : 'login') +' display-5 align-center text-center'">{{ !registering ? 'Login' : 'Register' }}</h1>
          <p class="pt-2 px-2 text-center" v-if="!registering">Or <b>register</b> in under 30 seconds <a class="primary" to="/register" @click="registering = 1"><u><b>here</b></u></a>.</p>
          <p class="pt-2 px-2 text-center" v-else>Or <b>login</b> <a class="primary" to="/login" @click="registering = 0"><u><b>here</b></u></a>.</p>       
        </template>
      </div>
    </b-row>
    <b-row v-if="loading" key="loading" align-h="center">
      <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
    </b-row>
    <b-row v-else class="justify-center mx-auto">
      <b-container>
        <b-form class="contact-form" @submit.prevent="loginOrRegister">
          <b-form-row align-h="center">
            <b-col md="6">
              <b-form-group label="Email">
                <b-form-input
                  v-model.trim="email"
                  autocomplete="email"
                  autofocus
                  required
                  type="email"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Password">
                <b-input-group>
                  <b-form-input v-model="password" autocomplete="current-password" required :type="passwordType" :state="password.length == 0 ? null : password.length >= 8" :formatter="passwordFormatter" />
                  <b-input-group-append>
                    <b-button variant="outline" @click="togglePasswordVisibility" class="show-password-button">
                      <b-icon :icon="passwordType === 'password' ? 'eye' : 'eye-slash'"  />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback id="input-live-feedback">
                  Must be at least 8 characters.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-form-row>
          <transition
            mode="out-in"
            name="fade"
            :duration="{ enter: 400, leave: 0 }"
          >
            <b-form-row v-if="registering" align-h="center">
              <b-col md="4">
                <b-form-group label="Confirm Password">
                  <b-form-input v-model="confirmPassword" required :type="passwordType" :state="confirmPassword.length ? password === confirmPassword : null" :formatter="passwordFormatter">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="First Name">
                  <b-form-input v-model.trim="first_name" required type="text">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Last Name">
                  <b-form-input v-model.trim="last_name" required type="text">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Phone Number">
                  <b-form-input
                    v-model.trim="phone"
                    v-mask="'+1(###)###-####'"
                    required
                    type="text"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </transition>

          <b-form-row
            v-if="
              registering && region.governance.EnableFeatureControlledSubstances
            "
            align-h-center
          >
            <b-col md="4">
              <b-form-group label="Photo ID (Front)">
                <b-form-file
                  v-model="photoIDFront"
                  accept=".jpg, .png, .gif"
                  required
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Photo ID (Back)">
                <b-form-file
                  v-model="photoIDBack"
                  accept=".jpg, .png, .gif"
                  required
                ></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                description="Please hold up the front of your ID next to your face."
                label="Photo ID (Comparison)"
              >
                <b-form-file
                  v-model="photoIDConf"
                  accept=".jpg, .png, .gif"
                  required
                ></b-form-file>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <span class="font-italic text-muted"
                >Photo ID is required to verify your legal age and ensure the
                secure delivery of your orders. Photo IDs are stored securely on
                our servers and are not shared with any third-parties.</span
              >
            </b-col>
          </b-form-row>
          <b-row v-if="registering" align-h="center" class="mt-4 mb-4">
            <b-checkbox v-model="agreeTos" class="mr-1"></b-checkbox>
            By checking this box you agree with our&nbsp;<a
              :href="configuration.tosUrl"
              target="_blank"
              required
              >Terms of Service</a
            >.
          </b-row>
          <b-row align-h="center" :class="registering ? '' : 'mt-5'">
            <b-button
              :class="(registering ? 'register' : 'login') + ' btn-cta'"
              size="lg"
              type="submit"
              variant="info"
              :disabled="
                registering == true &&
                (!agreeTos ||
                  password.length < 8 ||
                  password != confirmPassword)
              "
              >{{ !registering ? 'Login' : 'Register' }}</b-button
            >
          </b-row>

          <b-row align-h="center" class="mt-5">
            <router-link class="reset-link" :to="`/vendor/${vendor.uuid}/reset-notify`"
              >Forgot your password?</router-link
            >
          </b-row>
          
          <b-row
            v-if="showCredit && !registering"
            class="powered-by">      
            <b-img :src="preppedIconBlack" />&nbsp;Powered by&nbsp;<b>Prepped</b>&nbsp;|&nbsp;<a target="_blank" href="https://orderprepped.com">Meal Prep Software</a>.  
          </b-row>
        </b-form>

      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import preppedIconBlack from '~/../assets/icons/icon-black.png';
import { axios } from '^/axios';
import { configuration } from '~/configuration';
import { auth } from '^/auth';
import { rules } from '^/rules';
import loadingGif from '~/../assets/loading.gif';
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import fetchAndSettingsColors from '%/fetchAndSettingsColors';

export default {
  directives: { mask },
  data() {
    return {
      showCredit: false,
      preppedIconBlack,
      email: '',
      registering: 1,
      password: '',
      name: '',
      phone: '',
      confirmPassword: '',
      first_name: null,
      last_name: null,
      loading: false,
      agreeTos: false,
      photoIDFront: null,
      photoIDBack: null,
      photoIDConf: null,
      configuration,
      loadingGif,
      rules,
      passwordType: 'password',
    };
  },
  mounted() {
    this.showCredit = window?.location?.hostname?.includes('orderprepped.com')
  },
  created() {
    if (this.$route.path === '/register') this.registering = 1;
    else this.registering = 0;
  },
  computed: {
    ...mapGetters(['alert', 'region', 'cartProducts', 'vendor']),
  },
  methods: {
    async loginOrRegister(e) {
      e.preventDefault();

      try {
        this.loading = true;

        const response = await new Promise((resolve) => {
          if (this.registering) {
            return resolve(this.register().then(this.login));
          } else {
            return resolve(this.login());
          }
        })

        auth.setBearerJwt(response.token.accessToken);
        auth.setGoogleAutocompleteToken(this.$uuid.v4());
        auth.setExpiry(response.token.token.expires_at);
        auth.setExtendedRoleClaims(response.extended_claims);

        if (!auth.isCustomer()) {
          const unauthorized = `${auth.getRole()}s can't use the shop site.`;
          this.$store.dispatch('alertError', unauthorized);
          auth.destroyToken();
          throw new Error(unauthorized);
        }

        const user = await axios.get('user')

        this.$store.commit('user', user);
        this.$store.dispatch('getGiftCardsInfo');

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else if (this.cartProducts.length > 0) {
          this.$router.push('/cart');
        } else {
          this.$router.push('/');
        }

        fetchAndSettingsColors()
      }
      catch (e) {
        if (e?.response?.status === 401) {
          this.$store.dispatch('alertError', 'Incorrect username or password. Try again.')
        } else {
          this.$store.dispatch('alertError', e.message ?? 'We encountered a technical issue. Please try again soon.')
        }
      }
      finally {
        this.loading = false;
      }
    },
    register() {
      if (this.password !== this.confirmPassword) {
        return Promise.reject('Passwords must match.');
      } else {
        let data = new FormData();
        data.append('name', this.first_name + ' ' + this.last_name);
        data.append('email', this.email);
        data.append('password', this.password);
        data.append('phone', this.phone);
        data.append('source_vendor_id', this.vendor.uuid);

        if (this.region.governance.EnableFeatureControlledSubstances) {
          data.append('photoIDFront', this.photoIDFront);
          data.append('photoIDBack', this.photoIDBack);
          data.append('photoIDConf', this.photoIDConf);
        }

        return axios.post(`user`, data);
      }
    },
    login() {
      return axios.post('login', {
        email: this.email,
        password: this.password,
      });
    },

    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    
    passwordFormatter(value) {
      return value.replace(/ /g, '')
    }
  },
};
</script>
<style lang="scss">
@import '../../styles/variables.module.scss';

.login-container {
  .nav-link {
    transition: 0.4s;
    font-size: 1.25rem;
    font-weight: bold;
    font-family: $staatliches-font;
    letter-spacing: 0.065em;
    color: #6c757d !important;
  }

  .register {
    transition: 0.4s;
  }

  .tabs {
    border-color: var(--primary) !important;
    border-bottom-color: transparent !important;
    color: var(--primary) !important;
    text-transform: uppercase !important;
  }

  .reset-link {
    font-size: 120%;
  }
      
  .powered-by {
    margin: auto;
    width: 308px;
    position: fixed; 
    bottom:2%;
    left: calc(100% - 50% - 154px);
    color: #989898;
    height: 20px;

    span.small {
      margin-top: 0.3em;
      margin-left: 0.3em;
      font-size: 80%;
    }

    & img {
      height: 100%;
      object-fit: contain;
    }
 
  }
}
</style>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

@include buttons;
@include inputs;

.show-password-button {
  border-width: 1px !important;
  box-shadow: none;
}
</style>
