
<b-container>
  <b-row class="justify-center py-5">
    <div class="col-12">
      <h1 class="display-4 align-center text-center">Order History</h1>
    </div>
  </b-row>
  <div v-if="!loading">
    <b-row v-if="orders.length > 0" class="justify-content-center pb-4">
      <b-col cols="12" sm="10">
        <b-list-group>
          <order
            v-for="(order, index) in orders"
            :key="index"
            :index="index"
            :order="order"
            @reorder="checkReorder"
          />
        </b-list-group>
      </b-col>
    </b-row>
    <b-container v-else>
      <b-row align-h="center" class="text-center pt-3">
        <p><b>Hey!</b> You haven't placed any orders yet.</p>
      </b-row>
      <b-row align-h="center" class="mt-3">
        <b-button class="btn-cta" to="/">Shop!</b-button>
      </b-row>
    </b-container>
    <b-row v-if="orders.length > 0" class="justify-content-center">
      <b-pagination
        v-model="currentPage"
        :per-page="itemsPerPage"
        :total-rows="totalOrders"
        pills
      ></b-pagination>
    </b-row>
  </div>
  <b-row v-else class="justify-content-center">
    <template align-h="center">
      <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
    </template>
  </b-row>
  <b-modal
    v-model="reorderAdjustmentModal"
    cancel-title="Nevermind"
    no-close-on-backdrop
    no-close-on-esc
    ok-title="That's ok!"
    title="Some changes to your order!"
    @ok="applyReorderItems"
  >
    <h4>We don't have enough of the following items in stock:</h4>
    <br />
    <ul>
      <li v-for="(o, index) in orderAdjustments" :key="index">
        You ordered {{ o.from }} <span v-if="!o.ordered_weight">units</span
        ><span v-else>{{
          smartWeightOptions[o.ordered_weight].text.toLowerCase()
        }}</span>
        of {{ o.product.title }}, but we
        <span v-if="o.to > 0">
          only have {{ o.to }} in stock that we can include in your
          reorder.</span
        >
        <span v-else>
          don't have any in stock. It won't be included in this reorder.</span
        >
      </li>
    </ul>
  </b-modal>
</b-container>
