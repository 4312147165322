import jwt_decode from 'jwt-decode';
import moment from 'moment';

export const RolesEnum = {
  Customer: 'Customer',
  Courier: 'Courier',
  Vendor: 'Vendor',
  RegionAdmin: 'RegionAdmin',
  GlobalAdmin: 'GlobalAdmin',
};

export const ApplicationGovernance = {
  EnableFeatureControlledSubstances: 'EnableFeatureControlledSubstances',
  EnableFeatureSmartWeight: 'EnableFeatureSmartWeight',
  EnableFeatureModifiers: 'EnableFeatureModifiers',
  EnableFeatureInventory: 'EnableFeatureInventory',
  EnableFeatureDelivery: 'EnableFeatureDelivery',
};

export const rolesArray = [
  RolesEnum.Customer,
  RolesEnum.GlobalAdmin,
  RolesEnum.RegionAdmin,
  RolesEnum.Courier,
  RolesEnum.Vendor,
];

export const auth = {
  setBearerJwt(bearerJwt) {
    if (sessionStorage.getItem('impersonation') === 'true') {
      sessionStorage.setItem('access-token', JSON.stringify(bearerJwt));
    }
    else {
      localStorage.setItem('access-token', JSON.stringify(bearerJwt));
    }
  },
  getBearerJwt() {
    const isImpersonate = sessionStorage.getItem('impersonation') === 'true'
    const accessToken = isImpersonate ? sessionStorage.getItem('access-token') : localStorage.getItem('access-token');

    if (accessToken === null) return null;

    return JSON.parse(accessToken);
  },
  setRefreshJwt(refreshToken) {
    localStorage.setItem('refresh-token', JSON.stringify(refreshToken));
  },
  setGoogleAutocompleteToken(token) {
    localStorage.setItem('google-autocomplete-token', JSON.stringify(token));
  },
  getGoogleAutocompleteToken() {
    const token = localStorage.getItem('google-autocomplete-token');
    return token ? JSON.parse(token) : null;
  },
  getRefreshJwt() {
    const refreshToken = localStorage.getItem('refresh-token');

    if (refreshToken === null) return null;

    return JSON.parse(refreshToken);
  },
  setExtendedRoleClaims(claims = []) {
    localStorage.setItem('claims', JSON.stringify(claims));
  },
  getExtendedRoleClaims(claim = null) {
    const claims = localStorage.getItem('claims');

    if (!claims) return null;

    const parsedClaims = JSON.parse(claims);

    if (claim !== null)
      return claim.split('.').reduce((o, i) => o[i], parsedClaims);

    return parsedClaims;
  },
  setExpiry(expiresIn) {
    localStorage.setItem('expires', JSON.stringify(expiresIn));
  },
  getExpiry() {
    const expires = localStorage.getItem('expires');
    if (expires === null) return null;
    return JSON.parse(expires);
  },
  getUnpackedBearerJwt() {
    const jwt = this.getBearerJwt();
    if (jwt !== null) return jwt_decode(jwt);
    return null;
  },
  isAuthenticated() {
    const expiry = this.getExpiry();
    if (expiry === null) return false;

    const expiryObject = moment(expiry, 'YYYY-MM-DD hh:mm:ss');
    return moment().isBefore(expiryObject);
  },
  isRegionAdmin() {
    return this.getRole() === RolesEnum.RegionAdmin;
  },
  isGlobalAdmin() {
    return this.getRole() === RolesEnum.GlobalAdmin;
  },
  isVendor() {
    return this.getRole() === RolesEnum.Vendor;
  },
  isCustomer() {
    return this.getRole() === RolesEnum.Customer;
  },
  getRole() {
    const decoded = this.getUnpackedBearerJwt();
    if (decoded === null) return null;
    return decoded.scopes.length > 0 ? decoded.scopes[0] : null;
  },
  destroyToken() {
    if(sessionStorage.getItem('impersonation') === 'true') {
      sessionStorage.clear();
    }
    localStorage.clear();
  },
};
