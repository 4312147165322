<template>
  <div :class="{ center: center }">
    <button class="button" :disabled="disabled" @click="onClick">
      <slot>Button</slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    onClick: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/variables.module.scss';
.button {
  padding: 8px 40px;
  font-family: $main-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: white;
  background: var(--primary);
  display: flex;
  border: 0;
  outline: none;
  border-radius: 5px;
  margin-top: 30px;
  transition: all 0.3s ease;
  &:disabled {
    background: #ececec;
    pointer-events: none;
  }
  &:active {
    background: var(--primary);
  }
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
