
<div class="address-wrapper py-4 border items-center align-items-center">
  <b-img class="img" :src="visa" width="30" />
  <div class="address-content">
    <div class="address-info">
      {{ address.autocomplete.slice(0, -4) }}
    </div>
    <div v-if="address.unit" class="address-info">
      {{ address.unit }}
    </div>
  </div>

  <div class="address-actions">
    <slot name="select" />
    
    <slot name="delete" />
  </div>
</div>
