<template>
  <div
    id="map"
    ref="map"
    class="map"
    tabindex="0"
    :style="`height:${height}px`"
  />
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { defaults } from 'ol/control';
import { defaults as iDefaults } from 'ol/interaction';
//import {FilterColorizeOptions} from 'ol-ext'

export default {
  name: 'Map',
  props: {
    height: Number,
    withControls: { type: Boolean, default: false },
    polygon: { type: Object, coordinates: Array },
    latitude: Number,
    longitude: Number,
    zoom: Number,
  },
  mounted() {
    this.map = new Map({
      target: 'map',
      interactions: iDefaults({
        doubleClickZoom: false,
        dragAndDrop: false,
        dragPan: this.withControls,
        keyboardPan: false,
        keyboardZoom: false,
        mouseWheelZoom: false,
        pointer: false,
        select: false,
      }),
      controls: defaults({
        attribution: false,
        zoom: this.withControls,
      }),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([Number(this.longitude), Number(this.latitude)]),
        zoom: this.zoom,
      }),
    });

    /*
        this.map.on('postcompose', function (e) {
          const canvas = document.querySelector('canvas')
          if (canvas)
            canvas.style.filter = ''
        })
    */
    this.addPolygon();
  },
  methods: {
    addPolygon() {
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(this.polygon, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }),
      });

      const vector = new Vector({
        source: vectorSource,
      });

      this.map.addLayer(vector);
    },
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 400px;
}

#map:focus {
  outline: none;
}
</style>
